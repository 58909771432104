import React from 'react';
import { FaMap } from "react-icons/fa";

const TestimonialCard = ({ title, location, address, image, position, isMobile }) => {
  const positionClass = position === 'left' 
    ? 'top-[15%] left-[-35%]' 
    : 'top-[15%] right-[-35%]';

  return (
    <div className="max-w-xs rounded-xl shadow-lg relative">
      <img src={image} alt={title} className="w-full md:w-[16rem] lg:w-80 h-auto object-cover rounded-lg" />

      <div className={`absolute ${isMobile ? "top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" : positionClass} px-6 py-4 md:px-4 md:w-44 lg:w-72 lg:px-10 rounded-xl bg-white shadow-[0_4px_40px_0_#00623F] z-10 min-w-[260px] md:min-w-0 max-w-[300px]`}>
        <h4 className='pb-4'>{title}</h4>
        <p className='text-gray-800 pb-4 text-2xl md:text-xl lg:text-2xl'>{location}</p>
        <div className='flex md:items-center gap-2 md:flex-row flex-col-reverse'>
          <p className='text-gray-500 text-sm'>{address}</p>
          <div className='p-2 md:p-1 lg:p-2 md:w-auto w-fit rounded-full bg-indigo-950 text-white text-sm'>
            <FaMap />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialCard;
