import { cn } from "../../lib/basic";
import PropTypes from "prop-types";

const variants = {
  primary: "bg-brandGreen text-white",
  outlined:
    "border border-[#E5E7EB] text-[#4B5563] hover:bg-[#F9FAFB] hover:border-[#D1D5DB]",
  disabled: "bg-[#FFB444] text-[#000000] opacity-50 cursor-not-allowed",
  ghost: "bg-neutral-100 text-neutral-600",
  destructive: "bg-red-500 text-white ",
};

const CustomButton = ({
  label,
  onClick,
  variant,
  className,
  Icon,
  iconProps,
  ...props
}) => {
  return (
    <button
      onClick={onClick}
      {...props}
      className={cn(
        "text-[16px] flex items-center justify-center gap-2 rounded-[6px]  p-[9px]",
        className,
        variants[variant],
        props?.disabled && variants["disabled"]
      )}
    >
      {Icon ? (
        <>
          <Icon {...iconProps} /> {label}
        </>
      ) : (
        label
      )}
    </button>
  );
};

CustomButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  variant: PropTypes.oneOf(Object.keys(variants)),
  className: PropTypes.string,
  Icon: PropTypes.elementType,
  iconProps: PropTypes.object,
};

export default CustomButton;
