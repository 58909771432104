import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../components/common/Header";
import Footer from "../components/common/Footer";
import introPhoto from "../assets/images/RSVP/detailHeader.png";
import writing from "../assets/images/RSVP/writing.png";
import hands from "../assets/images/RSVP/hands.png";
import girl from "../assets/images/RSVP/girl.png";
import space from "../assets/images/RSVP/space.png";
import calander from "../assets/images/RSVP/calander.png";
import TestimonialCard from "../components/card/testimonial";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import PageContainer from "../components/containers/PageContainer";
import { redirect } from "../lib/basic";
import { useSelector } from "react-redux";
import { selectUser } from "../redux/reducers/authReducer";

// FRAMER MOTION VARIANT
const variants = {
  hidden: {
    opacity: 0,
    x: -50,
    y: 0,
  },

  visible: {
    opacity: 1,
    x: 0,
    y: 0,

    transition: {
      delay: 0.2,
      duration: 0.8,
      type: "spring",
      stiffness: 50,
    },
  },
};

const container_variants = {
  hidden: {
    opacity: 0.1,
    x: -400,
    y: 400,
  },
  visible: {
    opacity: 1,
    x: 0,
    y: 0,
    transition: {
      delay: 0.35,
      duration: 1.8,
      type: "spring",
      stiffness: 40,
    },
  },
};

function RsvpDetail() {
  const testimonials = [
    {
      title: "Venue",
      location: "Hilton Addis Ababa",
      address: "Menelik II Avenue, Addis Ababa, Ethiopia",
      image: writing,
    },
    {
      title: "Venue",
      location: "Skylight Hotel",
      address: "Airport Road, Addis Ababa, Ethiopia",
      image: hands,
    },
    {
      title: "Venue",
      location: "Intercontinental Addis",
      address: "Africa Avenue, Addis Ababa, Ethiopia",
      image: space,
    },
  ];
  const user = useSelector(selectUser);
  const navigate = useNavigate();

  // Parse the query parameters
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const category = searchParams.get("category");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const prevTestimonial = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length
    );
  };

  const nextTestimonial = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  return (
    <PageContainer>
      <Header link={"RSVP"} />
      <div className="relative flex items-center justify-center flex-col">
        <img
          src={introPhoto}
          alt="party image"
          className="lg:my-10 w-full h-auto max-h-[calc(100vh-4rem)] sm:max-h-[calc(100vh-6rem)] md:max-h-[calc(100vh-8rem)] lg:max-h-[calc(100vh-10rem)] xl:max-h-[calc(100vh-12rem)] 2xl:max-h-[calc(100vh-16rem)] object-cover rounded-xl opacity-100"
        />
        <Link to={user != null ? `/events/create?category=${category}` : "/sign-in"}>
          <motion.button
            variants={variants}
            whileInView={"visible"}
            initial={"hidden"}
            viewport={{
              once: true,
            }}
            className="max-w-72 px-3 md:px-8 py-3 md:py-4 mt-5 sm:absolute sm:top-2/3 sm:left-[15%] rounded-lg bg-brandGreen text-white"
          >
            Start Creating Invitations
          </motion.button>
        </Link>
      </div>

      <div className=" mb-10 md:mt-32 md:max-w-3xl mx-auto flex md:flex-row flex-col items-center md:gap-8">
        <motion.div
          initial={"hidden"}
          variants={container_variants}
          whileInView={"visible"}
          viewport={{
            once: true,
          }}
          className="text-lg w-full mt-10 lg:w-[50%] flex flex-col items-center lg:items-start text-center lg:text-left"
        >
          <p className="font-inter text-xl md:text-[30px] lg:text-[36px] font-light leading-tight lg:leading-[77.45px]">
            "Customized Online RSVP Solution for Your Business.{" "}
            <span className="text-[#FFB444]">Your Brand </span>Takes Center
            Stage, Not Ours."
          </p>
          <Link
            to={user?.isLoggedIn ? `events/create?category=${category}` : "/sign-in"}
            className=" hidden md:flex"
          >
            <motion.button
              variants={variants}
              whileInView={"visible"}
              initial={"hidden"}
              viewport={{
                once: true,
              }}
              className="max-w-72 md:px-8 md:py-4 mt-5 px-4 py-2 text-sm md:text-md rounded-lg bg-[#FFB444] text-white"
            >
              Start Creating Invitations
            </motion.button>
          </Link>
        </motion.div>

        <motion.div
          initial={"hidden"}
          variants={{
            ...container_variants,
            hidden: { ...container_variants.hidden, x: 350 },
          }}
          whileInView={"visible"}
          viewport={{
            once: true,
          }}
          className="relative mt-10 lg:mt-0"
        >
          <img
            src={calander}
            alt="calendar"
            className="shadow-lg rounded-xl w-80"
          />
          <img
            src={girl}
            alt="girl"
            className="absolute top-[30%] left-[25%] w-40 lg:w-80 rounded-xl shadow-lg"
          />
        </motion.div>
      </div>

      <div className="mt-16 md:mt-56 mb-10 max-w-3xl mx-auto flex md:flex-row flex-col gap-8">
        <motion.p
          variants={container_variants}
          whileInView={"visible"}
          initial={"hidden"}
          viewport={{
            once: true,
          }}
          className="text-xl md:text-[30px] lg:text-[36px] w-full text-center md:w-1/2 font-light leading-tight lg:leading-[56px]"
        >
          "Join us for an unforgettable evening of enchantment as we celebrate{" "}
          <span className="text-[#FFB444]">love, laughter, and happily</span>{" "}
          ever afters at our Fairy Tale Ball. RSVP now to reserve your magical
          moment!"
        </motion.p>

        <motion.div
          initial={"hidden"}
          variants={{
            ...container_variants,
            hidden: { ...container_variants.hidden, x: 350 },
          }}
          whileInView={"visible"}
          viewport={{
            once: true,
          }}
          className="mt-10 lg:mt-0 md:block hidden"
        >
          <img src={space} alt="space" className="w-80 rounded-xl object- " />
        </motion.div>
      </div>

      <div className="mt-16 md:mt-56 mb-10 flex flex-col justify-center items-center">
        <h1 className="text-black text-4xl font-bold text-center mb-10">
          Testimonies
        </h1>
        <div className="flex items-center relative w-full">
          <button
            className="absolute bottom-10 left-10 sm:top-3/4 transform sm:-translate-y-1/2 sm:left-[10%] bg-gray-100 sm:bg-gray-300 hover:bg-gray-400 rounded-full p-2 z-20 h-10"
            onClick={prevTestimonial}
          >
            <IoIosArrowBack className="text-xl text-neutral-600" />
          </button>

          <div className="flex gap-5 lg:gap-20 overflow-hidden w-full justify-center">
            {isMobile
              ? [0].map((offset) => {
                  const index = (currentIndex + offset) % testimonials.length;
                  const testimonial = testimonials[index];
                  return (
                    <div
                      key={testimonial.location}
                      className="max-w-80 flex-shrink-0"
                    >
                      <TestimonialCard
                        title={testimonial.title}
                        location={testimonial.location}
                        address={testimonial.address}
                        image={testimonial.image}
                        position={"center"}
                        isMobile={isMobile}
                      />
                    </div>
                  );
                })
              : [0, 1].map((offset) => {
                  const index = (currentIndex + offset) % testimonials.length;
                  const testimonial = testimonials[index];
                  const position = offset === 0 ? "left" : "right";
                  return (
                    <TestimonialCard
                      key={index}
                      title={testimonial.title}
                      location={testimonial.location}
                      address={testimonial.address}
                      image={testimonial.image}
                      position={position}
                    />
                  );
                })}
          </div>

          <button
            className="absolute sm:top-3/4 bottom-10 right-10 transform sm:-translate-y-1/2 sm:right-[10%] bg-gray-100 sm:bg-gray-300 hover:bg-gray-400 rounded-full p-2 z-10 h-10"
            onClick={nextTestimonial}
          >
            <IoIosArrowForward className="text-xl text-neutral-600" />
          </button>
        </div>
      </div>
      <Footer />
    </PageContainer>
  );
}

export default RsvpDetail;
