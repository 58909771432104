import React, { useState } from 'react';
import { FaCog, FaChevronLeft, FaChevronRight, FaTimes } from 'react-icons/fa';
import { apiUrl } from '../../../utils/const';

function GallerySection({ eventData, isEditing, openEditor }) {
  const Gallery = eventData.contents.find(
    (item) => item.gallery && item.gallery.length > 0
  );
  const galleryContent = eventData.contents.find(
    (item) => item.type === 'gallery'
  );
  const galleryImages = Gallery ? Gallery.gallery : [];
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [transition, setTransition] = useState(false); // State to handle the sliding transition

  const handleNext = () => {
    setTransition(true);
    setTimeout(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === galleryImages.length - 1 ? 0 : prevIndex + 1
      );
      setTransition(false);
    }, 300); // Set the transition duration to 300ms
  };

  const handlePrev = () => {
    setTransition(true);
    setTimeout(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? galleryImages.length - 1 : prevIndex - 1
      );
      setTransition(false);
    }, 300); // Set the transition duration to 300ms
  };

  const openLightbox = () => {
    setIsLightboxOpen(true);
  };

  const closeLightbox = () => {
    setIsLightboxOpen(false);
  };

  return (
    <section
      style={{
        backgroundColor: eventData.custom_design.story_styles.background_color,
        color: eventData.custom_design.story_styles.color,
        padding: eventData.custom_design.story_styles.padding,
        paddingTop: 0,
        position: 'relative',
        textAlign: 'center',
      }}
      className="gallery-section w-full flex justify-center"
    >
      {/* setting icon */}
      {isEditing && (
        <FaCog
          className="absolute text-xl top-4 right-4 cursor-pointer text-black"
          onClick={() => openEditor(galleryContent)}
        />
      )}

      {galleryImages.length > 0 && (
        <div className="relative group">
          <div
            className={`transition-all duration-300 ease-in-out ${
              transition ? 'opacity-50' : 'opacity-100'
            }`}
          >
            <img
              src={`${apiUrl}/${galleryImages[currentIndex]}`}
              alt={`Gallery ${currentIndex + 1}`}
              className="w-[400px] h-[200px] object-cover rounded-md cursor-pointer"
              onClick={openLightbox}
            />
          </div>

          <button
            className="absolute left-0 top-1/2 transform -translate-y-1/2 text-white p-2 bg-black bg-opacity-50 rounded-full hidden group-hover:block transition-all duration-300 ease-in-out"
            onClick={handlePrev}
          >
            <FaChevronLeft />
          </button>

          <button
            className="absolute right-0 top-1/2 transform -translate-y-1/2 text-white p-2 bg-black bg-opacity-50 rounded-full hidden group-hover:block transition-all duration-300 ease-in-out"
            onClick={handleNext}
          >
            <FaChevronRight />
          </button>
        </div>
      )}

      {galleryImages.length === 0 && (
        <p className="text-gray-500">No images available in the gallery.</p>
      )}

      {/* Lightbox Modal */}
      {isLightboxOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
          onClick={closeLightbox}
        >
          <div className="relative">
            <img
              src={`${apiUrl}/${galleryImages[currentIndex]}`}
              alt={`Gallery Image ${currentIndex + 1}`}
              className="max-w-[90vw] max-h-[90vh] object-contain"
            />
            <button
              className="absolute top-4 right-4 text-white text-2xl"
              onClick={closeLightbox}
            >
              <FaTimes />
            </button>
          </div>
        </div>
      )}
    </section>
  );
}

export default GallerySection;
