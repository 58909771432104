import React from 'react';
import PricingCard from '../card/Pricing';
import data from './data';
import { motion } from 'framer-motion';


// FRAMER MOTION VARIANTS 
const variants = {
  hidden: {
    opacity: 0.1,
    y: 400,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 1.2,
      type: 'linear',
    }
  }
};

function Pricing() {
  return (
    <div className="max-w-7xl mx-auto py-8">
      <h1 className="text-3xl font-bold text-center mb-12">Pricing</h1>
      <div className="grid grid-cols-1 md:grid-cols-3">
        {data.map((cardData, index) => (
          <motion.div
            initial="hidden"
            whileInView="visible"
            variants={
              {
                ...variants,
                visible: {
                  ...variants.visible,
                  transition: {
                    ...variants.visible.transition,
                    delay: index * 0.4,
                  }
                }
              }}
            viewport={{ once: true }}
            key={index}
            className={`relative ${index === 1 ? '-translate-y-4 z-10' : ''} ${index === 0 ? 'md:-mr-8' : ''
              } ${index === 2 ? 'md:-ml-8' : ''}`}
          >
            <PricingCard {...cardData} />
          </motion.div>
        ))}
      </div>
    </div>
  );
}

export default Pricing;
