import React, { useEffect, useState } from 'react';
import Websitebuilder from '../components/DashboardSections/Websitebuilder';
import RSVPForm from '../components/DashboardSections/RSVP_form';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { apiUrl } from '../utils/const';
import ClipLoader from 'react-spinners/ClipLoader';

function PreviewPage() {
  const { eventId } = useParams();
  const [wallDesign, setWallDesign] = useState(null);
  const [eventName, setEventName] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [wallId, setWallId] = useState('');
  const [userId, setUserId] = useState('');
  const [loading, setLoading] = useState(true); // New loading state

  useEffect(() => {
    const fetchEvent = async () => {
      setLoading(true); // Start loading
      try {
        if (eventId) {
          const response = await axios.get(`${apiUrl}/api/events/${eventId}`);
          setWallId(response.data.wallDesignId);
          setWallDesign(response.data.template);
          setEventName(response.data.eventName);
          setStartDate(response.data.start_date);
          setStartTime(response.data.start_time);
          setUserId(response.data.userId);
        }
      } catch (error) {
        console.error('Error fetching event:', error);
      } finally {
        setLoading(false); // Stop loading
      }
    };

    fetchEvent();
  }, [eventId]);

  return (
    <div className="flex justify-center items-center min-h-screen">
      {loading ? (
        <ClipLoader color="#4A90E2" loading={loading} size={50} />
      ) : (
        <div className="w-full">
          <Websitebuilder
            wallId={wallId}
            wallDesign={wallDesign}
            isEditing={false}
          />
          <RSVPForm
            eventName={eventName}
            startDate={startDate}
            startTime={startTime}
            eventId={eventId}
            userId={userId}
            wallDesign={wallDesign}
          />
        </div>
      )}
    </div>
  );
}

export default PreviewPage;
