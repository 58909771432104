import React, { useState } from "react";
import { CiFacebook, CiInstagram, CiLinkedin, CiYoutube } from "react-icons/ci";
import { FaXTwitter } from "react-icons/fa6";
import Flogo from "../../assets/images/Flogo.png";
import { cn } from "../../lib/basic";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/reducers/authReducer";
import CustomButton from "../Button/Buttons";
import { Error, Success } from "../../utils/alert";
import { sendFeedback } from "../../service/Feedback.service";

const Footer = () => {
  const user = useSelector(selectUser);
  const location = useLocation();
  const [isVisible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.message) {
      return Error({
        title: "Oops, sorry!",
        message: "Your message is required!",
      });
    }

    setLoading(true);
    try {
      await sendFeedback(formData);
      Success({
        title: "Sent!",
        message: "Your feedback has been recorded",
      });

      setVisible(false);
    } catch (error) {
      Error({
        title: "Error!",
        message: "Oops something went wrong!",
      });
    }

    setLoading(false);
  };

  return (
    <div
      className={cn("bg-[#3F3F3F] md:p-12 p-10 rounded-xl md:rounded-[38px]")}
    >
      <div className="flex flex-col lg:flex-row justify-between items-center lg:items-start space-y-6 lg:space-y-0 lg:space-x-8">
        <div className="space-y-8">
          <a href="#">
            <img
              className="h-8 max-w-[230px] md:max-w-auto"
              src={Flogo}
              alt="company logo"
            />
          </a>

          {isVisible ? (
            <div className="space-y-1 text-neutral-300 text-sm">
              <p className="mb-4">Your feed back helps us improvise</p>
              <div className="flex flex-col">
                <label
                  htmlFor="fullName"
                  className="text-neutral-300 font-semibold"
                >
                  Full Name
                </label>
                <input
                  onChange={handleChange}
                  value={formData.fullName}
                  type="text"
                  name="fullName"
                  placeholder="Enter Full Name"
                  className="bg-gray-300 text-neutral-600 placeholder:text-neutral-500 border rounded-md border-brandGreen"
                />
              </div>
              <div className="flex flex-col">
                <label
                  htmlFor="email"
                  className="text-neutral-300 font-semibold"
                >
                  Email
                </label>
                <input
                  onChange={handleChange}
                  name="email"
                  value={formData.email}
                  type="text"
                  placeholder="Enter email"
                  className="bg-gray-300 text-neutral-600 placeholder:text-neutral-500 border rounded-md border-brandGreen"
                />
              </div>
              <div className="flex flex-col">
                <label
                  htmlFor="message"
                  className="text-neutral-300 font-semibold"
                >
                  Message
                </label>
                <textarea
                  onChange={handleChange}
                  name="message"
                  value={formData.message}
                  type="text"
                  placeholder="Send message"
                  className="bg-gray-300 text-neutral-600 placeholder:text-neutral-500 border rounded-md border-brandGreen"
                />
              </div>
              <div className="flex flex-col pt-4">
                <CustomButton
                  disabled={loading}
                  variant={"primary"}
                  onClick={handleSubmit}
                  label={loading ? "Sending..." : "Send"}
                />
              </div>
            </div>
          ) : (
            <>
              <CustomButton
                variant={"primary"}
                onClick={() => setVisible(true)}
                label="Send Feedback"
                className={"px-3"}
              />
            </>
          )}
        </div>
        <ul className="text-center flex flex-col space-y-4 text-gray-400 font-inter text-xs font-medium leading-4 md:text-left">
          <li className="font-semibold text-lg text-white">Now Invite</li>
          <li>
            <a href="/sign-in">Login</a>
          </li>
          <li>
            <a href="/pricing">Pricing</a>
          </li>
        </ul>

        <ul className="text-center flex flex-col space-y-4 text-gray-400 font-inter text-xs font-medium leading-4 md:text-left">
          <li className="font-semibold text-lg text-white">FEATURES</li>
          <li>
            <a href="/rsvp">RSVP-Form builder</a>
          </li>
          <li>
            <a href="/rsvp">Event Management</a>
          </li>
          <li>
            <a href="/rsvp">Email Invitation</a>
          </li>
          <li>
            <a href="/rsvp">QR Code Ticketing</a>
          </li>
          <li>
            <a href="/rsvp">Event Hosting</a>
          </li>
        </ul>

        <ul className="text-center flex flex-col space-y-4 text-gray-400 font-inter text-xs font-medium leading-4 md:text-left">
          <li className="font-semibold text-white text-lg">COMPANY</li>
          <li>
            <a href="/about-us">About Us</a>
          </li>
          <li>
            <a href="/careers">Careers</a>
          </li>
        </ul>
      </div>

      <div className="flex flex-col gap-4 lg:flex-row items-center mt-10 md:mt-14  lg:mr-28 lg:space-x-8">
        <ul className="flex space-x-4 text-white text-2xl">
          <li>
            <a href="#facebook">
              <CiFacebook />
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/now_invite?igsh=ZWJhbGt3ejgyanpt">
              <CiInstagram />
            </a>
          </li>
          <li>
            <a href="#twitter">
              <FaXTwitter />
            </a>
          </li>
          <li>
            <a href="@now_invite">
              <CiLinkedin />
            </a>
          </li>
          {/* <li>
            <a href="#youtube">
              <CiYoutube />
            </a>
          </li> */}
        </ul>
        <ul className="flex space-x-4 text-gray-400 font-inter text-xs font-medium leading-4">
          <li>
            <a href="#terms">Terms of Service</a>
          </li>
          <li>
            <a href="#privacy">Privacy</a>
          </li>
          <li>
            <a href="#return-refund">Return and Refund</a>
          </li>
        </ul>
        <div className="flex-grow" />
        <p className="text-gray-400 text-sm font-inter font-medium leading-4">
          powered by Ohh company© 2024
        </p>
      </div>
    </div>
  );
};

export default Footer;
