import React from 'react';
import Layout from '../components/common/Layout';
import SentEmail from '../components/DashboardSections/SentEmail';

function EmailSentPage() {
  return (
    <Layout>
      <SentEmail />
    </Layout>
)
}

export default EmailSentPage