import { TrashIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';
import { IoMdPerson } from 'react-icons/io';
import MainModal from '../modal/MainModal';

const AccountSection = ({ user, handleSave, handleCancel }) => {
  const [fullName, setFullName] = useState(user.displayName || '');
  const [email, setEmail] = useState(user.email || '');
  const [profilePic, setProfilePic] = useState({
    file: null,
    img: null,
  })
  const [previewModalOpen, setPreviewModalOpen] = useState(false);


  const handleSaveClick = () => {
    handleSave(fullName, email);
  };

  const handleCancelClick = () => {
    handleCancel();
  };

  // Handling profile pic upload
  const handleProfileUpload = (e) => {
    e.preventDefault();

    // If there is an img return
    if(profilePic.img) {
      setPreviewModalOpen(true);
      return;
    }

    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('class', 'opacity-0');
    input.setAttribute('maxLength', 1);
    input.onchange = (e) => {
      const file = e.target.files[0];
      setProfilePic((profilePic) => ({...profilePic, file: file, img: URL.createObjectURL(file)}));
    };

    input.click();
  }

  return (
    <div className='flex flex-col lg:flex-row'>
      <div className='w-full lg:w-2/3'>
        <div className='mb-6'>
          <label className="block text-gray-600 text-sm font-bold mb-1">
            Full Name
          </label>
          <input
            type='text'
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            placeholder='Full Name'
            className="shadow appearance-none placeholder:text-sm border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-brandGreen/40 focus:ring focus:shadow-outline"
            />
        </div>
        <div className='mb-6'>
          <label className="block text-gray-600 text-sm font-bold mb-1">
            E-mail
          </label>
          <input
            type='email'
            value={email}
            placeholder='Enter your email'
            onChange={(e) => setEmail(e.target.value)}
            className="shadow appearance-none placeholder:text-sm border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-brandGreen/40 focus:ring focus:shadow-outline"
            />
        </div>
          <div className='flex gap-3 mt-4'>
            <button
              onClick={handleSaveClick}
              className='bg-brandGreen text-white px-6 py-2 rounded-md hover:bg-green-700'
            >
              Save
            </button>
            <button
              onClick={handleCancelClick}
              className=' px-6 py-2 rounded-md bg-neutral-200 text-neutral-800'
            >
              Cancel
            </button>
          </div>
      </div>
      <div className='w-full lg:w-1/3 flex justify-center items-center mt-6 lg:mt-0'>
        <div onClick={handleProfileUpload} className='text-center relative rounded-full'>
          {
            profilePic.img ? (
              <div className='flex flex-col items-center gap-1'>
                <img src={profilePic.img} className='w-16 h-16 object-cover rounded-full border-2 border-neutral-300 cursor-pointer' alt={"Profile"} />
                <TrashIcon onClick={() => setProfilePic({
                  file: null,
                  img: null
                })} className='w-5 stroke-red-600 cursor-pointer'/>
              </div>
            ) : 
            <>
             <IoMdPerson className='text-6xl cursor-pointer border border-yellow-500 p-4 rounded-full text-yellow-500' />
              <button
                className='bg-brandGreen absolute top-[57%] rounded-b-full right-1/2 translate-x-1/2 text-sm text-white px-4 py-1'
              >
                Edit
              </button>
            </>
          }
        </div>
      </div>

      {
        previewModalOpen && profilePic.img && 
        <MainModal className={'mt-0 w-fit max-w-[80vw] max-h-[80vh]'} isOpen={previewModalOpen} closeModal={() => setPreviewModalOpen(false)} title={"Your profile picture"}>
          <img src={profilePic.img} alt='Profile' />
        </MainModal>
      }
    </div>
  );
};

export default AccountSection;
