import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/common/Header';
import Footer from '../components/common/Footer';
import forgotPasswordImg from '../assets/images/forgot-password.png';
import { auth } from '../config/firebase';
import { sendPasswordResetEmail, confirmPasswordReset } from 'firebase/auth';
import PageContainer from '../components/containers/PageContainer';

function ForgotPasswordPage() {
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [showCodeInput, setShowCodeInput] = useState(false); // Flag to show code input
  const navigate = useNavigate();

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSend = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');

    try {
      await sendPasswordResetEmail(auth, email);
      setMessage('A verification code has been sent to your email.');
      setShowCodeInput(true); // Show the code input after sending the email
    } catch (error) {
      setError(error.message);
    }
  };

  const handleConfirm = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');

    try {
      await confirmPasswordReset(auth, code);
      setMessage('Verification successful! Redirecting...');
      setTimeout(() => {
        navigate('/sing-in');
      }, 3000); // Redirect to login after 3 seconds
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <PageContainer>
      <Header />
      <div className='w-full bg-gray-200 px-2 lg:p-0 py-4'>
      <div className='flex items-center my-8 md:my-4 justify-center md:p-6 rounded-lg gap-16 '>
        <img src={forgotPasswordImg} alt='forgot password' className='h-[30rem] hidden md:block' />
        <div className='w-full max-w-sm flex flex-col items-center gap-4 bg-gray-50 py-6 md:rounded-xl px-3'>
          <h2 className='text-2xl font-bold'>Forgot Password</h2>
          {message && <p className='text-green-500'>{message}</p>}
          {error && <p className='text-red-500'>{error}</p>}
          {!showCodeInput ? (
            <form onSubmit={handleSend} className='w-full'>
              <div className='mb-4'>
                <label htmlFor='email' className='block text-sm text-gray-600'>Email</label>
                <input
                  type='email'
                  name='email'
                  id='email'
                  value={email}
                  placeholder='Enter your email'
                  onChange={handleChange}
                  className='w-full px-3 py-2 rounded-lg border focus:outline-none focus:ring-brandGreen/40 focus:ring pr-10'
                  required
                />
              </div>
              <button
                type='submit'
                className='w-full bg-brandGreen text-white py-2 rounded-lg hover:bg-brandGreen/80 focus:outline-none'
                >
                Send Code
              </button>
            </form>
          ) : (
            <form onSubmit={handleConfirm} className='w-full'>
              <div className='mb-4'>
                <label htmlFor='code' className='block text-gray-700'>Verification Code</label>
                <input
                  type='text'
                  name='code'
                  id='code'
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  className='w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-brandGreen'
                  required
                />
              </div>
              <button
                type='submit'
                className='w-full bg-brandGreen text-white py-2 rounded-lg hover:bg-brandGreen focus:outline-none'
              >
                Confirm
              </button>
            </form>
          )}
          <div className='flex items-center gap-1'>
            <p className='text-sm'>Return to login?</p>
            <button
              className='cursor-pointer focus:outline-none text-sm  text-blue-400 underline '
              onClick={() => navigate('/sign-in')}
            >
              Login
            </button>
          </div>
        </div>
      </div>
      </div>
      <Footer />
    </PageContainer>
  );
}

export default ForgotPasswordPage;
