import axios from "axios";
import { apiUrl } from "../utils/const";

const fetchEvent = async (eventId) => {
  return await axios.get(`${apiUrl}/api/events/${eventId}`);
};

const publishEvent = async (eventId) => {
  return await axios.put(`${apiUrl}/api/events/${eventId}/publish`);
};

const unpublishEvent = async (eventId) => {
  return await axios.put(`${apiUrl}/api/events/${eventId}/unpublish`);
}

const getPublishedEvent = async () => {
  return await axios.get(`${apiUrl}/api/events/subdomain`)
}

export { fetchEvent, publishEvent, unpublishEvent, getPublishedEvent };
