import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import 'reactjs-popup/dist/index.css';

const CustomPopup = ({ triggerContent, children }) => {
  const [popupOpen, setPopupOpen] = useState(false);

  return (
    <Popup
      trigger={
        <button
          className="flex items-center font-inter font-semibold text-18px text-brandGreen"
          onClick={() => setPopupOpen(!popupOpen)}
        >
          {triggerContent}
          {popupOpen ? (
            <ChevronUpIcon className="h-5 w-5 ml-1" aria-hidden="true" />
          ) : (
            <ChevronDownIcon className="h-5 w-5 ml-1" aria-hidden="true" />
          )}
        </button>
      }
      position="bottom right"
      closeOnDocumentClick
      onClose={() => setPopupOpen(false)}
      contentStyle={{
        width: '180px',
        // height: '140px',
        right: '0',
        top: '4rem',
        border: '2px solid #00A67E', // assuming brandGreen is #00A67E
        borderRadius: '0.5rem',
          }}
      arrow={false}
    >
      <div className="bg-white p-4">
        {children}
      </div>
    </Popup>
  );
};

export default CustomPopup;
