// rootReducer.js
import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "./authReducer";
import inviteeReducer from "./inviteeReducer";
import eventReducer from "./eventReducer";
// Import other reducers as needed

const rootReducer = combineReducers({
  auth: authReducer,
  // Other reducers go here
  invitee: inviteeReducer,
  event: eventReducer,
});

export default rootReducer;
