import React from 'react'
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
function TemplateCustomize({prevStep,handleSubmit,loading,values}) {
  return (
    <div>
       <h1 className='w-full mx-auto text-3xl font-bold'>TemplateCustomize
        </h1> 
        <button onClick={handleSubmit} disabled={loading} className="bg-green-500 rounded-md text-white p-2">
        {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Submit'}
      </button>
        </div>
  )
}

export default TemplateCustomize