// SignUpPage.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Header from '../components/common/Header';
import Footer from '../components/common/Footer';
import signupImg from '../assets/images/signup.png';
import { auth } from '../config/firebase';
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { apiUrl } from '../utils/const';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { loginSuccess } from '../redux/reducers/authReducer';
import { FcGoogle } from 'react-icons/fc';
import PageContainer from '../components/containers/PageContainer';
import { Error, Success } from '../utils/alert';
import { cn } from '../lib/basic';

function SignUpPage() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
  });

  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleGoogleSignIn = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const token = await user.getIdToken();
      const full_user_name = user.displayName.split(' ');
      // Check if the user already exists in Firestore
      const {
        data: { exists },
      } = await axios.get(`${apiUrl}/api/users/check`, {
        params: { email: user.email },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // If the user does not exist, register them
      if (!exists) {
        await axios.post(
          `${apiUrl}/api/users/register`,
          {
            firstName: full_user_name[0],
            lastName: full_user_name[1] ?? '',
            email: user.email,
            googleSignIn: true,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }

      const User = {
        uid: user.uid,
        email: user.email,
        displayName: user.displayName,
        role: 'USER',
        token: token,
      };

      dispatch(loginSuccess(User));

      Success({
        title: 'Success',
        message: 'Welcome ' + user.displayName + '!',
      });
      setTimeout(() => {
        navigate('/');
      }, 3000);
      console.log('logged in');
    } catch (error) {
      console.error('Error during Google auth:', error);
      setError(error.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    setLoading(true);

    const { firstName, lastName, email, password, confirmPassword } = formData;

    if (password !== confirmPassword) {
      setLoading(false);
      return setConfirmPasswordError(true);
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      await updateProfile(userCredential.user, {
        displayName: `${firstName} ${lastName}`,
      });
      const token = await userCredential.user.getIdToken();
      await axios.post(
        `${apiUrl}/api/users/register`,
        {
          firstName,
          lastName,
          email,
          googleSignIn: false,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const User = {
        uid: userCredential.user.uid,
        email: userCredential.user.email,
        displayName: userCredential.user.displayName,
        role: 'USER',
        token: token,
      };

      dispatch(loginSuccess(User));
      setSuccess('User registered successfully!');
      setTimeout(() => {
        navigate('/');
      }, 3000); // Redirect to home after 3 seconds
    } catch (error) {
      setError(error.message);
      Error({
        title:
          error.message === 'Firebase: Error (auth/email-already-in-use).'
            ? 'Email already in use'
            : 'Something went wrong.',
        message: 'Use a new email',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageContainer>
      <Header link={'Login/Sign-up'} />
      <div className="w-full bg-gray-200 px-2 lg:p-0 py-4">
        <div className="flex items-center my-8 md:my-4 justify-center md:p-6 rounded-lg gap-16 ">
          <img
            src={signupImg}
            alt="signup"
            className="h-[30rem] hidden md:block"
          />

          <div className="w-full max-w-md flex flex-col items-center gap-4 bg-gray-50 py-6 md:rounded-xl px-3 md:px-7">
            <h2 className="text-2xl font-bold">Sign Up</h2>

            <button
              className="focus:outline-none space-x-2 flex items-center justify-center gap-2 bg-neutral-50 text-black w-full py-2 px-4 rounded-lg border border-gray-300 hover:bg-gray-100"
              onClick={handleGoogleSignIn}
            >
              <FcGoogle size={28} /> Continue with Google
            </button>

            <p className="text-neutral-500 text-sm md:text-left text-center">
              Fill in the form below to create an account
            </p>

            {/* {error && <p className='text-red-500'>{error}</p>} */}
            {success && <p className="text-green-500">{success}</p>}

            <form onSubmit={handleSubmit}>
              <div className="flex gap-4 mb-4 md:flex-row flex-col">
                <div className="">
                  <label
                    htmlFor="firstName"
                    className="block text-sm text-gray-600"
                  >
                    First Name
                  </label>
                  <input
                    type="text"
                    name="firstName"
                    id="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    className="w-full px-3 py-2 rounded-lg border focus:outline-none placeholder:text-sm focus:ring-brandGreen/40 focus:ring"
                    placeholder="Enter your first name"
                    required
                  />
                </div>
                <div className="">
                  <label
                    htmlFor="lastName"
                    className="block text-sm text-gray-600"
                  >
                    Last Name
                  </label>
                  <input
                    type="text"
                    name="lastName"
                    id="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    className="w-full px-3 py-2 rounded-lg border focus:outline-none placeholder:text-sm focus:ring-brandGreen/40 focus:ring"
                    placeholder="Enter your last name"
                    required
                  />
                </div>
              </div>

              <div className="mb-4">
                <label htmlFor="email" className="block text-sm text-gray-600">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full px-3 py-2 rounded-lg border focus:outline-none placeholder:text-sm focus:ring-brandGreen/40 focus:ring"
                  placeholder="Enter your email"
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="password"
                  className="block text-sm text-gray-600"
                >
                  Password
                </label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  value={formData.password}
                  onChange={handleChange}
                  className={cn(
                    'w-full px-3 py-2 rounded-lg border focus:outline-none placeholder:text-sm focus:ring-brandGreen/40 focus:ring',
                    confirmPasswordError &&
                      'border-red-500 text-red-500 ring-red-500'
                  )}
                  placeholder="Enter your password"
                  required
                />
              </div>
              <div className="mb-8">
                <label
                  htmlFor="confirmPassword"
                  className="block text-sm text-gray-600"
                >
                  Confirm Password
                </label>
                <input
                  type="password"
                  name="confirmPassword"
                  id="confirmPasswprd"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  className={cn(
                    'w-full px-3 py-2 rounded-lg border focus:outline-none placeholder:text-sm focus:ring-brandGreen/40 focus:ring',
                    confirmPasswordError &&
                      'border-red-500 text-red-500 ring-red-500'
                  )}
                  placeholder="Confirm your password"
                  required
                />
                {confirmPasswordError && (
                  <p className="text-sm text-red-500 mt-1">
                    Passwords don&apos;t match.
                  </p>
                )}
              </div>
              <button
                type="submit"
                className={`w-full bg-brandGreen text-white py-2 rounded-lg hover:bg-brandGreen/80 focus:outline-none ${
                  loading ? 'opacity-50 cursor-not-allowed' : ''
                }`}
                disabled={loading}
              >
                {loading ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : (
                  'Sign Up'
                )}
              </button>
            </form>
            <div className="flex items-center gap-1">
              <p className="text-sm">Already have an account? </p>
              <button
                onClick={() => navigate('/sign-in')}
                className="cursor-pointer text-sm focus:outline-none text-blue-400 underline "
              >
                Login
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </PageContainer>
  );
}

export default SignUpPage;
