import { useEffect, useState } from "react";
import { bg_1, bg_2, bg_3 } from "../assets";
import { cn, formatTime } from "../lib/basic";
import { FaLocationDot } from "react-icons/fa6";
import { MdOutlineSchedule } from "react-icons/md";
import { GrSchedulePlay } from "react-icons/gr";
import { Error } from "../utils/alert";
import { motion } from "framer-motion";
import { getPublishedEvent } from "../service/Event.service";
import { useNavigate } from "react-router-dom";
import { ColorRingOptions } from "../components/DashboardSections/PreviewInvitee";
import { ColorRing } from "react-loader-spinner";
import dayjs from "dayjs";

const RSVP = () => {
  const [width, setWidth] = useState(null);
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
  });
  const [activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate();
  const [eventData, setEventData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % eventData?.imgs?.length);
    }, 3000); // Change every 2 seconds

    return () => clearInterval(interval); // Clean up interval on unmount
  }, [eventData?.imgs?.length]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const [selected, setSelected] = useState({
    yes: true,
    no: false,
  });

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Fetch event data based on the hosting url or subdomain
  useEffect(() => {
    const fetchEvent = async () => {
      setLoading(true);
      try {
        const response = await getPublishedEvent();
        const { status, data } = response;
        setEventData({
          title: data.eventName,
          description: data.description,
          date: data.eventDate,
          location: data.location,
          programs: data.schedules?.map((sch) => ({
            title: sch.name,
            time: formatTime(sch.time),
          })),
        });
      } catch (error) {
        window.location.href = "nowinvite.com";
      } finally {
        setLoading(false);
      }
    };

    fetchEvent();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { firstName, lastName, phoneNumber } = values;
    if (!firstName || !lastName || !phoneNumber)
      return Error({
        message: "All fields are required",
        title: "Failed submission",
      });

    const rsvp_form = {
      ...values,
      response: selected.yes ? "attending" : "not-attending",
    };

    alert(JSON.stringify(rsvp_form));
  };

  return (
    <div className="h-full flex-grow flex-col overflow-hidden md:px-16 px-4 py-6">
      {loading ? (
        <div className="h-screen mx-auto flex items-center justify-center">
          <ColorRing {...ColorRingOptions} />
        </div>
      ) : (
        <>
          {" "}
          <div className="flex justify-center items-center gap-2">
            {eventData?.imgs?.map((img, index) => (
              <motion.img
                key={index}
                src={img}
                alt="bg"
                style={{
                  zIndex: index === activeIndex ? "9999" : "inherit",
                }}
                className={cn(
                  "h-64 object-cover rounded-md md:rounded-3xl",
                  `w-${Math.ceil(width / 9)}`
                )}
                initial={{ scale: 1 }}
                animate={
                  index === activeIndex ? { scale: [1, 1.2, 1] } : { scale: 1 }
                }
                transition={{
                  duration: 3,
                  ease: "easeInOut",
                }}
              />
            ))}
          </div>
          <div className="max-w-5xl mx-auto">
            <h1 className="text-4xl font-bold text-neutral-700 text-start mt-6">
              {eventData.title}
            </h1>

            <p className="text-start text-sm mt-4 text-neutral-500">
              {eventData.description}
            </p>

            <div
              className={cn(
                "flex items-start md:items-center md:flex-row flex-col space-y-3 md:space-x-6 md:space-y-0 mt-4 text-sm text-neutral-500 "
              )}
            >
              <div className="flex items-center space-x-2">
                <GrSchedulePlay size={23} color="#646363" />

                <span>{dayjs(eventData.date).format("YYYY-MM-DD")}</span>
              </div>

              <div className="flex items-center space-x-2">
                <FaLocationDot size={23} color="#646363" />

                <span>{eventData.location}</span>
              </div>
            </div>

            <div className="flex items-center justify-center md:justify-start gap-3 flex-wrap mt-5">
              <ProgramCard
                title={"Schedule"}
                Value={() => <MdOutlineSchedule className="text-2xl" />}
              />

              {eventData.programs?.map((program, index) => {
                return (
                  <ProgramCard
                    title={program.title}
                    key={index}
                    Value={() => (
                      <div className="flex flex-col items-start gap-2">
                        <span className="text-sm text-neutral-700 font-semibold">
                          {program.time}
                        </span>
                      </div>
                    )}
                  />
                );
              })}
            </div>

            <div className="mt-8 md:mt-4">
              <h3 className="mb-3 font-bold text-neutral-700">
                RSVP information form
              </h3>

              <form onSubmit={handleSubmit}>
                <div className="flex flex-col gap-4">
                  {" "}
                  <div className="grid md:grid-cols-2 gap-y-4 md:gap-x-4">
                    <InputField
                      label="First Name"
                      name="firstName"
                      onChange={handleChange}
                      value={values.firstName}
                      placeholder="John"
                    />
                    <InputField
                      label="Last Name"
                      name="lastName"
                      onChange={handleChange}
                      value={values.lastName}
                      placeholder="Doe"
                    />
                  </div>
                  <InputField
                    label="Phone"
                    placeholder="+1 "
                    name="phoneNumber"
                    onChange={handleChange}
                  />
                </div>

                <div className="flex items-center space-x-0 mt-3">
                  <button
                    type="button"
                    onClick={() =>
                      setSelected({
                        yes: true,
                        no: false,
                      })
                    }
                    className={cn(
                      selected.yes
                        ? "border  border-green-500 bg-green-50"
                        : "opacity-40 border border-opacity-50 border-red-500",
                      "w-[150px] rounded-md  border-r-0 rounded-tr-none rounded-br-none text-center py-3 px-3 text-sm font-semibold text-neutral-600"
                    )}
                  >
                    &#128512; I will be there
                  </button>

                  <button
                    type="button"
                    className={cn(
                      selected.no
                        ? "border border-green-500 bg-green-50"
                        : "opacity-40 border border-opacity-50 border-red-500",
                      "w-[150px] rounded-md border-l-0 rounded-bl-none rounded-tl-none text-center py-3 px-3 text-sm font-semibold text-neutral-600"
                    )}
                    onClick={() => {
                      setSelected({
                        yes: false,
                        no: true,
                      });
                    }}
                  >
                    &#128542; Can't make it
                  </button>
                </div>

                <input
                  type="submit"
                  value={"Submit RSVP"}
                  className="mt-4 bg-black text-white py-2 px-4 font-semibold text-sm rounded-md cursor-pointer"
                />
              </form>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const InputField = ({ label, name, ...props }) => {
  return (
    <div>
      <label
        htmlFor={name}
        className="uppercase mb-1 text-neutral-500 block text-sm"
      >
        {label}
      </label>

      <input
        className="w-full focus:border-gray-400 outline-none py-2 px-3 border rounded-md placeholder:text-gray-500 text-neutral-600"
        {...props}
      />
    </div>
  );
};

const ProgramCard = ({ title, Value }) => {
  return (
    <div className="flex items-center justify-center gap-3 flex-col h-[120px] w-[240px] border rounded-md">
      <h2 className="text-sm text-neutral-500">{title}</h2>

      <Value />
    </div>
  );
};

// const fake_rsvp = {
//   title: "Annual Gala Night 2023",
//   description:
//     "Join us for the evening of elegance, celebration and connection at our Annual Gala Night 2023. Enjoy a cocktail reception,a guest dinner witha key note speech, and a night of dancing and entertainment.",
//   date: "December 15, 2023",
//   location:
//     "The Grand Ballroom, The Ritz-Carlton, 1234 Main Street, New York, NY 10001",
//   programs: [
//     {
//       title: "Cocktail Reception",
//       time: "6:00 PM",
//       description:
//         "Join us for a cocktail reception to kick off the evening. Enjoy a selection of hors d'oeuvres and drinks as you mingle with other guests.",
//     },
//     {
//       title: "Dinner & Keynote Speech",
//       time: "7:00 PM",
//       description:
//         "Sit down for a delicious dinner and listen to a keynote speech from our special guest speaker.",
//     },
//     {
//       title: "Dancing & Entertainment",
//       time: "9:00 PM",
//       description:
//         "Dance the night away and enjoy live entertainment from our special performers.",
//     },
//   ],
//   imgs: [bg_1, bg_2, bg_3],
// };

export default RSVP;
