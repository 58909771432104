import React from 'react';
import { FaCog } from 'react-icons/fa';

function LocationSection({
  eventData,
  handleContentChange,
  openEditor,
  isEditing,
}) {
  const locationContent = eventData.contents.find(
    (item) => item.type === 'location'
  );

  return (
    <section
      className="relative w-full p-6 bg-cover bg-center flex"
      style={{
        backgroundColor:
          eventData.custom_design.location_styles.background_color,
        color: eventData.custom_design.location_styles.color,
        padding: eventData.custom_design.location_styles.padding,
      }}
    >
      <div className="w-full">
        <h3
          className={`text-2xl font-bold`}
          //   contentEditable={isEditing}
          //   suppressContentEditableWarning={true}
          //   onBlur={(e) =>
          //     isEditing && handleContentChange('location', e.target.innerText)
          //   }
        >
          {locationContent?.title || 'Event Location'}
        </h3>

        <p
          className={`text-lg mt-2 ${
            isEditing ? 'px-2 hover:shadow-dashed hover:shadow-white' : ''
          }`}
          contentEditable={isEditing}
          suppressContentEditableWarning={true}
          onBlur={(e) =>
            isEditing && handleContentChange('location', e.target.innerText)
          }
        >
          {locationContent?.text || 'Location address'}
        </p>

        {locationContent?.map_link && (
          <a
            href={locationContent.map_link}
            className="text-blue-500 block mt-4 mx-auto max-w-40"
            target="_blank"
            rel="noopener noreferrer"
          >
            View on Google Maps
          </a>
        )}
      </div>

      {isEditing && (
        <FaCog
          className="absolute text-xl top-4 right-4 cursor-pointer"
          onClick={() => openEditor(locationContent)}
        />
      )}
    </section>
  );
}

export default LocationSection;
