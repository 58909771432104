// ProtectedRoute.js
import React from 'react';
import {Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectIsLoggedIn } from '../redux/reducers/authReducer';

const ProtectedRoute = ({ children }) => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const location = useLocation();
  console.log(isLoggedIn)
  if (!isLoggedIn) {
    return <Navigate to={`/sign-in?redirect=${location.pathname}`} />;
  }

  return children;
};

export default ProtectedRoute;
