import React from 'react';
import Header from '../components/common/Header';
import Footer from '../components/common/Footer';
import heroBanner from '../assets/images/pricing.png';
import Pricing from '../components/pricing/Pricing';
import FreePricing from "../components/pricing/FreePricing";
import PageContainer from '../components/containers/PageContainer';

const PricingPage = () => {
  return (
    <PageContainer>
      <div className="">
        <Header link={"Price"} />
        <img
          src={heroBanner}
          alt="party image"
          className="lg:my-10 w-full h-auto max-h-[calc(100vh-4rem)] sm:max-h-[calc(100vh-6rem)] md:max-h-[calc(100vh-8rem)] lg:max-h-[calc(100vh-10rem)] xl:max-h-[calc(100vh-12rem)] 2xl:max-h-[calc(100vh-20rem)] object-cover rounded-xl opacity-100"
        />
      </div>
      {/* <Pricing /> */}
      <h1 className="text-2xl font-bold text-center mx-auto"> Pricing </h1>
      <FreePricing />
      <Footer />
    </PageContainer>
  );
};

export default PricingPage;
