import Layout from "../components/common/Layout";
import PreviewInvitee from "../components/DashboardSections/PreviewInvitee";

const PreviewInviteePage = () => {
  return (
    <Layout>
      <PreviewInvitee />
    </Layout>
  );
};

export default PreviewInviteePage;
