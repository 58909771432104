import React from "react";
import NotHiring from "../assets/illu1.png";

export default function Career() {
  return (
    <div className="h-full flex items-center justify-center bg-[#F5F2F0] m-8 py-8 rounded-2xl">
      <div className="max-w-2xl w-full px-4 py-8 text-center">
        <div className="flex flex-col md:flex-row items-center justify-center gap-8 md:gap-12">
          <div className="relative h-96 w-96">
            {/* Illustration container */}
            <div className="absolute inset-0">
              <img
                src={NotHiring}
                alt="Not Hiring"
                className="object-cover w-full h-96"
              />
            </div>
          </div>

          <div className="space-y-8">
            <h1 className="text-[#2D2F45] text-4xl font-bold w-full tracking-wider">
              We're not hiring at this time.
            </h1>
            <p className="text-[#FFA726] text-xl font-bold tracking-wide">
              Check back soon!
            </p>
            <a
              href="/"
              className="block px-6 py-2 bg-[#006B3D] text-white rounded-md hover:bg-[#005732] transition-colors"
            >
              Back to Home
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
