import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaPlus, FaTimes } from "react-icons/fa";
import axios from "axios";
import { apiUrl } from "../utils/const";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectToken } from "../redux/reducers/authReducer";
import Toast from "../components/UI/toast";
import { ColorRingOptions } from "../components/DashboardSections/PreviewInvitee";
import { ColorRing } from "react-loader-spinner";
import CustomButton from "../components/Button/Buttons";

export default function EventCustomizer() {
  const { eventId } = useParams();
  const token = useSelector(selectToken);

  const [loading, setLoading] = useState(true);
  const [timezone, setTimeZone] = useState("");

  const [eventData, setEventData] = useState({
    eventName: "",
    description: "",
    location: "",
    eventDate: new Date(),
    startTime: "",
    images: [],
  });
  const [images, setImages] = useState([]);
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });

  const [schedules, setSchedules] = useState([
    { name: undefined, time: undefined },
  ]);

  useEffect(() => {
    const fetchEvent = async () => {
      setLoading(true);
      try {
        if (eventId) {
          const response = await axios.get(`${apiUrl}/api/events/${eventId}`);
          const start_time = new Date(response.data.start_time);
          const userLocalTime = start_time.toLocaleTimeString(undefined, {
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          });
          setEventData({
            eventName: response.data.eventName,
            description: response.data.description,
            location: response.data.location,
            eventDate: new Date(response.data.start_date),
            startTime: userLocalTime,
          });
          if (
            Array.isArray(response.data.schedules) &&
            response.data.schedules.length > 0
          ) {
            setSchedules(
              response.data.schedules.map((schedule) => ({
                name: schedule.name,
                time: schedule.time,
              }))
            );
          }
          const detectedTimezone =
            Intl.DateTimeFormat().resolvedOptions().timeZone;
          setTimeZone(detectedTimezone);
        }
      } catch (error) {
        console.error("Error fetching event:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchEvent();
  }, [eventId]);

  const handleEventDataChange = (e) => {
    const { name, value } = e.target;
    setEventData((prev) => ({ ...prev, [name]: value }));
  };

  const handleDateChange = (date) => {
    if (date) {
      setEventData((prev) => ({ ...prev, eventDate: date }));
    }
  };

  const handleImageChange = (e) => {
    const selectedFiles = Array.from(e.target.files);

    // Disable any existing alert
    setAlert({ show: false, message: "", type: "" });

    // Validate file types and size
    const validImageFiles = selectedFiles.filter((file) => {
      // Check file type
      if (!file.type.startsWith("image/")) {
        setAlert({
          show: true,
          message: "Please upload an image file",
          type: "error",
        });
        return false;
      }

      // Check file size (10MB limit)
      if (file.size > 10 * 1024 * 1024) {
        setAlert({
          show: true,
          message: "Please upload an image file smaller than 10MB",
          type: "error",
        });
        return false;
      }

      return true;
    });

    // Set images for upload
    setImages(validImageFiles);
  };

  const handleScheduleChange = (index, field, value) => {
    const newSchedules = [...schedules];
    if (field === "time") {
      newSchedules[index][field] = new Date(
        `${eventData.eventDate.toDateString()} ${value}`
      );
    } else {
      newSchedules[index][field] = value;
    }
    setSchedules(newSchedules);
  };

  const addSchedule = () => {
    if (schedules.length < 3) {
      setSchedules([...schedules, { name: "", time: "" }]);
    }
  };

  const removeSchedule = (index) => {
    setSchedules(schedules.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append(
      "updatedEventData",
      JSON.stringify({
        eventName: eventData.eventName,
        description: eventData.description,
        location: eventData.location,
        eventDate: eventData.eventDate,
        start_time: new Date(
          `${eventData.eventDate.toDateString()} ${eventData.startTime}`
        ).toISOString(),
        schedules: schedules,
      })
    );
    images.forEach((image, index) => {
      formData.append("images", image);
    });

    try {
      await axios.put(`${apiUrl}/api/events/${eventId}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      setAlert({
        show: true,
        message: "Event updated successfully!",
        type: "success",
      });
    } catch (error) {
      setAlert({ show: true, message: "Error updating event.", type: "error" });
    } finally {
      setTimeout(() => {
        setAlert({ show: false, message: "", type: "" });
      }, 3000);
    }
  };

  return (
    <div className="container mx-auto p-4 max-w-4xl">
      <h1 className="text-2xl font-bold mb-6 text-center">
        Customize Your Event
      </h1>
      {loading ? (
        <div className="w-full flex-1 h-full">
          <ColorRing {...ColorRingOptions} />
        </div>
      ) : (
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="grid md:grid-cols-2 gap-6">
            <div className="space-y-4">
              <div>
                <label
                  htmlFor="eventName"
                  className="block text-sm font-medium text-gray-700"
                >
                  Event Name
                </label>
                <input
                  id="eventName"
                  name="eventName"
                  type="text"
                  value={eventData.eventName}
                  onChange={handleEventDataChange}
                  required
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                />
              </div>
              <div>
                <label
                  htmlFor="description"
                  className="block text-sm font-medium text-gray-700"
                >
                  Description
                </label>
                <textarea
                  id="description"
                  name="description"
                  rows="3"
                  value={eventData.description}
                  onChange={handleEventDataChange}
                  required
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                ></textarea>
              </div>
              <div>
                <label
                  htmlFor="location"
                  className="block text-sm font-medium text-gray-700"
                >
                  Location
                </label>
                <input
                  id="location"
                  name="location"
                  type="text"
                  value={eventData.location}
                  onChange={handleEventDataChange}
                  required
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                />
              </div>
              <div>
                <label
                  htmlFor="eventDate"
                  className="block text-sm font-medium text-gray-700"
                >
                  Event Date
                </label>
                <DatePicker
                  id="eventDate"
                  selected={eventData.eventDate}
                  value={eventData.eventDate}
                  onChange={handleDateChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  required
                />
              </div>
              <div className="">
                <div className="flex items-center gap-x-8">
                  <div>
                    <label
                      htmlFor="startTime"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Start Time
                    </label>
                    <input
                      type="time"
                      id="startTime"
                      name="startTime"
                      value={eventData.startTime}
                      onChange={handleEventDataChange}
                      required
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                    <br />
                  </div>
                  <div className="flex items-center italic my-auto">
                    <strong>{timezone}</strong>
                  </div>
                </div>
              </div>
              <div>
                <label
                  htmlFor="images"
                  className="block text-sm font-medium text-gray-700"
                >
                  Upload Images
                </label>
                <input
                  type="file"
                  id="images"
                  name="images"
                  accept="image/*"
                  onChange={handleImageChange}
                  multiple
                  className="mt-1 block w-full text-sm text-gray-500
                file:mr-4 file:py-2 file:px-4
                file:rounded-full file:border-0
                file:text-sm file:font-semibold
                file:bg-violet-50 file:text-brandGreen
                hover:file:bg-violet-100"
                />
              </div>
            </div>
            <div className="space-y-4">
              <div className="flex items-center justify-between">
                <h2 className="text-xl font-semibold">
                  Add Your Schedules (3 max)
                </h2>
                <button
                  type="button"
                  onClick={addSchedule}
                  disabled={schedules.length >= 3}
                  className="p-2 text-indigo-600 hover:text-indigo-900 disabled:opacity-50"
                >
                  <FaPlus className="h-5 w-5" />
                </button>
              </div>
              {schedules.map((schedule, index) => (
                <div key={index} className="space-y-2">
                  <div className="flex items-center space-x-2">
                    <input
                      type="text"
                      placeholder={`schedule ${index + 1}`}
                      value={schedule.name}
                      onChange={(e) =>
                        handleScheduleChange(index, "name", e.target.value)
                      }
                      required
                      className="block w-[200px] rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                    <input
                      type="time"
                      value={new Date(schedule.time).toLocaleTimeString(
                        undefined,
                        {
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: false, // Optional: Use 24-hour format
                        }
                      )}
                      onChange={(e) =>
                        handleScheduleChange(index, "time", e.target.value)
                      }
                      required
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                    {
                      <button
                        type="button"
                        onClick={() => removeSchedule(index)}
                        className="p-2 text-red-600 hover:text-red-900"
                      >
                        <FaTimes className="h-5 w-5" />
                      </button>
                    }
                  </div>
                </div>
              ))}
            </div>
          </div>
          <button
            type="submit"
            className="w-full bg-brandGreen text-white py-2 px-4 rounded-md hover:bg-brandGreen/80"
            onClick={handleSubmit}
          >
            Submit
          </button>
          {alert.show && (
            <Toast
              message={alert.message}
              type={alert.type}
              onClose={() => setAlert({ show: false, message: "", type: "" })}
            />
          )}
        </form>
      )}
    </div>
  );
}
