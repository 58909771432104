import React from 'react';
import { FaCog } from 'react-icons/fa';

function ScheduleSection({
  eventData,
  handleContentChange,
  openEditor,
  isEditing,
}) {
  const scheduleContent = eventData.contents.find(
    (item) => item.type === 'schedule'
  );

  return (
    <section
      className=" w-full relative"
      style={{
        backgroundColor:
          eventData.custom_design.schedule_styles.background_color,
        color: eventData.custom_design.schedule_styles.color,
        padding: eventData.custom_design.schedule_styles.padding,
      }}
    >
      <h2 className="text-2xl font-semibold mb-4">Schedule of Events</h2>
      <ul>
        {scheduleContent?.events?.map((event, index) => (
          <li
            key={index}
            className={`mb-2 ${
              isEditing ? 'px-4 hover:shadow-dashed hover:shadow-white' : ''
            }`}
            contentEditable={isEditing}
            suppressContentEditableWarning={true}
            onBlur={(e) => {
              if (isEditing) {
                const updatedText = e.currentTarget.innerText.split(' - ');
                const updatedSchedule = [...scheduleContent.events];
                updatedSchedule[index] = {
                  time: updatedText[0],
                  name: updatedText[1],
                };
                handleContentChange('schedule', updatedSchedule);
              }
            }}
          >
            <strong>{event.time}</strong> - {event.name}
          </li>
        ))}
      </ul>

      {/* Show setting icon only when in edit mode */}
      {isEditing && (
        <FaCog
          className="absolute text-xl top-4 right-4 cursor-pointer text-black"
          onClick={() => openEditor(scheduleContent)}
        />
      )}
    </section>
  );
}

export default ScheduleSection;
