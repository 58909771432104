import React, { useEffect, useState } from "react";
import WeddingTemplate from "../../components/templates/weeddingTamplate/weeddingTamplate";
import BabyShowerTemplate from "../../components/templates/babyShowerTamplate/BabyShowerTemplate";
import BirthdayTemplate from "../../components/templates/birthdayTamplates/BirthdayTemplate";
import ChurchTemplates from "../../components/templates/churchTemplates/ChurchTemplates";
import CorporateTemplate from "../../components/templates/corporateTamplates/CorporateTemplate";
import BrideShowerTemplate from "../../components/templates/brideShowerTemplates/BrideShowerTemplate";
import ContentToggleSidebar from "../templates/common/ContentToggleSidebar";
import axios from "axios";
import { apiUrl } from "../../utils/const";
function Websitebuilder({ wallId, wallDesign, isEditing }) {
  const [contents, setContents] = useState([]);

  useEffect(() => {
    // Fetch the event contents data
    const fetchContents = async () => {
      try {
        setContents(wallDesign.contents);
      } catch (error) {
        console.error("Error fetching contents:", error);
      }
    };

    fetchContents();
  }, [wallDesign]);

  const handleToggle = async (contentId) => {
    try {
      // Toggle 'show' status in the backend
      await axios.put(
        `${apiUrl}/api/wall/${wallId}/content/${contentId}/toggle-show`
      );

      // Update the 'show' status in the local state
      setContents((prevContents) =>
        prevContents.map((content) =>
          content.id === contentId
            ? { ...content, show: !content.show }
            : content
        )
      );
    } catch (error) {
      console.error("Error toggling show status:", error);
    }
  };

  // Ensure wallDesign is not null and has category before rendering
  const renderTemplate = () => {
    switch (wallDesign.category) {
      case "wedding":
        return (
          <>
            {isEditing && (
              <div className="flex flex-col">
                <ContentToggleSidebar
                  contents={contents}
                  handleToggle={handleToggle}
                />
              </div>
            )}
            <WeddingTemplate
              eventData={wallDesign}
              wallId={wallId}
              isEditing={isEditing}
              contents={contents}
            />
          </>
        );
      case "birthday":
        return <BirthdayTemplate eventData={wallDesign} />;
      case "baby_shower":
        return <BabyShowerTemplate eventData={wallDesign} />;
      case "corporate":
        return <CorporateTemplate eventData={wallDesign} />;
      case "church":
        return <ChurchTemplates eventData={wallDesign} />;
      case "bridal_shower":
        return <BrideShowerTemplate eventData={wallDesign} />;
      default:
        return <p>Template not available!</p>;
    }
  };

  return (
    <div className={`${isEditing ? "flex pl-0 gap-2" : "w-[60%]"}`}>
      {renderTemplate()}
    </div>
  );
}
export default Websitebuilder;
