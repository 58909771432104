import { useState } from 'react';
import { Dialog, DialogPanel, PopoverGroup } from '@headlessui/react';
import {
  Bars3Icon,
  XMarkIcon,
  UserCircleIcon,
} from '@heroicons/react/24/outline';
import Hlogo from '../../assets/images/Hlogo.png';
import {
  selectIsLoggedIn,
  selectUser,
  logoutSuccess,
} from '../../redux/reducers/authReducer';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import CustomPopup from '../UI/ProfilePopUP'; // Import the new CustomPopup component
// import { MdContactSupport } from 'react-icons/md';
import { FaCircleUser } from 'react-icons/fa6';
import { IoIosLogOut } from 'react-icons/io';
import { cn } from '../../lib/basic';

const Header = ({ link }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [activeLink, setActiveLink] = useState(link); // Default active link
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const links = [
    { name: 'Invite', href: '/rsvp' },
    { name: 'About us', href: '/about-us' },
    {
      name:
        isLoggedIn && user
          ? `Hello ${user?.displayName?.split(' ')[0]}`
          : 'Login/Sign-up',
      href: isLoggedIn ? null : '/sign-in',
    },
    // { name: 'Price', href: '/pricing' },
    { name: 'My Dashboard', href: '/dashboard' },
  ];
  const handleLogout = () => {
    dispatch(logoutSuccess());
    navigate('/');
    window.location.reload();
  };
  return (
    <header>
      <nav
        className="bg-white mx-auto flex items-center justify-between py-6"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <Link to="/" className="-m-1.5 p-1.5">
            <span className="sr-only">NowInvite</span>
            <img
              className="h-8 max-w-[230px] md:max-w-auto"
              src={Hlogo}
              alt="company logo"
            />
          </Link>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <PopoverGroup className="hidden lg:flex items-center lg:gap-x-12">
          {links.map((link, index) => (
            <div key={link.name} className="relative">
              {isLoggedIn &&
              link.name === `Hello ${user?.displayName?.split(' ')[0]}` ? (
                <CustomPopup
                  triggerContent={
                    <>
                      <UserCircleIcon
                        className="h-6 w-6 mr-1 text-yellow-500"
                        aria-hidden="true"
                      />
                      {link.name}
                    </>
                  }
                >
                  <button
                    onClick={() => navigate('/my-account')}
                    className=" w-full text-left mb-2  text-brandGreen flex gap-1 items-center"
                  >
                    <FaCircleUser
                      className="h-5 w-5 mr-2 text-yellow-500"
                      aria-hidden="true"
                    />
                    My Account
                  </button>
                  {/* <button
                    onClick={() => alert('Support')}
                    className=" w-full text-left text-brandGreen mb-2 gap-1 flex items-center"
                  >
                    <MdContactSupport
                      className="h-5 w-5 mr-2 text-yellow-500"
                      aria-hidden="true"
                    />
                    Support
                  </button> */}
                  <button
                    onClick={handleLogout}
                    className=" w-full text-left text-brandGreen gap-1 flex items-center"
                  >
                    <IoIosLogOut
                      className="h-5 w-5 mr-2 text-yellow-500"
                      aria-hidden="true"
                    />
                    Sign Out
                  </button>
                </CustomPopup>
              ) : (
                <Link
                  to={link.href}
                  onClick={() => setActiveLink(link.name)}
                  className={cn(
                    `font-inter font-semibold text-18px ${
                      activeLink === link.name
                        ? 'bg-brandGreen text-white rounded-lg px-3 py-1'
                        : 'text-brandGreen hover:text-yellow-500'
                    } ${link.href === '/dashboard' && !isLoggedIn && 'hidden'}`
                  )}
                >
                  {link.name}
                </Link>
              )}
            </div>
          ))}
        </PopoverGroup>
      </nav>

      <Dialog
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={() => setMobileMenuOpen(false)}
      >
        <div className="fixed inset-0 z-10" />
        <DialogPanel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <Link to="/" className="-m-1.5 p-1.5">
              <span className="sr-only"></span>
              <img
                className="h-8 max-w-[230px] md:max-w-auto"
                src={Hlogo}
                alt="company logo"
              />
            </Link>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {links.map((link) => (
                  <Link
                    key={link.name}
                    to={link.href}
                    onClick={() => {
                      setActiveLink(link.name);
                      setMobileMenuOpen(false);
                    }}
                    className={`-mx-3 block rounded-lg px-3 py-2 font-inter font-semibold text-20px ${
                      activeLink === link.name
                        ? 'bg-brandGreen text-white'
                        : 'text-gray-900 hover:bg-gray-50'
                    } ${!isLoggedIn && link.href === "/dashboard" && " hidden"}`}
                  >
                    {link.name}
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </header>
  );
};

export default Header;
