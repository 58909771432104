import React from 'react';
import { FaCog } from 'react-icons/fa';

function StorySection({
  eventData,
  handleContentChange,
  openEditor,
  isEditing,
}) {
  const storyContent = eventData.contents.find((item) => item.type === 'story');

  return (
    <section
      className="relative w-full p-6 bg-cover bg-center flex"
      style={{
        backgroundColor: eventData.custom_design.story_styles.background_color,
        color: eventData.custom_design.story_styles.color,
        padding: eventData.custom_design.story_styles.padding,
      }}
    >
      <div className="w-full">
        <h3 className={`text-2xl font-bold`}>
          {storyContent?.title || 'Our Story'}
        </h3>

        <p
          className={`text-lg mt-2 ${
            isEditing ? 'px-2 hover:shadow-dashed hover:shadow-white' : ''
          }`}
          contentEditable={isEditing} // Enable editing only in editor mode
          suppressContentEditableWarning={true}
          onBlur={(e) =>
            isEditing && handleContentChange('story', e.target.innerText)
          }
        >
          {storyContent?.text || 'Your story goes here'}
        </p>
      </div>

      {isEditing && (
        <FaCog
          className="absolute text-xl top-4 right-4 cursor-pointer"
          onClick={() => openEditor(storyContent)}
        />
      )}
    </section>
  );
}

export default StorySection;
