import axios from "axios";
import { apiUrl } from "../utils/const";

const fetchResponsesFromGuests = async (event_id) => {
  return await axios.get(`${apiUrl}/api/rsvp-response/guest-list/${event_id}`);
};

const exportGuestResponses = async (event_id, format) => {
  return await axios.get(
    `${apiUrl}/api/rsvp-response/guest-list/file/${event_id}?format=${
      format || "csv"
    }`,
    {
      responseType: "blob",
    }
  );
};

export { fetchResponsesFromGuests, exportGuestResponses };
