import Swal from "sweetalert2";

const Success = ({ message, title, onOk, onCancel }) => {
  Swal.fire({
    title: title || "Success",
    text: message || "",
    timer: 3000,
    icon: "success",
    showCloseButton: false,
  });
};

const Error = ({ message, title, onOk, onCancel }) => {
  Swal.fire({
    title,
    text: message || "Please try again later.",
    icon: "error",
    showCloseButton: true,
  });
};

export { Success, Error };
