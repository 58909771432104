import React from 'react';
import { FaCog } from 'react-icons/fa';
import { apiUrl } from '../../../utils/const';

function HeaderSection({
  eventData,
  handleContentChange,
  openEditor,
  isEditing,
  coverImage,
}) {
  const headerContent = eventData.contents.find(
    (item) => item.type === 'header'
  );
  return (
    <header
      className="relative w-full h-screen bg-cover bg-center flex"
      style={{
        backgroundImage: `url(${coverImage})`,
        textAlign: eventData.custom_design.header_styles.text_align,
      }}
    >
      <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-center">
        <div className={`${isEditing ? '' : ''}`}>
          <h1
            className={`text-white text-5xl font-bold ${
              isEditing ? 'px-2 hover:shadow-dashed hover:shadow-white' : ''
            }`}
            contentEditable={isEditing} // Enable editing only in editor mode
            suppressContentEditableWarning={true}
            onBlur={(e) =>
              isEditing && handleContentChange('header', e.target.innerText)
            } // Handle content change only in edit mode
          >
            {headerContent?.text || 'Header'}
          </h1>

          <h2
            className={`text-white text-2xl mt-2 ${
              isEditing ? 'px-2 hover:shadow-dashed hover:shadow-white' : ''
            }`}
            contentEditable={isEditing} // Enable editing only in editor mode
            suppressContentEditableWarning={true}
            onBlur={(e) =>
              isEditing && handleContentChange('subheader', e.target.innerText)
            } // Handle content change only in edit mode
          >
            {eventData.contents.find((item) => item.type === 'subheader')
              ?.text || 'Subheader'}
          </h2>
        </div>
      </div>

      {/* Show setting icon only when in edit mode */}
      {isEditing && (
        <FaCog
          className="absolute text-xl top-4 right-4 cursor-pointer text-white"
          onClick={() => openEditor(headerContent)}
        />
      )}
    </header>
  );
}

export default HeaderSection;
