import React, { useState } from 'react';
import MiniHeader from '../components/common/MiniHeader';
import Footer from '../components/common/Footer';
import EventNameForm from '../components/form/EventNameForm';
import EventDetailsForm from '../components/form/EventDetailsForm';
import { selectUser } from '../redux/reducers/authReducer';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { apiUrl } from '../utils/const';
import { useLocation } from 'react-router-dom';
import debounce from '../utils/debounce';
import PageContainer from '../components/containers/PageContainer';

function EventCreationPage() {
  const user = useSelector(selectUser);
  const [step, setStep] = useState(1);
  const [detailPage, setDetailPage] = useState(1);
  const [isUrlAvailable, setIsUrlAvailable] = useState(true);
  const [isCheckingUrl, setIsCheckingUrl] = useState(false);
  // Parse the query parameters
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const category = searchParams.get('category');

  const [formValues, setFormValues] = useState({
    eventName: '',
    start_date: '',
    start_time: '',
    end_date: '',
    end_time: '',
    // timezone: '',
    location: '',
    hosting_url: '',
    theme_id: '',
    attending: 0,
    notAttending: 0,
    mayBe: 0,
    status: 'active',
    category: category,
  });

  const nextStep = () => {
    if (step === 1) {
      setStep(2);
    } else if (step === 2 && detailPage < 3) {
      setDetailPage((prevPage) => prevPage + 1);
    } else {
      setStep(step + 1);
      setDetailPage(1);
    }
  };

  const prevStep = () => {
    if (step === 3 || detailPage === 1) {
      setStep(step - 1);
    } else if (step === 2 && detailPage > 1) {
      setDetailPage((prevPage) => prevPage - 1);
    }
  };

  const goToStep = (stepNumber) => {
    if (stepNumber <= step) {
      if (stepNumber === 2) {
        setDetailPage(1);
      }
      setStep(stepNumber);
    }
  };

  const checkUrlAvailability = async (url) => {
    setIsCheckingUrl(true);
    try {
      const response = await axios.get(
        `${apiUrl}/api/events/check-hosting-url`,
        {
          params: { hosting_url: url },
        }
      );
      setIsUrlAvailable(response.data.isAvailable);
    } catch (error) {
      console.error('Error checking URL availability:', error);
      setIsUrlAvailable(false);
    } finally {
      setIsCheckingUrl(false);
    }
  };

  const debouncedCheckUrlAvailability = debounce(checkUrlAvailability, 300);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
    if (name === 'hosting_url') {
      debouncedCheckUrlAvailability(value);
    }
  };

  const handleDateChange = (name, date) => {
    setFormValues({
      ...formValues,
      [name]: date,
    });
  };

  const handleSubmit = async () => {
    try {
      const eventData = {
        ...formValues,
        userId: user.uid,
      };
      const response = await axios.post(`${apiUrl}/api/events`, eventData);
      console.log('Event created successfully:', response.data);

      const eventId = response.data.id;
      console.log('Event ID:', eventId);

      const userUpdateData = { eventId };
      await axios.put(`${apiUrl}/api/users/${user.uid}`, userUpdateData);
      console.log('User updated successfully with new event.');

      // Return success to trigger the notification
      return true;
    } catch (error) {
      console.error('Error creating event:', error);
      // Throw error to show the error notification
      throw error;
    }
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <EventNameForm
            nextStep={nextStep}
            handleChange={handleChange}
            values={formValues}
          />
        );
      case 2:
        return (
          <EventDetailsForm
            nextStep={nextStep}
            prevStep={prevStep}
            handleChange={handleChange}
            handleDateChange={handleDateChange}
            values={formValues}
            currentPage={detailPage}
            isUrlAvailable={isUrlAvailable}
            isCheckingUrl={isCheckingUrl}
            handleSubmit={handleSubmit}
          />
        );
      default:
        return null;
    }
  };

  return (
    <PageContainer>
      <MiniHeader />
      <div className="my-10">
        <div className="flex gap-4 mb-6">
          <p
            onClick={() => goToStep(1)}
            className={`flex-1 text-center ${
              step >= 1 ? 'cursor-pointer' : ''
            } ${step === 1 ? 'text-brandGreen' : 'text-gray-500'}`}
          >
            <span
              className={`rounded-full px-2 py-1 text-white ${
                step === 1 ? 'bg-brandGreen' : 'bg-gray-500'
              }`}
            >
              1
            </span>{' '}
            Event Name
          </p>
          <p
            onClick={() => goToStep(2)}
            className={`flex-1 text-center ${
              step >= 2 ? 'cursor-pointer' : ''
            } ${step >= 2 ? 'text-brandGreen' : 'text-gray-500'}`}
          >
            <span
              className={`rounded-full px-2 py-1 text-white ${
                step >= 2 ? 'bg-brandGreen' : 'bg-gray-500'
              }`}
            >
              2
            </span>{' '}
            Event Details
          </p>
        </div>
        {renderStep()}
      </div>
      <Footer />
    </PageContainer>
  );
}

export default EventCreationPage;
