import { FaCircleInfo } from "react-icons/fa6"
import { cn } from "../../lib/basic"
import { FaCheckCircle } from "react-icons/fa"
import { IoIosWarning } from "react-icons/io"
import { VscError } from "react-icons/vsc";

const Alert = ({ type, description, className }) => {
    return (
        <div className={cn('p-4 flex items-center justify-start gap-2 rounded-[6px]', className)}>
            {type === 'success' ? (
                <FaCheckCircle size="20" />
            ) : 
            type === 'warning' ? (
                <IoIosWarning size="20" />
            ) : 
            type === 'error' ? (
                <VscError size="20" />
            ) : 
            type === 'info' ? (
                <FaCircleInfo size="20" />
            ) : null}

            <p className="text-[#4E4E4E] text-[16px] font-semibold ">{description}</p>
        </div>
    )
} 

export default Alert