import React from 'react';
import { FaWpforms, FaChevronRight } from 'react-icons/fa';
import {
  MdOutlineMailOutline,
  MdEventSeat,
  MdPublishedWithChanges,
} from 'react-icons/md';
import { CiShare2 } from 'react-icons/ci';
import { LuScanLine } from 'react-icons/lu';

const Section = ({ title, items }) => (
  <div>
    <h1 className="text-black text-2xl font-bold">{title}</h1>
    {items.map(({ icon: Icon, heading, description }, index) => (
      <div
        key={index}
        className="bg-yellow-500 cursor-pointer rounded-md my-4 px-8 py-4 flex items-center"
      >
        <Icon className="text-3xl" /> {/* Increase icon size */}
        <div className="flex-1 ml-4">
          {' '}
          {/* Allow heading and description to take available space */}
          <h2 className="text-xl font-semibold">{heading}</h2>{' '}
          {/* Increase font size of h2 */}
          <p>{description}</p>
        </div>
        <FaChevronRight className="text-xl ml-auto" />{' '}
        {/* Move right-side icon to end of container */}
      </div>
    ))}
  </div>
);

function EventChecklist() {
  const sections = [
    {
      title: 'Customize',
      items: [
        {
          icon: FaWpforms,
          heading: 'Form builder',
          description:
            'Customize your registration form, Ask questions, manage capacity limits, calendar, invitation and more.',
        },
        {
          icon: MdOutlineMailOutline,
          heading: 'Confirmation emails and remainders',
          description:
            'Customize automated emails guests and reminders as you want',
        },
      ],
    },
    {
      title: 'Organize',
      items: [
        {
          icon: MdEventSeat,
          heading: 'Email',
          description: 'Inviting guests with email ',
        },
        {
          icon: LuScanLine,
          heading: 'Check-In',
          description: 'Check in guests by name, email, confirmation code.',
        },
      ],
    },
    {
      title: 'Launch',
      items: [
        {
          icon: MdPublishedWithChanges,
          heading: 'Publish',
          description:
            'Make sure that your link is active and start sharing for guests',
        },
        {
          icon: CiShare2,
          heading: 'Invite and share',
          description: 'Customize your emails and reminders as you want',
        },
      ],
    },
  ];

  return (
    <div className="flex flex-col gap-6 text-white">
      {sections.map((section, index) => (
        <Section key={index} title={section.title} items={section.items} />
      ))}
    </div>
  );
}

export default EventChecklist;
