import React from 'react';
import Layout from '../components/common/Layout';
import NewEmail from '../components/DashboardSections/NewEmail';

function NewEmailPage() {
  return (
    <Layout>
      <NewEmail />
    </Layout>
)
}

export default NewEmailPage