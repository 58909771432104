import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { useNavigate, useParams } from "react-router-dom";
import "../common/styles/theme.css";
import {
  IoCodeDownloadSharp,
  IoPencil,
  IoPencilSharp,
  IoTrash,
} from "react-icons/io5";
import { TbTableImport } from "react-icons/tb";
import {
  addInvitee,
  downloadInviteeListTemplate,
  removeInvitee,
  updateInvitee,
  uploadInviteeFile,
} from "../../service/InviteeList.service";
import { fetchInviteeList } from "../../service/InviteeList.service";
import { storeInvitees } from "../../redux/reducers/inviteeReducer";
import { useDispatch, useSelector } from "react-redux";
import { Pen, Plus } from "lucide-react";
import { Button, Form, Input, Space, Spin, Table } from "antd";
import { downloader } from "../../lib/downloader";
import { Error, Success } from "../../utils/alert";
import { cn } from "../../lib/basic";

Modal.setAppElement("#root");

const EventList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [manual, setManual] = useState(false);

  const { event } = useSelector((state) => state.event);
  const { eventId } = useParams();

  const [invitee_file, setInviteeFile] = useState(null);

  // If there is no invitee that user imported, then keep the
  // flow otherwise redirect to invitee list page.
  useEffect(() => {
    const fetchInvitee = async () => {
      try {
        const response = await fetchInviteeList(eventId);
        const { inviteeList } = response?.data;
        return inviteeList;
      } catch (error) {
        console.log("No Invitee List");
      }
    };

    if (!event.eventName) {
      fetchInvitee().then((data) => {
        if (Array.isArray(data) && data.length > 0) {
          dispatch(storeInvitees(data));
          navigate("view");
        }
      });
    } else {
      if (Array.isArray(event.invitees) && event.invitees.length > 0) {
        navigate("view");
      }
    }
  }, [eventId, navigate, dispatch]);

  useEffect(() => {
    const uploadFileAction = async () => {
      const response = await uploadInviteeFile(eventId, invitee_file);

      if (response?.status === 200) {
        // Remove the previous file
        setInviteeFile(null);
        Success({
          title: "Success",
          message: "Your template has successfully been uploaded.",
        });
        navigate("view");
      }
    };

    if (typeof invitee_file === "object" && invitee_file != null) {
      // trigger upload file
      uploadFileAction();
    }
  }, [invitee_file, eventId]);

  const [form] = Form.useForm();
  const [count, setCount] = useState(0);
  const [data, setData] = useState([
    { key: count, title: "", firstName: "", lastName: "", email: "" },
  ]);
  const [loading, setLoading] = useState({
    addRow: false,
    removeRow: false,
  });
  const [updateInviteeLoading, setUpdateInviteeLoading] = useState(false);

  // Columns for the Ant Design table
  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (_, record, index) => (
        <Input
          value={record.name}
          placeholder="Title"
          onChange={(e) => handleInputChange(index, "title", e.target.value)}
        />
      ),
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
      render: (_, record, index) => (
        <Input
          value={record.age}
          placeholder="First Name"
          onChange={(e) =>
            handleInputChange(index, "firstName", e.target.value)
          }
        />
      ),
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
      render: (_, record, index) => (
        <Input
          value={record.age}
          placeholder="Last Name"
          onChange={(e) => handleInputChange(index, "lastName", e.target.value)}
        />
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (_, record, index) => (
        <Input
          value={record.age}
          placeholder="Email"
          onChange={(e) => handleInputChange(index, "email", e.target.value)}
        />
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, __, index) => (
        <Space>
          {index === data.length - 1 && (
            <Button
              type="dashed"
              onClick={() => handleAddRow(index)}
              icon={<Plus />}
              loading={data.length === index && loading.addRow}
              style={{ width: "100%" }}
            >
              {/* Add Guest */}
            </Button>
          )}

          {index !== data.length - 1 && (
            <Button
              type="text"
              onClick={() => handleUpdateRow(index)}
              icon={<IoPencil size={18} />}
              style={{ width: "100%" }}
            >
              {/* Add Guest */}
            </Button>
          )}

          <Button
            type="text"
            danger
            icon={<IoTrash />}
            onClick={() => handleDeleteRow(index)}
          >
            {/* Delete */}
          </Button>
        </Space>
      ),
    },
  ];

  // Function to handle input changes in the table
  const handleInputChange = (index, key, value) => {
    const newData = [...data];
    newData[index][key] = value;
    setData(newData);
  };

  // Handle update row
  const handleUpdateRow = (index) => {
    const formData = data[index];

    if (
      !formData.firstName ||
      !formData.lastName ||
      !formData.email ||
      !formData.title
    )
      return;

    setUpdateInviteeLoading(true);

    updateRow(formData)
      .then(() => {
        Success({
          message: "Successfully updated invitee",
        });
      })
      .catch((err) => {
        Error({
          message: err?.response?.error || "Failed updating the invitee",
        });
      });

    setUpdateInviteeLoading(false);
  };

  // Function to add a new row
  const handleAddRow = (index) => {
    const formData = data[index];

    if (
      !formData.firstName ||
      !formData.lastName ||
      !formData.email ||
      !formData.title
    )
      return;

    setLoading((prev) => ({ ...prev, addRow: true, removeRow: false }));
    saveRow(formData)
      .then(() => {
        setCount((prev) => prev + 1);
        setData([
          ...data,
          { key: count, title: "", firstName: "", lastName: "", email: "" },
        ]);
      })
      .catch((err) => {
        Error({ message: "Something went wrong!" });
      })
      .finally(() => {
        setLoading((prev) => ({ ...prev, addRow: false, removeRow: false }));
      });
  };

  // Function to delete a row
  const handleDeleteRow = (index) => {
    const formData = data[index];

    if (
      !formData.email ||
      !formData.title ||
      !formData.firstName ||
      !formData.lastName
    )
      return;

    setLoading((prev) => ({ ...prev, addRow: false, removeRow: true }));
    deleteRow(formData.email)
      .then(() => {
        setData((prevData) => prevData.filter((_, i) => i !== index));
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading((prev) => ({ ...prev, addRow: false, removeRow: false }));
      });
  };

  useEffect(() => {
    if (data.length === 0) {
      setData([{ key: 0, title: "", firstName: "", lastName: "", email: "" }]);
    }
  }, [data]);

  // Handle request to save the row
  const saveRow = async (formData) => {
    return await addInvitee(eventId, formData);
  };

  // Handle request to delete the row
  const deleteRow = async (email) => {
    return await removeInvitee(eventId, { email });
  };

  // Handle request to update the row
  const updateRow = async (formData) => {
    return await updateInvitee(eventId, formData);
  };

  // Handle download template
  const downloadTemplate = async () => {
    try {
      const response = await downloadInviteeListTemplate(eventId);
      downloader({
        file_name: "InviteeListTemplate.xlsx",
        blob: response.data,
      });
    } catch (error) {
      Error({
        title: "Failed downloading template.",
        message: "Please try again later.",
      });
    }
  };

  return (
    <div>
      <h1 className="text-gray-800 font-bold text-2xl text-center">
        Import list of invitees
      </h1>

      <p className="text-primary text-center">
        Import a .xlsx or .csv. You'll be able to map your imported columns in
        the next step
      </p>

      <div className="flex flex-col md:flex-row items-center justify-center mt-12 flex-wrap gap-4">
        <Card
          Icon={IoCodeDownloadSharp}
          title="Download Spreadsheet Template"
          onClick={downloadTemplate}
          tag={"Recommended"}
        />
        <Card
          Icon={TbTableImport}
          title="Import Spreadsheet"
          onClick={() => {
            // Create a file input element
            const uploadFile = document.createElement("input");

            // Set the input type and accepted file types
            uploadFile.type = "file";
            uploadFile.accept = ".csv, .xlsx"; // Allowed file extensions

            // Add an event listener for when the file is selected
            uploadFile.addEventListener("change", (e) => {
              const file = e.target.files[0]; // Get the selected file
              if (file) {
                setInviteeFile(file); // Pass the file to the handler
              }
            });

            // Append to the body temporarily to trigger the dialog
            document.body.appendChild(uploadFile);

            // Trigger the file selection dialog
            uploadFile.click();

            // Remove the input element from the DOM after use
            document.body.removeChild(uploadFile);
          }}
        />
        <Card
          Icon={IoPencil}
          title="Manually Add Invitee"
          onClick={() => {
            setManual(true);
          }}
          tag={"Not recommended"}
        />
      </div>

      {!manual ? (
        <Description />
      ) : (
        <div className="mt-8">
          <Form form={form}>
            <Spin spinning={loading.addRow || loading.removeRow}>
              <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                bordered
                style={{ marginBottom: 16 }}
                loading={
                  loading.addRow || loading.removeRow || updateInviteeLoading
                }
              />
            </Spin>
          </Form>
        </div>
      )}
    </div>
  );
};

const Card = ({ Icon, tag, title, onClick }) => {
  return (
    <div
      onClick={onClick}
      className="flex flex-col items-center w-full h-40 md:w-80 p-4 rounded-md shadow ring-2 ring-transparent bg-white text-gray-800 hover:ring-purple-light hover:ring-brandGreen cursor-pointer transition duration-150 ease-in-out"
    >
      <Icon size={48} color={"#4B5563"} />

      <h3 className="mt-4 font-bold ">{title}</h3>

      {tag && (
        <p
          className={cn(
            "mt-2 text-xs text-gray-600",
            tag.toLowerCase() === "recommended" &&
              " bg-brandGreen rounded-md px-2 py-1 text-white"
          )}
        >
          {tag}
        </p>
      )}
    </div>
  );
};

const Description = () => {
  return (
    <>
      <p class="text-lg text-center text-gray-800 mt-10 mb-4">
        Import spreadsheet column headers requirements:
      </p>

      <table class="table-auto bg-white mx-auto">
        <thead class="bg-teal-light">
          <tr>
            <th class="border p-2 w-[260px]">Column Title</th>
            <th class="border p-2 w-[260px]">Requirements</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="text-center border p-2 uppercase">
              <div class="flex items-center justify-center">Title</div>
            </td>
            <td class="text-center border p-2 text-sm">Optional</td>
          </tr>
          <tr>
            <td class="text-center border p-2 uppercase">First name</td>
            <td class="text-center border p-2 text-sm">
              Required if no email included
            </td>
          </tr>
          <tr>
            <td class="text-center border p-2 uppercase">Last Name</td>
            <td class="text-center border p-2 text-sm">
              Required if no email included
            </td>
          </tr>
          <tr>
            <td class="text-center border p-2 uppercase">Email</td>
            <td class="text-center border p-2 text-sm">
              Required if no first and last name included
            </td>
          </tr>
        </tbody>
      </table>

      <div class="flex flex-col items-center justify-center w-full max-w-xl mx-auto my-12">
        <p class="text-center font-bold text-xl uppercase text-gray-700">
          Tips
        </p>
        <ul class="list-disc mt-4 text-md text-gray-700 mx-10">
          <li>
            Each invitee must have a FIRST NAME, and LAST NAME, and/or EMAIL
            ADDRESS
          </li>
          <li>
            Be sure your spreadsheet includes column headers in the first row.
          </li>
        </ul>
      </div>
    </>
  );
};
export default EventList;
