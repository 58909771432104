import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { useParams } from "react-router-dom";
import { FaCheckCircle } from "react-icons/fa"; // Spinner icon for loading
import ReactPaginate from "react-paginate"; // For pagination
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import "../components/common/styles/theme.css";
import { cn, formatStandardDate } from "../lib/basic";
import { attending_icon, not_attending_icon } from "../assets";
import CustomButton from "../components/Button/Buttons";
import { FcExport } from "react-icons/fc";
import Alert from "../components/Alert/Alert";
import Layout from "../components/common/Layout";
import { fetchEvent } from "../service/Event.service";
import {
  exportGuestResponses,
  fetchResponsesFromGuests,
} from "../service/Guest.service";
import { ColorRingOptions } from "../components/DashboardSections/PreviewInvitee";
import { ColorRing } from "react-loader-spinner";
import { downloader } from "../lib/downloader";
import { useSelector } from "react-redux";
import { IoClose } from "react-icons/io5";

Modal.setAppElement("#root");

const Reporting = () => {
  const { eventId } = useParams();
  const { event } = useSelector((state) => state.event);

  const [eventInfo, setEventInfo] = useState(null);
  const [responses, setResponses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [export_loading, setExportLoading] = useState(false);

  const [pageNumber, setPageNumber] = useState(0);
  const [attending, setAttending] = useState(0);
  const [number_of_guests, setNumberOfGuests] = useState(0);

  const responsesPerPage = 10;
  const pagesVisited = pageNumber * responsesPerPage;

  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        const response = await fetchEvent(eventId);
        setEventInfo(response.data);
      } catch (error) {
        console.error("Error fetching event details:", error);
      } finally {
        setLoading(false);
      }
    };

    if (!!event) {
      setEventInfo(event);
    } else {
      fetchEventDetails();
    }
  }, [eventId]);

  useEffect(() => {
    const fetchGuestResponses = async () => {
      setLoading(true);
      try {
        const response = await fetchResponsesFromGuests(eventId);
        const { guestInfo, numberOfGuests } = response?.data;
        setResponses(guestInfo);
        setNumberOfGuests(numberOfGuests);
        // Calculate attending amount
        const attending_number = guestInfo.filter(
          (guest) => guest.response === "attending"
        ).length;

        setAttending(attending_number);
      } catch (error) {
        console.error("Error fetching guest responses:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchGuestResponses();
  }, [eventId]);

  // Export list as csv
  const handleExport = async () => {
    setExportLoading(true);
    try {
      const response = await exportGuestResponses(eventId);
      downloader({ file_name: "Guest-Responses.csv", blob: response.data });
    } catch (error) {
      Error({
        title: "Something went wrong!",
        message:
          error?.response?.data?.error ||
          "Failed to export your guest responses",
      });
    }
    setExportLoading(false);
  };

  const pageCount = Math.ceil(responses.length / responsesPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <Layout>
      <div>
        {/* Event Summary */}
        <div className="flex space-x-8 mb-8">
          <AttendanceCard
            is_attending={true}
            number_of_attendee={eventInfo ? attending : "N/A"}
          />
          <AttendanceCard
            is_attending={false}
            number_of_attendee={
              eventInfo ? number_of_guests - attending : "N/A"
            }
          />
        </div>

        <hr />

        <div className="bg-white shadow-md rounded-lg p-6">
          {/* <CustomButton label='Send Invitation' onClick={() => setIsModalOpen(true)} variant={!isModalOpen ? "primary" : "disabled"}/> */}

          <div className="flex justify-between items-center mb-4 mt-2">
            <input
              type="text"
              placeholder="Search..."
              className="p-2 border border-gray-200 focus:border-gray-400 outline-none rounded-lg"
            />

            <div className="space-x-2 flex items-center">
              <CustomButton
                Icon={export_loading ? ColorRing : FcExport}
                label={!export_loading ? "Export" : "Exporting ..."}
                variant={"outlined"}
                disabled={export_loading}
                onClick={handleExport}
                iconProps={
                  export_loading
                    ? {
                        ...ColorRingOptions,
                        height: "25",
                        width: "25",
                      }
                    : null
                }
              />
              {/* <CustomButton
                Icon={TbTableImport}
                label={"Import"}
                variant={"outlined"}
              /> */}
            </div>
          </div>

          {/* Event List */}
          {loading ? (
            <div className="flex items-center justify-center">
              <ColorRing {...ColorRingOptions} />
            </div>
          ) : (
            <table className="w-full text-left">
              <thead>
                <tr>
                  <th
                    className={cn(
                      Style.table.th,
                      responses.length === 0 && "border-transparent"
                    )}
                    align="start"
                  >
                    Title
                  </th>
                  <th
                    className={cn(
                      Style.table.th,
                      responses.length === 0 && "border-transparent"
                    )}
                  >
                    First Name
                  </th>
                  <th
                    className={cn(
                      Style.table.th,
                      responses.length === 0 && "border-transparent"
                    )}
                  >
                    Last Name
                  </th>
                  <th
                    className={cn(
                      Style.table.th,
                      responses.length === 0 && "border-transparent"
                    )}
                  >
                    Email
                  </th>
                  <th
                    className={cn(
                      Style.table.th,
                      responses.length === 0 && "border-transparent"
                    )}
                  >
                    Phone Number
                  </th>
                  <th
                    className={cn(
                      Style.table.th,
                      responses.length === 0 && "border-transparent"
                    )}
                    align="center"
                  >
                    Responded at
                  </th>
                  <th
                    className={cn(
                      Style.table.th,
                      responses.length === 0 && "border-transparent"
                    )}
                    align="center"
                  >
                    Attending
                  </th>
                </tr>
              </thead>
              <tbody>
                {responses
                  .slice(pagesVisited, pagesVisited + responsesPerPage)
                  .map((guest, guestIndex) => {
                    const rowId = `guest-${guestIndex}`;
                    return (
                      <React.Fragment key={rowId}>
                        <tr
                          className={cn(
                            "hover:bg-neutral-100",
                            guestIndex % 2 === 0 && "bg-neutral-50"
                          )}
                        >
                          <td className={Style.table.td} align="start">
                            {guest.title}
                          </td>
                          <td className={Style.table.td}>{guest.firstName}</td>
                          <td className={Style.table.td}>{guest.lastName}</td>
                          <td className={Style.table.td}>{guest.email}</td>
                          <td className={Style.table.td}>
                            {guest.phoneNumber}
                          </td>
                          <td className={Style.table.td} align="center">
                            {formatStandardDate(guest.responded_at)}
                          </td>
                          <td className={Style.table.td} align="center">
                            {guest.response === "attending" ? (
                              <FaCheckCircle color="#55ca09" />
                            ) : (
                              <IoClose color="#ff0049" />
                            )}
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })}
              </tbody>
            </table>
          )}

          {/* Pagination */}
          <div
            className={cn(
              "flex justify-center space-x-2 mt-12",
              responses.length === 0 && "hidden"
            )}
          >
            <ReactPaginate
              previousLabel={<FaChevronLeft />}
              nextLabel={<FaChevronRight />}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={"pagination"}
              previousLinkClassName={"previous"}
              nextLinkClassName={"next"}
              disabledClassName={"disabled"}
              activeClassName={"active"}
            />
          </div>
        </div>

        {responses?.length === 0 && (
          <Alert
            type="info"
            description="You haven’t received any registrations."
            className="mt-4 bg-[#FFB444]"
          />
        )}
      </div>
    </Layout>
  );
};

const IconContainer = ({ is_attending }) => {
  return (
    <div
      className={cn(
        "w-11 h-11 flex items-center justify-center rounded-full",
        is_attending ? "bg-[#E6F6F1]" : "bg-[#FCECED]"
      )}
    >
      {is_attending ? (
        <img src={attending_icon} />
      ) : (
        <img src={not_attending_icon} />
      )}
    </div>
  );
};

const AttendanceCard = ({ is_attending, number_of_attendee }) => {
  return (
    <div
      className={cn(
        "flex flex-col bg-white items-center justify-center w-[244px] h-[160px] py-6 shadow-[0px_1px_3px_#0000001A]"
      )}
    >
      <IconContainer is_attending={is_attending} />
      <p className="font-bold text-[30px] text-[#4b5563]">
        {number_of_attendee}
      </p>
      <p className="text-[14px] text-[#4b5563]">
        {is_attending ? "Attending" : "Not Attending"}
      </p>
    </div>
  );
};

const Style = {
  table: {
    th: "border-b border-neutral-300 py-2 font-semibold text-[14px] text-[#4B5563]",
    td: "border-b py-2 text-sm text-neutral-600 border-neutral-200",
  },
};
export default Reporting;
