import React from "react";
import Free from "../../assets/free.png";

export default function FreePricing() {
  return (
    <div className="h-96  flex items-center justify-center bg-[#F5F2F0] m-8 rounded-2xl">
      <div className="max-w-2xl w-full px-4 py-8 text-center">
        <div className="flex flex-col md:flex-row items-center justify-center gap-8 md:gap-12">
          <div className="relative w-48 h-48">
            {/* Illustration container */}
            <div className="absolute inset-0">
              <img
                src={Free}
                alt="Free Pricing"
                className="object-cover w-full h-full"
              />
            </div>
          </div>

          <div className="space-y-4">
            <h1 className="text-[#2D2F45] text-4xl font-bold">
              Available at no cost in this version
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
}
