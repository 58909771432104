import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { FaPlus, FaTrash } from 'react-icons/fa';
import { message } from 'antd';
import { ClipLoader } from 'react-spinners';
import { apiUrl } from '../../utils/const';

function FormBuilder() {
  const { eventId } = useParams();
  const [foodPreferences, setFoodPreferences] = useState([]);
  const [newPreference, setNewPreference] = useState('');
  const [WallId, setWallId] = useState('');
  const [loading, setLoading] = useState(false);

  // Fetch event to get wallDesignId
  useEffect(() => {
    const fetchEvent = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${apiUrl}/api/events/${eventId}`);
        setWallId(response.data.wallDesignId);
      } catch (error) {
        message.error('Failed to fetch event details.');
      } finally {
        setLoading(false);
      }
    };

    fetchEvent();
  }, [eventId]);

  // Fetch food preferences from wall design
  useEffect(() => {
    const fetchPreferences = async () => {
      if (WallId) {
        setLoading(true);
        try {
          const response = await axios.get(`${apiUrl}/api/wall/${WallId}`);
          setFoodPreferences(response.data.food_preference || []);
        } catch (error) {
          message.error('Failed to fetch food preferences.');
        } finally {
          setLoading(false);
        }
      }
    };

    fetchPreferences();
  }, [WallId]);

  // Add new preference and update in database
  const handleAddPreference = async () => {
    if (newPreference.trim()) {
      const updatedPreferences = [
        ...foodPreferences,
        { id: Date.now().toString(), name: newPreference },
      ];

      setLoading(true);
      try {
        await axios.put(`${apiUrl}/api/wall/${WallId}`, {
          food_preference: updatedPreferences,
        });
        setFoodPreferences(updatedPreferences);
        setNewPreference('');
        message.success('Preference added successfully.');
      } catch (error) {
        message.error('Failed to add preference.');
      } finally {
        setLoading(false);
      }
    }
  };

  // Remove preference and update in database
  const handleRemovePreference = async (id) => {
    const updatedPreferences = foodPreferences.filter((pref) => pref.id !== id);

    setLoading(true);
    try {
      await axios.put(`${apiUrl}/api/wall/${WallId}`, {
        food_preference: updatedPreferences,
      });
      setFoodPreferences(updatedPreferences);
      message.success('Preference removed successfully.');
    } catch (error) {
      message.error('Failed to remove preference.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="relative p-4 w-3/4 border rounded-md">
      <h2 className="text-xl font-semibold mb-4 text-center">
        Food Preferences
      </h2>
      {/* Display Food Preferences */}
      <ul className="mb-4">
        {foodPreferences.map((preference) => (
          <li
            key={preference.id}
            className="flex justify-between items-center mb-2 p-2 bg-gray-100 rounded-md"
          >
            {preference.name}
            <FaTrash
              className="cursor-pointer text-red-500 hover:text-red-700"
              onClick={() => handleRemovePreference(preference.id)}
            />
          </li>
        ))}
      </ul>
      <div className="flex items-center gap-2">
        <input
          type="text"
          value={newPreference}
          onChange={(e) => setNewPreference(e.target.value)}
          placeholder="Add new preference"
          className="p-2 border rounded-md flex-grow"
        />
        <button
          className="p-2 bg-brandGreen text-white rounded-md hover:bg-green-700"
          onClick={handleAddPreference}
        >
          <FaPlus />
        </button>
      </div>
      {/* Loading Spinner */}
      {loading && (
        <div className="w-full my-6 flex justify-center">
          <ClipLoader size={30} color="#3498db" />
        </div>
      )}
    </section>
  );
}

export default FormBuilder;
