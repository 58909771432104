import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom"

const Unauthorized = () => {
    const navigate = useNavigate();
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
        <FontAwesomeIcon icon={faExclamationTriangle} className="text-red-500 text-6xl mb-4" />
        <h1 className="text-4xl font-bold mb-2">Forbidden Access</h1>
        <p className="text-lg text-gray-700 mb-8">Sorry, you are not authorized to visit this page.</p>
        <Link onClick={() => navigate(-1)} className="text-blue-500 hover:underline">Go back</Link>
  </div>
  )
}

export default Unauthorized