import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { FaSpinner } from 'react-icons/fa';
import { IoIosArrowBack } from 'react-icons/io';
import { MdNavigateNext } from 'react-icons/md';
import ReactPaginate from 'react-paginate';
import { apiUrl } from '../../utils/const';
import '../common/styles/theme.css';
import NotFound from '../Empty/NotFound';
import { empty_email_img } from '../../assets';
import { cn } from '../../lib/basic';

const SentEmail = () => {
  const [sentEmails, setSentEmails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const { eventId } = useParams();
  const navigate = useNavigate();
  const emailsPerPage = 10;
  const pageCount = Math.ceil(sentEmails.length / emailsPerPage);

  // Fetch sent emails from backend API
  useEffect(() => {
    const fetchSentEmails = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/invitations`, {
          params: { eventId },
        });
        setSentEmails(response.data);
      } catch (error) {
        console.error('Error fetching sent emails:', error);
      } finally {
        setLoading(false); // Stop loading after fetch is complete
      }
    };

    fetchSentEmails();
  }, [eventId]);

  const handleNewEmailClick = () => {
    navigate(`/dashboard/events/${eventId}/new-email`);
  };

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };
  return (
    <div>
      <div className="p-4 py-0">
        <div className="flex gap-4 items-center mb-8">
          <input
            type="text"
            placeholder="Search"
            className="border p-2 border-brandGreen rounded w-1/3"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button
            className="bg-yellow-500 text-white px-4 py-2 rounded"
            onClick={handleNewEmailClick}
          >
            New Email
          </button>
        </div>
        {loading ? (
          <FaSpinner className="animate-spin w-full mx-auto text-4xl text-gray-500" />
        ) : sentEmails.length === 0 ? (
          <NotFound message={"You haven't sent any emails yet."} img={{ src: empty_email_img, alt: "No email sent.", imgClassName: "mt-10 md:mt-20" }} />
        ) : (
          <table className="min-w-full bg-white border">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b">Email</th>
                <th className="py-2 px-4 border-b">Sent Date</th>
                <th className="py-2 px-4 border-b">Status</th>
                <th className="py-2 px-4 border-b">Reply-To</th>
                <th className="py-2 px-4 border-b">Subject</th>
              </tr>
            </thead>
            <tbody>
              {sentEmails.map((email) => (
                <tr key={email.id}>
                  <td className="py-2 px-4 border-b">{email.guestEmails[0]}</td>
                  <td className="py-2 px-4 border-b">
                    {new Date(email.sent_at).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                    })}
                  </td>
                  <td className="py-2 px-4 border-b">{email.status}</td>
                  <td className="py-2 px-4 border-b">{email.reply_To}</td>
                  <td className="py-2 px-4 border-b">{email.subject}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      <div className="flex justify-center mt-20 mb-4">
        <ReactPaginate
          previousLabel={<IoIosArrowBack />}
          nextLabel={<MdNavigateNext />}
          pageCount={pageCount}
          onPageChange={handlePageClick}
          containerClassName={'pagination'}
          activeClassName={'active'}
          pageClassName={'page-item'}
          pageLinkClassName={'page-link'}
          previousClassName={cn('previous ', (sentEmails.length === 0) && "disabled")}
          previousLinkClassName={'previous-link'}
          nextClassName={cn('next ', (sentEmails.length === 0) && "disabled")}
          nextLinkClassName={'next-link'}
          breakClassName={'page-item'}
          breakLinkClassName={'page-link'}
          disabledClassName={'disabled'}
        />
      </div>
    </div>
  );
};

export default SentEmail;
