import React, { useState, useEffect } from "react";
import axios from "axios";
import EventCard from "../components/card/EventCard";
import { IoIosArrowBack } from "react-icons/io";
import { MdNavigateNext } from "react-icons/md";
import ReactPaginate from "react-paginate";
import Footer from "../components/common/Footer";
import MiniHeader from "../components/common/MiniHeader";
import { Link } from "react-router-dom";
import { apiUrl } from "../utils/const";
import { selectUser } from "../redux/reducers/authReducer";
import { useSelector } from "react-redux";
import babyShower from "../assets/babyshower.jpg";
import brideShower from "../assets/birthday.jpg";
import wedding from "../assets/wedding.jpg";
import birthday from "../assets/birthd.jpg";
import church from "../assets/church.jpg";
import corporate from "../assets/birthday.jpg";
import "../components/common/styles/theme.css";
import { Breathing } from "react-shimmer";
import NotFound from "../components/Empty/NotFound";
import { cn } from "../lib/basic";
import { default_error_img, no_data_img } from "../assets";

const MyEventsList = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [events, setEvents] = useState([]);
  const [filter, setFilter] = useState("active");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const user = useSelector(selectUser);

  // Mapping categories to their corresponding thumbnails
  const categoryThumbnails = {
    babyShower,
    brideShower,
    wedding,
    birthday,
    church,
    corporate,
  };

  useEffect(() => {
    fetchUserAndEvents();
  }, []);

  const fetchUserAndEvents = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const userResponse = await axios.get(`${apiUrl}/api/users/${user.uid}`);
      const userEvents = userResponse.data.events;

      const eventsWithTemplates = await Promise.all(
        userEvents.map(async (eventId) => {
          const eventResponse = await axios.get(
            `${apiUrl}/api/events/${eventId}`
          );
          const event = eventResponse.data;
          return event;
        })
      );

      setEvents(eventsWithTemplates);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Failed to load data. Please try again later.");
      setIsLoading(false);
    }
  };

  const filteredEvents = events.filter((event) => event.status === filter);

  const eventsPerPage = 10;
  const pageCount = Math.ceil(filteredEvents.length / eventsPerPage);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const offset = currentPage * eventsPerPage;
  const currentEvents = filteredEvents.slice(offset, offset + eventsPerPage);
  const handleDeleteEvent = (deletedEventId) => {
    setEvents((prevEvents) =>
      prevEvents.filter((event) => event.id !== deletedEventId)
    );
  };
  return (
    <div className="px-6 lg-px-10">
      <MiniHeader dashboard={true} />
      <div className="p-4">
        <div className="flex gap-4 items-center mb-4">
          <input
            type="text"
            placeholder="Search"
            className="border p-2 border-brandGreen rounded w-1/3"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Link to={"/rsvp"}>
            <button className="bg-yellow-500 text-white px-4 py-2 rounded">
              New Event
            </button>
          </Link>
        </div>
        <div className="flex gap-4 mb-4">
          <button
            className={`px-4 py-2 rounded text-brandGreen ${
              filter === "active"
                ? "text-yellow-500 border-b-2 border-yellow-500"
                : ""
            }`}
            onClick={() => setFilter("active")}
          >
            Current
          </button>
          <button
            className={`px-4 py-2 rounded text-brandGreen ${
              filter === "past"
                ? "text-yellow-500 border-b-2 border-yellow-500"
                : ""
            }`}
            onClick={() => setFilter("past")}
          >
            Past Events
          </button>
        </div>
        {isLoading ? (
          [0].map((i) => (
            <Breathing
              width={400}
              height={350}
              className="rounded-3xl"
              key={i}
            />
          ))
        ) : error ? (
          <NotFound
            message={error}
            img={{ alt: "Error", src: default_error_img }}
          />
        ) : (
          <div>
            {currentEvents.length === 0 ? (
              <NotFound
                img={{ alt: "Not event found", src: no_data_img }}
                message={
                  filter === "active"
                    ? "No active event found."
                    : "No past event found."
                }
              />
            ) : (
              <div className="grid grid-cols-3 gap-x-4 gap-y-12">
                {currentEvents.map((event, index) => (
                  <EventCard
                    key={index}
                    eventId={event.id}
                    imagePath={event.template?.coverPhoto}
                    attending={event.attending}
                    maybe={event.mayBe}
                    notAttending={event.notAttending}
                  />
                ))}
              </div>
            )}
          </div>
        )}
        <div className="flex justify-center mt-20 mb-4">
          <ReactPaginate
            previousLabel={<IoIosArrowBack />}
            nextLabel={<MdNavigateNext />}
            pageCount={pageCount}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            activeClassName={"active"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={cn(
              "previous",
              (error || filteredEvents.length === 0) && "disabled"
            )}
            previousLinkClassName={"previous-link"}
            nextClassName={cn(
              "next",
              (error || filteredEvents.length === 0) && "disabled"
            )}
            nextLinkClassName={"next-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            disabledClassName={"disabled"}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MyEventsList;
