import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  invitee_list: [],
};

const inviteeSlice = createSlice({
  name: "invitee",
  initialState,
  reducers: {
    storeInvitees: (state, action) => {
      state.invitee_list = action.payload;
    },

    removeInvitee: (state, action) => {
      state.invitee_list = [];
    },
  },
});

export const selectFromInvitee = (state) => state.invitee;
export const { storeInvitees, removeInvitee } = inviteeSlice.actions;
export default inviteeSlice.reducer;
