import React from 'react';
import Footer from './Footer';
import Sidebar from './Sidebar';
import MiniHeader from './MiniHeader';
import { useParams } from 'react-router-dom';

const Layout = ({ children }) => {
  const { eventId } = useParams();
  return (
    <div className="flex flex-col bg-[#e5e7eb71] min-h-screen">
      <MiniHeader dashboard={false}/>
      <div className="flex flex-1">
        <Sidebar eventId={eventId}/>
        <div className="flex-1 p-8 ml-[20rem]">
          {children}
        </div>
      </div>
      <div className='ml-[20rem]'>
      <Footer/>
      </div>
    </div>
  );
};

export default Layout;
