import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectIsLoggedIn, selectUser } from "../redux/reducers/authReducer";
import { useNavigate } from "react-router-dom";
import { Breathing } from "react-shimmer";
import Header from "../components/common/Header";
import introPhoto from "../assets/images/homeIntro.png";
import Footer from "../components/common/Footer";
import Service1 from "../assets/images/home/service1.png";
import Service2 from "../assets/images/home/service2.png";
import Venue1 from "../assets/images/home/venue1.png";
import Venue2 from "../assets/images/home/vanue2.png";
import Rectangle15 from "../assets/images/home/Rectangle 15.png";
import Rectangle19 from "../assets/images/home/Rectangle 19.png";
import Rectangle20 from "../assets/images/home/Rectangle 20.png";
import Rectangle21 from "../assets/images/home/Rectangle 21.png";
import Rectangle22 from "../assets/images/home/Rectangle 22.png";
import Rectangle23 from "../assets/images/home/Rectangle 23.png";
import SeniorDocGuiding from "../assets/images/home/Senior-Doc-Guiding-you-to-better-health 2.png";
import TruffleShuffle from "../assets/images/home/Truffle Shuffle.png";
import TruffleShuffleLogo from "../assets/images/home/Truffle Shuffle logo.png";
import Vector from "../assets/images/home/Vector.png";
import Vector1 from "../assets/images/home/Vector (1).png";
import Vector2 from "../assets/images/home/Vector (2).png";
import Vector3 from "../assets/images/home/Vector (3).png";
import HomeCard from "../components/card/HomeCard";
import EventGrid from "../components/card/Schedule"; // Assuming the component is in EventGrid.js

import { motion } from "framer-motion";
import PageContainer from "../components/containers/PageContainer";
import { fetchPartners } from "../service/partner";
// FRAMER MOTION VARIANTS
const variants = {
  hidden: {
    opacity: 0,
    x: 400,
    y: 400,
  },
  visible: {
    opacity: 1,
    x: 0,
    y: 0,
    transition: {
      // delay: 0.2,
      duration: 1.8,
      type: "spring",
      stiffness: 20,
    },
  },
};

// exmaple testimonial data
function HomePage() {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const [showAll, setShowAll] = useState(false);

  const user = useSelector(selectUser);
  const [partners, setPartners] = useState([]);
  const [loading, setLoading] = useState(true);
  const visiblePartners = showAll ? partners : partners.slice(0, 6); // Show all or limit to 4

  const navigate = useNavigate();
  const testimonials = [
    {
      title: "Venue",
      location: "Hilton Addis Ababa",
      address: "Addis Ababa, Ethiopia",
    },
    {
      title: "Venue",
      location: "Skylight Hotel",
      address: "Addis Ababa, Ethiopia",
    },
  ];

  // Example event data
  const events = [
    {
      id: 1,
      image: Rectangle15,
      date: "July 15, 2024",
      title: "Summer Festival",
      description: "Join us for a day of music, food, and fun!",
    },
    {
      id: 2,
      image: Rectangle19,
      date: "August 5, 2024",
      title: "Tech Conference",
      description: "Explore the latest in technology and innovation.",
    },
    {
      id: 3,
      image: Rectangle20,
      date: "July 15, 2024",
      title: "Summer Festival",
      description: "Join us for a day of music, food, and fun!",
    },
    {
      id: 4,
      image: Rectangle21,
      date: "August 5, 2024",
      title: "Tech Conference",
      description: "Explore the latest in technology and innovation.",
    },
    {
      id: 5,
      image: Rectangle22,
      date: "July 15, 2024",
      title: "Summer Festival",
      description: "Join us for a day of music, food, and fun!",
    },
    {
      id: 6,
      image: Rectangle23,
      date: "August 5, 2024",
      title: "Tech Conference",
      description: "Explore the latest in technology and innovation.",
    },
    // Add more events as needed
  ];
  useEffect(() => {
    const loadPartners = async () => {
      try {
        setLoading(true); // Start loading
        const data = await fetchPartners();
        setPartners(data.partners);
      } catch (error) {
        console.error("Failed to load partners:", error);
        setLoading(true);
      } finally {
        setLoading(false);
      }
    };

    loadPartners();
  }, []);
  // Toggle function for the button
  const handleToggleShowAll = () => {
    setShowAll((prev) => !prev);
  };
  return (
    <PageContainer>
      <Header />
      <img
        src={introPhoto}
        alt="party image"
        className="lg:my-10 w-full h-auto max-h-[calc(100vh-2rem)] sm:max-h-[calc(100vh-6rem)] md:max-h-[calc(100vh-8rem)] lg:max-h-[calc(100vh-10rem)] xl:max-h-[calc(100vh-12rem)] 2xl:max-h-[calc(100vh-12rem)] object-cover rounded-xl opacity-100"
      />

      <div className="mb-20 mt-32 hidden md:flex max-w-3xl mx-auto h-[350px] gap-16 ">
        <motion.div
          initial={"hidden"}
          variants={{ ...variants, hidden: { ...variants.hidden, x: -400 } }}
          whileInView={"visible"}
          viewport={{
            once: true,
          }}
          className="mt-40 w-1/2 hidden md:flex items-center justify-end"
        >
          <p className="text-5xl leading-[76px] font-bold">
            <span className="text-yellow-500">Customized </span>Event Planning
            and Organizing Platform
          </p>
        </motion.div>

        <motion.div
          initial={"hidden"}
          variants={variants}
          whileInView={"visible"}
          viewport={{
            once: true,
          }}
          className=" self-start w-1/2 md:block hidden"
        >
          <div className="relative w-full">
            <div className="flex flex-col absolute top-0 left-0 w-fit">
              <img
                src={Service1}
                className="lg:max-w-[350px] md:w-[240px] h-[350px]"
              />
              <strong className="shadow-md self-end font-bold rounded-md z-50 bg-white py-[20px] px-[50px] text-[40px] border-[3px] border-[#00623F] text-black">
                300+
              </strong>
            </div>

            <div className="absolute left-1/4 pt-40">
              <img
                src={Service2}
                className="lg:w-[350px] md:w-[240px] h-[350px]"
              />
            </div>
          </div>
        </motion.div>
      </div>

      <div className="mt-60 mb-32 max-w-3xl mx-auto gap-16 hidden md:flex justify-between items-center">
        <motion.div
          variants={{ ...variants, hidden: { ...variants.hidden, x: -400 } }}
          initial={"hidden"}
          whileInView={"visible"}
          viewport={{
            once: true,
          }}
          className="relative hidden md:block"
        >
          <h2 className="w-[10rem] text-4xl leading-relaxed font-bold mx-8">
            Explore Beautiful Venues
          </h2>
          <img src={Venue1} alt="venue1" className="h-[28rem] rounded-md" />
          <div className="flex items-center justify-center absolute bottom-6 -right-24 z-50  py-2 w-60 px-4 rounded-xl bg-white shadow-[0_4px_40px_0_#00623F] max-w-[300px] max-h-[245px]">
            <HomeCard testimonial={testimonials[0]} />
          </div>
        </motion.div>

        <motion.div
          variants={variants}
          initial={"hidden"}
          whileInView={"visible"}
          viewport={{
            once: true,
          }}
          className="relative"
        >
          <div className="flex items-center justify-center absolute top-4 -left-24 z-50 py-2  px-4 rounded-xl bg-white shadow-[0_4px_40px_0_#00623F] w-fit ">
            <HomeCard testimonial={testimonials[1]} />
          </div>
          <img
            src={Venue2}
            alt="venue2"
            className="h-[28rem] rounded-md top-10"
          />
        </motion.div>
      </div>

      <EventGrid events={events} />
      <div className="my-20 flex flex-col items-center gap-8">
        <h1 className="text-3xl font-bold">CLIENT PARTNER</h1>
        {loading ? (
          <div className="grid grid-cols-1 md:grid-cols-4 gap-16 mb-20">
            {[0, 1, 2, 3].map((i) => (
              <Breathing
                width={200}
                height={200}
                className="rounded-3xl"
                key={i}
              />
            ))}
          </div>
        ) : (
          <div className="grid grid-cols-1 rounded md:grid-cols-4 gap-16 mb-20">
            {visiblePartners.slice(0, 4).map((partner, index) => (
              <motion.div
                initial={"hidden"}
                whileInView={"visible"}
                viewport={{ once: true }}
                variants={{
                  hidden: { opacity: 0, x: -40, y: -40 },
                  visible: {
                    opacity: 1,
                    x: 0,
                    y: 0,
                    transition: {
                      delay: index * 0.2,
                      duration: 0.5,
                    },
                  },
                }}
                key={partner.id}
                className="max-h-[12rem] flex flex-col items-center justify-center p-4 shadow-lg rounded-lg"
              >
                <div className="relative group">
                  <img
                    src={partner.partnerLogo[0]}
                    alt={partner.partnerName}
                    className="transform rounded scale-110 group-hover:scale-100 transition-transform duration-500 ease-in-out max-w-[10rem] max-h-[6rem] object-contain"
                  />
                </div>
                <p className="mt-2 text-center font-semibold text-lg text-gray-800 hover:text-brandGreen transition-colors duration-300">
                  {partner.partnerName}
                </p>
              </motion.div>
            ))}
          </div>
        )}
        {!loading && (
          <button
            className="px-8 py-3 bg-brandGreen text-white font-bold rounded-md cursor-pointer"
            onClick={handleToggleShowAll}
          >
            {showAll ? "Show Less" : "All Client Partners"}
          </button>
        )}
      </div>

      <Footer />
    </PageContainer>
  );
}

export default HomePage;
