import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCvPmNLtBOqHEhF6PtxilE4TvqirzpDc_w",
  authDomain: "ohh-company.firebaseapp.com",
  projectId: "ohh-company",
  storageBucket: "ohh-company.firebasestorage.app",
  messagingSenderId: "756872172669",
  appId: "1:756872172669:web:508c93934c4c895ccb4d14",
  measurementId: "G-TMXPZK6GTF",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth };
