import { LuCalendarDays } from "react-icons/lu";
import { cn } from "../../lib/basic";
import DatePicker from "react-datepicker";
import CustomButton from "../Button/Buttons";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { apiUrl } from "../../utils/const";
import { useDispatch, useSelector } from "react-redux";
import { storeEvent } from "../../redux/reducers/eventReducer";

const EventSetting = () => {
  const { eventId } = useParams();
  const [eventInfo, setEventInfo] = useState(null);
  const dispatch = useDispatch();
  const { event } = useSelector((state) => state.event);

  const [data, setData] = useState({
    eventName: "",
    start_date: "",
    start_time: "",
    end_date: "",
    end_time: "",
  });

  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/events/${eventId}`);
        setEventInfo(response.data);
        dispatch(storeEvent(response.data));
      } catch (error) {
        console.error("Error fetching event details:", error);
      }
    };

    if (!event.eventName) {
      fetchEventDetails();
    } else {
      setEventInfo(event);
    }
  }, [eventId]);

  useEffect(() => {
    if (!!eventInfo) {
      setData({
        start_date: event.start_date,
        start_time: event.start_time,
        end_date: event.end_date,
        end_time: event.end_time,
      });
    }
  }, [eventInfo]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div>
      <div className="bg-white p-4 shadow rounded-[6px] space-y-8">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-3">
            <LuCalendarDays size={29} />
            <h1 className="text-lg font-semibold text-gray-700">
              Event Details
            </h1>
          </div>
        </div>

        <form onSubmit={(e) => null} className="space-y-8">
          <div className="w-full md:w-1/2">
            <InputField
              published={eventInfo?.published || false}
              value={eventInfo?.eventName}
              label={"Event Name"}
              name="eventName"
              onChange={handleChange}
            />
          </div>

          <div className="w-full md:w-1/2">
            <InputField
              published={eventInfo?.published || false}
              value={eventInfo?.hosting_url}
              label={"Event URL"}
              name="hosting_url"
              onChange={handleChange}
            />
          </div>

          <div className="w-full md:w-1/2">
            <TimeInputSection
              published={eventInfo?.published || false}
              // published={false}
              start={{
                start_date: data.start_date,
                start_time: data.start_time,
              }}
              onStartDateChange={handleChange}
              onStartTimeChange={handleChange}
              end={{
                end_date: data.end_date,
                end_time: data.end_time,
              }}
              onEndDateChange={handleChange}
              onEndTimeChange={handleChange}
            />
          </div>
        </form>

        <div
          className={cn(
            "bg-gray-100 p-4 rounded-md shadow-inner text-sm text-gray-600",
            !eventInfo?.published && "hidden"
          )}
        >
          <strong>Info:</strong> Your event has already been published, for this
          reason you can&apos;t edit your event information.
        </div>

        <div
          className={cn(
            "flex items-center justify-end",
            eventInfo?.published && "hidden"
          )}
        >
          <CustomButton
            variant={"primary"}
            className={"px-8"}
            label="Save"
            disabled={eventInfo?.published || false}
            onClick={() => null}
          />
        </div>
      </div>
    </div>
  );
};

export const InputField = ({
  label,
  inputClassName,
  labelClassName,
  published,
  ...props
}) => {
  return (
    <div>
      <label
        htmlFor={props.name || props.id}
        className={cn(
          labelClassName,
          "flex items-center justify-between relative mb-2 uppercase tracking-wide text-sm font-semibold text-gray-600"
        )}
      >
        {label}
      </label>

      <div className="relative">
        <input
          {...props}
          className={cn(
            inputClassName,
            "w-full py-2 pl-4 pr-20 text-gray-600 outline-none focus:border-gray-400 border rounded-[6px] border-gray-200",
            props?.disabled && " bg-gray-100 cursor-not-allowed"
          )}
          disabled={published}
        />
        {props.name === "hosting_url" ? (
          <p className="text-gray-600 absolute top-0 right-0 pt-2 pr-4">
            .nowinvite.com
          </p>
        ) : null}
      </div>
    </div>
  );
};

const TimeInputSection = ({
  labelClassName,
  label,
  start,
  end,
  onStartDateChange,
  onEndDateChange,
  onStartTimeChange,
  onEndTimeChange,
  published,
  ...props
}) => {
  const Style = {
    picker: {
      date: "w-[250px] py-2 px-4 text-gray-600 outline-none focus:border-gray-400 border rounded-[6px] border-gray-200 disabled:bg-gray-50 disabled:cursor-not-allowed",
      time: "w-[180px] py-2 px-4 text-gray-600 outline-none focus:border-gray-400 border rounded-[6px] border-gray-200 disabled:bg-gray-50 disabled:cursor-not-allowed",
    },
  };

  const decorate = (date, name) => {
    return {
      target: {
        name,
        value: date,
      },
    };
  };

  return (
    <div>
      <label
        htmlFor={props.name || props.id}
        className={cn(
          "flex items-center justify-between relative mb-2 uppercase tracking-wide text-sm font-semibold text-gray-600"
        )}
      >
        Time
      </label>

      <table>
        <tr>
          <th className="font-semibold text-gray-600 w-16 flex-none text-left py-4">
            Start
          </th>

          <td className="pr-2">
            <DatePicker
              selected={start.start_date}
              onChange={(date) => {
                onStartDateChange(decorate(date, "start_date"));
              }}
              disabled={published}
              name={"start_date"}
              dateFormat="MMMM d, yyyy"
              className={Style.picker.date}
              placeholderText="Date"
            />
          </td>

          <td>
            <DatePicker
              selected={start.start_time || null}
              disabled={published}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="h:mm aa"
              className={Style.picker.time}
              onChange={(date) => {
                onStartDateChange(decorate(date, "start_time"));
              }}
              placeholderText="Time"
            />
          </td>
        </tr>

        <tr>
          <th className="font-semibold text-gray-600 w-16 flex-none text-left py-4">
            End
          </th>

          <td>
            <DatePicker
              selected={end.end_date}
              disabled={published}
              onChange={(date) => {
                onStartDateChange(decorate(date, "end_date"));
              }}
              dateFormat="MMMM d, yyyy"
              className={Style.picker.date}
              placeholderText="Date"
            />
          </td>

          <td>
            <DatePicker
              selected={end.end_time || null}
              disabled={published}
              onChange={(date) => {
                onStartDateChange(decorate(date, "end_time"));
              }}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="h:mm aa"
              className={Style.picker.time}
              placeholderText="Time"
            />
          </td>
        </tr>
      </table>
    </div>
  );
};

export default EventSetting;
