import React from 'react';
import Logo from '../../../assets/images/footerLogo.png';
function MiniFooter() {
  return (
    <div className="p-4 cursor-pointer flex gap-2 items-center justify-center">
      <span className="text-lg font-bold">Made with</span>
      <a href="http://localhost:3000/ ">
        <img src={Logo} alt="companylogo" style={{ width: '200px' }} />
      </a>
    </div>
  );
}

export default MiniFooter;
