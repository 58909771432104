import { cn } from "../../lib/basic";
import CustomButton from "../Button/Buttons";
import { FcExport } from "react-icons/fc";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PiRowsPlusTopThin } from "react-icons/pi";
import { CiPen, CiTrash } from "react-icons/ci";
import MainModal from "../modal/MainModal";
import { InputField } from "./EventSetting";
import { useDispatch, useSelector } from "react-redux";
import "../common/styles/theme.css";
import {
  selectFromInvitee,
  storeInvitees,
} from "../../redux/reducers/inviteeReducer";
import { Error, Success } from "../../utils/alert";
import {
  addInvitee,
  exportInviteeList,
  fetchInviteeList,
  removeInvitee,
  updateInvitee,
} from "../../service/InviteeList.service";
import Swal from "sweetalert2";
import { ColorRing } from "react-loader-spinner";
import ReactPaginate from "react-paginate";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { downloader } from "../../lib/downloader";

export const ColorRingOptions = {
  visible: true,
  height: "80",
  width: "80",
  ariaLabel: "color-ring-loading",
  wrapperClass: "color-ring-wrapper",
  colors: ["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"],
};

const PreviewInvitee = () => {
  const dispatch = useDispatch();
  // Select previous invitee_list data
  const invitee = useSelector(selectFromInvitee);
  const { eventId } = useParams();

  // For add invitee modal
  const [openInviteeModal, setInviteeModal] = useState(false);
  const [editInviteeModal, setEditInviteeModal] = useState(false);
  const [delete_loading, setDeleteLoading] = useState(false);
  const [export_loading, setExportLoading] = useState(false);
  const [update_loading, setUpdateLoading] = useState(false);

  // For pagination
  const [pageNumber, setPageNumber] = useState(0);
  const responsesPerPage = 10;
  // const pagesVisited = pageNumber * responsesPerPage;

  const pageCount = Math.ceil(invitee.invitee_list?.length / responsesPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  // Data to be editted
  const [data, setData] = useState({});

  useEffect(() => {
    refetch();
  }, []);

  // Clicked row index
  const [row_index, setRowIndex] = useState(0);

  // Refetch function
  const refetch = useCallback(() => {
    const fetchInvitee = async () => {
      try {
        const response = await fetchInviteeList(eventId);
        const { inviteeList } = response?.data;
        return inviteeList;
      } catch (error) {
        console.log("No Invitee List");
      }
    };

    fetchInvitee().then((data) => {
      dispatch(storeInvitees(data));
    });
  }, [dispatch, eventId]);

  // Remove invitee function
  const remove_invitee_from_list = useCallback(
    (email) => {
      // Prepare data
      const invitee_data = {
        email,
      };

      const delete_invitee = async () => {
        return await removeInvitee(eventId, invitee_data);
      };

      // Confirm by checking if user is willing,
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          // Loading state - true
          setDeleteLoading(true);

          delete_invitee()
            .then((resp) => {
              Success({
                message: resp.data.message,
                title: "Successfully deleted",
              });

              // Loading state - false
              setDeleteLoading(false);

              // Refetch invitee list
              refetch();
            })
            .catch((err) => {
              // Loading state - false
              setDeleteLoading(false);
              Error({
                message: err?.response?.data?.error || "Internal server error",
                title: "Failed to delete invitee.",
              });
            });
        }
      });
    },
    [eventId, setDeleteLoading, refetch]
  );

  // Calculate percentage for replies
  function find_replies() {
    if (Array.isArray(invitee.invitee_list)) {
      const reply_count = invitee.invitee_list.reduce((acc, curr) => {
        if (curr.replied) {
          acc++;
        }
        return acc;
      }, 0);

      return reply_count !== 0
        ? (reply_count / invitee.invitee_list.length).toFixed(1)
        : 0;
    }

    return 0;
  }

  // Export list as csv or xlsx
  const handleExport = async () => {
    setExportLoading(true);
    try {
      const response = await exportInviteeList(eventId);
      downloader({ file_name: "invitee-list.csv", blob: response.data });
    } catch (error) {
      Error({
        title: "Something went wrong!",
        message:
          error?.response?.data?.error || "Failed to export your invitees",
      });
    }
    setExportLoading(false);
  };

  // Handle edit invitation
  const handleEdit = (email) => {
    const doc = invitee?.invitee_list?.find((item) => item.email === email);
    if (doc) {
      setEditInviteeModal(true);
      setData(doc);
    }
  };

  return (
    <div>
      <div className="flex items-center mb-6 gap-10">
        <div className="w-1/2 text-center md:text-left text-neutral-600 md:w-auto">
          <div className="block text-brandGreen text-4xl tracking-wide font-bold">
            {invitee.invitee_list?.length || 0}
            <span>{invitee?.invitee_list?.length > 99 ? "+" : null}</span>
          </div>
          <span className="block text-neutral-600 uppercase text-sm tracking-wide">
            Guests on list
          </span>
        </div>

        <div className="w-1/2 text-center md:text-left md:w-auto">
          <span className="block text-4xl tracking-wide font-bold text-[#FFB444]">
            {find_replies()} %
          </span>
          <span className="block uppercase text-sm text-neutral-600 tracking-wide">
            Replied
          </span>
        </div>
      </div>

      <div className="bg-white shadow-md rounded-lg p-6">
        <div className="flex justify-between items-center mb-4 mt-2">
          <input
            type="text"
            placeholder="Search..."
            className="p-2 border border-gray-200 focus:border-gray-400 outline-none rounded-lg"
          />

          <div className="space-x-2 flex items-center">
            <CustomButton
              Icon={export_loading ? ColorRing : FcExport}
              label={"Export"}
              variant={"outlined"}
              onClick={handleExport}
              disabled={export_loading}
              iconProps={
                export_loading
                  ? {
                      ...ColorRingOptions,
                      height: "25",
                      width: "25",
                    }
                  : null
              }
            />
            <CustomButton
              Icon={PiRowsPlusTopThin}
              label={"Add Invitee"}
              variant={"primary"}
              onClick={() => setInviteeModal(true)}
            />
          </div>
        </div>

        {/* Event List */}
        <table className="w-full text-left">
          <thead>
            <tr>
              <th
                className={cn(
                  Style.table.th,
                  InviteeList.length === 0 && "border-transparent"
                )}
              >
                #
              </th>
              <th
                className={cn(
                  Style.table.th,
                  InviteeList.length === 0 && "border-transparent"
                )}
              >
                Title
              </th>
              <th
                className={cn(
                  Style.table.th,
                  InviteeList.length === 0 && "border-transparent"
                )}
              >
                First Name
              </th>
              <th
                className={cn(
                  Style.table.th,
                  InviteeList.length === 0 && "border-transparent"
                )}
              >
                Last Name
              </th>
              <th
                className={cn(
                  Style.table.th,
                  InviteeList.length === 0 && "border-transparent"
                )}
              >
                Email
              </th>
              <th
                className={cn(
                  Style.table.th,
                  // Use the actual fetched data
                  InviteeList.length === 0 && "border-transparent"
                )}
              ></th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(invitee.invitee_list) &&
              invitee.invitee_list
                .slice(
                  pageNumber * responsesPerPage,
                  pageNumber * responsesPerPage + responsesPerPage
                )
                .map((invitee, index) => {
                  const rowId = `invitee-${index}`;
                  return (
                    <React.Fragment key={rowId}>
                      <tr
                        className={cn(
                          "hover:bg-neutral-100",
                          index % 2 === 0 && "bg-neutral-50"
                        )}
                      >
                        <td className={cn(Style.table.td)}>
                          {pageNumber * responsesPerPage + index + 1}
                        </td>
                        <td className={cn(Style.table.td)}>{invitee.title}</td>
                        <td className={cn(Style.table.td)}>
                          {invitee.firstName}
                        </td>
                        <td className={cn(Style.table.td)}>
                          {invitee.lastName}
                        </td>
                        <td className={cn(Style.table.td)}>{invitee.email}</td>
                        <td className={cn(Style.table.td)}>
                          <button
                            disabled={delete_loading || update_loading}
                            className="focus:outline-none rounded-md p-1"
                          >
                            {index === row_index &&
                            delete_loading &&
                            update_loading ? (
                              <ColorRing
                                {...ColorRingOptions}
                                height="20"
                                width={20}
                              />
                            ) : (
                              <div className="flex items-end gap-2">
                                <div>
                                  <CiPen
                                    onClick={() => handleEdit(invitee.email)}
                                    size={20}
                                    color="#1e1e1e"
                                  />
                                </div>
                                <div className="bg-red-200">
                                  <CiTrash
                                    onClick={() => {
                                      remove_invitee_from_list(invitee.email);
                                      setRowIndex(index);
                                    }}
                                    size={20}
                                    color="#ff0049"
                                  />
                                </div>
                              </div>
                            )}
                          </button>
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })}
          </tbody>
        </table>

        <div
          className={cn(
            "flex justify-center space-x-2 mt-12",
            invitee.invitee_list?.length === 0 && "hidden"
          )}
        >
          <ReactPaginate
            previousLabel={<FaChevronLeft />}
            nextLabel={<FaChevronRight />}
            pageCount={pageCount}
            onPageChange={changePage}
            containerClassName={"pagination"}
            previousLinkClassName={"previous"}
            nextLinkClassName={"next"}
            disabledClassName={"disabled"}
            activeClassName={"active"}
          />
        </div>
      </div>

      {openInviteeModal && (
        <AddInviteeModal
          event_id={eventId}
          open={openInviteeModal}
          refetch={refetch}
          close={() => setInviteeModal(false)}
        />
      )}

      {editInviteeModal && (
        <EditInviteeModal
          event_id={eventId}
          open={editInviteeModal}
          refetch={refetch}
          close={() => setEditInviteeModal(false)}
          data={data}
        />
      )}
    </div>
  );
};

const AddInviteeModal = ({ open, close, event_id, refetch }) => {
  const [loading, setLoading] = useState(false);
  const [inviteeData, setInviteeData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    title: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !inviteeData.firstName ||
      !inviteeData.lastName ||
      !inviteeData.email ||
      !inviteeData.title
    ) {
      return Error({
        message: "All fields are required",
        title: "Can't add this guest.",
      });
    }

    setLoading(true);
    // Start submission
    try {
      const response = await addInvitee(event_id, inviteeData);
      Success({
        message: response.data.message,
        title: "Success,",
      });

      // refetch table list
      refetch();
    } catch (error) {
      Error({
        message: error.response.data.error || "Failed to add invitee",
        title: "Something went wrong!",
      });
      console.error(error);
    } finally {
      setLoading(false);
      close();
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setInviteeData((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <MainModal
      closeModal={close}
      title={"Add Invitee"}
      className={"space-y-4"}
      isOpen={open}
    >
      <InputField
        label={"Title"}
        placeholder="Mr., Ms., Dr."
        onChange={handleChange}
        name="title"
        value={inviteeData.title}
      />

      <InputField
        label={"First Name"}
        placeholder="John"
        onChange={handleChange}
        name="firstName"
        value={inviteeData.firstName}
      />

      <InputField
        label={"Last Name"}
        placeholder="Doe"
        onChange={handleChange}
        name="lastName"
        value={inviteeData.lastName}
      />

      <InputField
        label={"Email"}
        placeholder="johndoe@domain.com"
        onChange={handleChange}
        name="email"
        value={inviteeData.email}
      />

      <div className="flex items-center justify-end mt-4 gap-2">
        <CustomButton
          label={loading ? "Adding ..." : "Submit"}
          onClick={handleSubmit}
          variant={"primary"}
          className={"px-3"}
          disabled={loading}
        />
        <CustomButton
          label="Close"
          disabled={loading}
          onClick={close}
          variant={"ghost"}
          className={"px-3"}
        />
      </div>
    </MainModal>
  );
};

const EditInviteeModal = ({ open, close, event_id, refetch, data }) => {
  const [loading, setLoading] = useState(false);
  const [inviteeData, setInviteeData] = useState(data);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !inviteeData.firstName ||
      !inviteeData.lastName ||
      !inviteeData.email ||
      !inviteeData.title
    ) {
      return Error({
        message: "All fields are required",
        title: "Can't edit this invitation.",
      });
    }

    setLoading(true);
    // Start submission
    try {
      const response = await updateInvitee(event_id, inviteeData);
      Success({
        message: response.data.message,
        title: "Success,",
      });

      // refetch table list
      refetch();
    } catch (error) {
      Error({
        message: error.response.data.error || "Failed to edit the invitation",
        title: "Something went wrong!",
      });
      console.error(error);
    } finally {
      setLoading(false);
      close();
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") return;
    setInviteeData((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <MainModal
      closeModal={close}
      title={"Edit Invitee"}
      className={"space-y-4"}
      isOpen={open}
    >
      <InputField
        label={"Title"}
        placeholder="Mr., Ms., Dr."
        onChange={handleChange}
        name="title"
        value={inviteeData.title}
      />

      <InputField
        label={"First Name"}
        placeholder="John"
        onChange={handleChange}
        name="firstName"
        value={inviteeData.firstName}
      />

      <InputField
        label={"Last Name"}
        placeholder="Doe"
        onChange={handleChange}
        name="lastName"
        value={inviteeData.lastName}
      />

      <InputField
        disabled={true}
        label={"Email"}
        placeholder="johndoe@domain.com"
        onChange={handleChange}
        name="email"
        value={inviteeData.email}
      />

      <div className="flex items-center justify-end mt-4 gap-2">
        <CustomButton
          label={loading ? "Editting ..." : "Edit"}
          onClick={handleSubmit}
          variant={"primary"}
          className={"px-3"}
          disabled={loading}
        />
        <CustomButton
          label="Close"
          disabled={loading}
          onClick={close}
          variant={"ghost"}
          className={"px-3"}
        />
      </div>
    </MainModal>
  );
};

const Style = {
  table: {
    th: "border-b border-neutral-300 py-2 font-semibold text-[14px] text-[#4B5563]",
    td: "border-b py-2 text-sm text-neutral-600 border-neutral-200",
  },
};

const InviteeList = [
  {
    title: "Mr.",
    firstName: "John",
    lastName: "Doe",
    email: "johndoe@gmail.com",
  },
  {
    title: "Ms.",
    firstName: "Jane",
    lastName: "Smith",
    email: "janesmith@gmail.com",
  },
  {
    title: "Dr.",
    firstName: "Emily",
    lastName: "Taylor",
    email: "emilytaylor@gmail.com",
  },
  {
    title: "Mr.",
    firstName: "Michael",
    lastName: "Brown",
    email: "michaelbrown@gmail.com",
  },
  {
    title: "Mrs.",
    firstName: "Laura",
    lastName: "Davis",
    email: "lauradavis@gmail.com",
  },
  {
    title: "Dr.",
    firstName: "Robert",
    lastName: "Wilson",
    email: "robertwilson@gmail.com",
  },
  {
    title: "Ms.",
    firstName: "Sophia",
    lastName: "Martinez",
    email: "sophiamartinez@gmail.com",
  },
  {
    title: "Mr.",
    firstName: "James",
    lastName: "Anderson",
    email: "jamesanderson@gmail.com",
  },
  {
    title: "Mrs.",
    firstName: "Olivia",
    lastName: "Moore",
    email: "oliviamoore@gmail.com",
  },
  {
    title: "Dr.",
    firstName: "David",
    lastName: "Clark",
    email: "davidclark@gmail.com",
  },
];

export default PreviewInvitee;
