import React from 'react';

function EventNameForm({ nextStep, handleChange, values }) {
  const isValueGiven = () => {
    return values.eventName;
  };
  return (
    <div className='w-1/2 mx-auto'>
           <h2 className='text-center font-bold text-3xl my-10 c'>When is the name of your event?</h2>
           <input
        type="text"
        name="eventName"
        placeholder="Enter event name"
        value={values.eventName}
        onChange={handleChange}
        className="border p-2 w-full placeholder:text-gray-500"
      />
      <button onClick={nextStep}
                className={`bg-yellow-500 my-20 text-white w-full p-2 ${!isValueGiven() ? 'opacity-50 cursor-not-allowed' : ''}`}
                disabled={!isValueGiven()}       
       >Continue</button>
    </div>
  );
}

export default EventNameForm;
