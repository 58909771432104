import React, { useState, useCallback } from 'react';
import { message } from 'antd';
import axios from 'axios';
import HeaderSection from './HeaderSection';
import ScheduleSection from './ScheduleSection';
import VenueSection from './VenueSection';
import DressCodeSection from './DressCodeSection';
import { apiUrl } from '../../../utils/const';
import Settting from './Settting';
// import MiniFooter from './MiniFooter';
import LocationSection from './ LocationSection';
import StorySection from './StorySection';
import FAQSection from './FAQSection';
import MiniFooter from './MiniFooter';
import GallerySection from './GallerySection';

function WeddingTemplate({ eventData, wallId, isEditing, contents }) {
  const [contentValues, setContentValues] = useState(eventData.contents);
  const [editingSection, setEditingSection] = useState(null);
  const { coverPhoto } = eventData;
  const [coverImage, setCoverImage] = useState(`${apiUrl}/${coverPhoto}`);
  const openEditor = (content) => {
    setEditingSection(content.type);
    console.log(content);
  };

  const closeEditor = () => {
    setEditingSection(null);
  };

  const handleContentChange = (type, newValue) => {
    const updatedContent = contentValues.map((content) => {
      if (content.type === type) {
        // return { ...content, text: newValue };
        return {
          ...content,
          ...(type === 'schedule' ? { events: newValue } : { text: newValue }),
        };
      }
      return content;
    });
    setContentValues(updatedContent);
    sendChange(updatedContent);
  };

  const sendChange = useCallback(
    async (updatedContent) => {
      try {
        await axios.put(`${apiUrl}/api/wall/${wallId}`, {
          ...eventData,
          contents: updatedContent,
        });
        message.success('Data updated successfully!', 3);
      } catch (error) {
        message.error('Error updating data!', 3);
      }
    },
    [eventData, wallId]
  );
  return (
    <div className={`flex flex-col justify-center items-center`}>
      {/* Section for Settings when editing */}
      {isEditing && editingSection && (
        <Settting
          editingSection={editingSection}
          closeEditor={closeEditor}
          eventData={eventData}
          wallId={wallId}
          setCoverImage={setCoverImage}
          coverImage={coverImage}
        />
      )}

      {/* Each section behaves differently depending on isEditing */}
      {contents.some(
        (content) => content.type === 'header' && content.show
      ) && (
        <HeaderSection
          eventData={eventData}
          contentValues={contentValues}
          coverImage={coverImage}
          handleContentChange={handleContentChange}
          isEditing={isEditing}
          openEditor={openEditor}
          closeEditor={closeEditor}
        />
      )}

      {contentValues.some(
        (content) => content.type === 'story' && content.show
      ) && (
        <StorySection
          eventData={eventData}
          contentValues={contentValues}
          handleContentChange={handleContentChange}
          isEditing={isEditing}
          openEditor={openEditor}
          closeEditor={closeEditor}
        />
      )}
      {contents.some(
        (content) => content.type === 'gallery' && content.show
      ) && (
        <GallerySection
          eventData={eventData}
          isEditing={isEditing}
          openEditor={openEditor}
          closeEditor={closeEditor}
        />
      )}

      {contents.some(
        (content) => content.type === 'schedule' && content.show
      ) && (
        <ScheduleSection
          eventData={eventData}
          contentValues={contentValues}
          handleContentChange={handleContentChange}
          isEditing={isEditing}
          openEditor={openEditor}
          closeEditor={closeEditor}
        />
      )}

      {contents.some(
        (content) => content.type === 'location' && content.show
      ) && (
        <LocationSection
          eventData={eventData}
          contentValues={contentValues}
          handleContentChange={handleContentChange}
          isEditing={isEditing}
          openEditor={openEditor}
          closeEditor={closeEditor}
        />
      )}
      {contents.some((content) => content.type === 'faq' && content.show) && (
        <FAQSection
          eventData={eventData}
          isEditing={isEditing}
          handleContentChange={handleContentChange}
          openEditor={openEditor}
        />
      )}

      {/* <DressCodeSection
        eventData={eventData}
        contentValues={contentValues}
        handleContentChange={handleContentChange}
        isEditing={isEditing}
        openEditor={openEditor}
        closeEditor={closeEditor}
      /> */}
      {!isEditing && <MiniFooter />}
    </div>
  );
}

export default WeddingTemplate;
