import babyShower from '../../assets/images/RSVP/baby.png';
import brideShower from '../../assets/images/RSVP/shower.png';
import wedding from '../../assets/images/RSVP/wedding.png';
import birthday from '../../assets/images/RSVP/birthday.png';
import church from '../../assets/images/RSVP/church.png';
import corporate from '../../assets/images/RSVP/corporate.png';

const data = [
  {
    id: 1,
    image: wedding,
    category: 'wedding',
    title: 'Weddings',
    description:
      'Planning a beautiful weeding ceremony? Find everything you need here.',
  },
  {
    id: 2,
    image: birthday,
    title: 'Birthdays',
    category: 'birthday',
    description:
      'Celebrate special birthdays with style and flair. Explore our birthday event essentials.',
  },
  {
    id: 3,
    image: church,
    title: 'Church',
    category: 'category',
    description:
      'Organize church events and gatherings seamlessly with our comprehensive tools.',
  },
  {
    id: 4,
    image: babyShower,
    title: 'Baby Shower',
    category: 'babyShower',
    description:
      'Welcome the newest addition to your family with adorable baby shower arrangements.',
  },
  {
    id: 5,
    image: brideShower,
    title: 'Bridal Shower',
    category: 'brideShower',
    description:
      'Create memorable moments for brides-to-be with elegant bridal shower setups.',
  },
  {
    id: 6,
    image: corporate,
    title: 'Corporate',
    category: 'corporate',
    description:
      'Plan and execute successful corporate events with our professional event management solutions.',
  },
];

export default data;
