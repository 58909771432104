import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useNavigate } from "react-router-dom";
import { fall_back_img } from "../../assets";
import { FaTachometerAlt, FaCogs, FaEye, FaTrashAlt } from "react-icons/fa";
import { useSelector } from "react-redux";
import { selectToken } from "../../redux/reducers/authReducer";
import { apiUrl } from "../../utils/const";

const EventCard = ({
  eventId,
  attending,
  maybe,
  notAttending,
  thumbnail,
  eventName,
  onDelete,
}) => {
  const navigate = useNavigate();
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setDropdownOpen((prevState) => !prevState);
  };
  const token = useSelector(selectToken);

  // Close dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const deleteEvent = async () => {
    setLoading(true);
    try {
      await axios.delete(`${apiUrl}/api/events/${eventId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setLoading(false);
      setShowMessage(true);
      isDropdownOpen(false);
      setTimeout(() => {
        setShowMessage(false);
        onDelete(eventId);
      }, 3000);
    } catch (error) {
      setLoading(false);
      alert("Failed to delete the event.");
    }
  };

  return (
    <div className="bg-gray-100 p-4 cursor-pointer relative rounded-lg shadow-md  items-center justify-center flex ">
      <LazyLoadImage
        src={thumbnail || fall_back_img}
        alt={`Event ${eventId}`}
        className="w-full rounded-3xl h-auto cursor-pointer"
        effect="blur"
        onClick={() => navigate(`/dashboard/events/${eventId}/reporting`)}
      />

      {/* Attendee Information */}
      <div className="absolute w-3/4 top-2/3 right-1/2 translate-x-1/2 z-50 mt-4 text-white bg-brandGreen p-2 py-6 rounded-lg">
        <div className="text-white text-2xl font-bold text-center px-4 py-2 rounded-md">
          {eventName}
        </div>
        <div className="flex justify-around">
          <div>
            <span className="block text-center text-lg font-bold">
              {attending}
            </span>
            <span className="block text-center text-sm">Attending</span>
          </div>
          <div>
            <span className="block text-center text-lg font-bold">{maybe}</span>
            <span className="block text-center text-sm">Maybe</span>
          </div>
          <div>
            <span className="block text-center text-lg font-bold">
              {notAttending}
            </span>
            <span className="block text-center text-sm">Not Attending</span>
          </div>
        </div>
      </div>

      {/* Top-right menu icon (Visible on Hover) */}
      <div className="absolute top-4 right-4 z-50">
        <svg
          onClick={toggleDropdown}
          viewBox="0 0 24 24"
          className="w-8 h-8 p-1 text-gray-700 transition ease-in-out duration-150 cursor-pointer group-hover:block hidden"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            fill="none"
            stroke="currentColor"
            d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
          />
        </svg>

        {/* Dropdown Menu */}
        {isDropdownOpen && (
          <div
            ref={dropdownRef}
            className="absolute right-5 top-8 w-48 bg-white shadow-lg rounded-lg py-2 text-gray-700"
          >
            <button
              onClick={() => navigate(`/events/${eventId}/reporting`)}
              className="flex items-center px-4 py-2 hover:bg-gray-100 w-full"
            >
              <FaTachometerAlt className="mr-2" /> Dashboard
            </button>
            <button
              onClick={() => navigate(`/events/${eventId}/setup`)}
              className="flex items-center px-4 py-2 hover:bg-gray-100 w-full"
            >
              <FaCogs className="mr-2" /> Setup
            </button>
            <button
              onClick={() => navigate(`/events/${eventId}/view`)}
              className="flex items-center px-4 py-2 hover:bg-gray-100 w-full"
            >
              <FaEye className="mr-2" /> View as Guest
            </button>
            <button
              onClick={deleteEvent}
              disabled={isLoading}
              className={`flex items-center px-4 py-2 hover:bg-red-100 text-red-600 w-full ${
                isLoading ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              {isLoading ? (
                <span>Deleting...</span>
              ) : (
                <>
                  <FaTrashAlt className="mr-2" /> Delete Event
                </>
              )}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default EventCard;
