import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { apiUrl } from '../../utils/const';
import { useParams } from 'react-router-dom';

const NewEmail = () => {
  const [subject, setSubject] = useState('');
  const [replyTo, setReplyTo] = useState('');
  const [emailInputs, setEmailInputs] = useState(['']);
  const [eventInfo, setEventInfo] = useState(null); // State to store event information
  const [error, setError] = useState(''); // State to store error messages
  const { eventId } = useParams();

  // Function to fetch event details from backend API
  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/events/${eventId}`);
        setEventInfo(response.data);
      } catch (error) {
        console.error('Error fetching event details:', error);
      }
    };

    fetchEventDetails();
  }, [eventId]);

  const handleEmailInputChange = (index, value) => {
    const newEmailInputs = [...emailInputs];
    newEmailInputs[index] = value;
    setEmailInputs(newEmailInputs);
  };

  const addEmailInput = () => {
    setEmailInputs([...emailInputs, '']);
  };

  const removeEmailInput = (index) => {
    const newEmailInputs = emailInputs.filter((_, i) => i !== index);
    setEmailInputs(newEmailInputs);
  };

  const sendInvitations = async () => {
    // Validate inputs
    if (!subject || !replyTo || emailInputs.length === 0 || emailInputs.some(email => !email)) {
      setError('Please fill out all fields before sending.');
      return;
    }
    setError(''); // Clear previous errors

    try {
      const response = await axios.post(`${apiUrl}/api/invitations`, {
        newInvitation: {
          event_id: eventInfo.id,
          guestEmails: emailInputs,
          reply_to: replyTo,
          subject: subject,
          status: 'sent',
          sent_at: new Date().toISOString()
        },
        eventDetails: eventInfo // Include event info for dynamic email content
      });
      console.log('Invitations sent successfully:', response.data);
    } catch (error) {
      console.error('Error sending invitations:', error);
    }
  };

  return (
    <div className="w-3/4 mx-auto flex flex-col p-4">
      {error && <div className="text-red-500 mb-2">{error}</div>}
      <input
        type="text"
        className="p-2 border border-gray-300 rounded-lg mb-2"
        value={subject}
        onChange={(e) => setSubject(e.target.value)}
        placeholder="Enter Subject"
      />
      <input
        type="email"
        className="p-2 border border-gray-300 rounded-lg mb-2"
        value={replyTo}
        onChange={(e) => setReplyTo(e.target.value)}
        placeholder="Enter Reply-To Email"
      />
      {emailInputs.map((email, index) => (
        <div key={index} className="flex items-center mb-2">
          <input
            type="email"
            className="p-2 border border-gray-300 rounded-lg flex-grow"
            value={email}
            onChange={(e) => handleEmailInputChange(index, e.target.value)}
            placeholder={`Enter Email ${index + 1}`}
          />
          {index > 0 && (
            <button
              className="py-1 px-3 text-red-500 border-2 border-red-500 rounded-full ml-2"
              onClick={() => removeEmailInput(index)}
            >
              -
            </button>
          )}
        </div>
      ))}
      <button
        className="py-1 px-3 text-blue-500 border-2 border-blue-500 rounded-full mb-2"
        onClick={addEmailInput}
      >
        +
      </button>
      <div className="flex justify-center mt-10">
        <button
          className="py-2 px-8 bg-green-500 text-white rounded-full"
          onClick={sendInvitations}
        >
          Send
        </button>
      </div>
    </div>
  );
};

export default NewEmail;
