import { ADMIN, SUPER_ADMIN, USER } from "../utils/const";

const accessList = {
    [SUPER_ADMIN]: [
        /.*/,
    ],

    [ADMIN]: [
        /.*/,
    ],

    [USER]:  [
        /^\/dashboard\/?.*/,
        /^\/templates\/?.*/,
        /^\/my-account\/?.*/,
        /\/rsvp-detail*/,
    ],
}

export const checkAccess = (role, pathname) => {
    if(!role){
        return false;
    };

    return accessList[role].some((path) => (
        new RegExp(path).test(pathname)
    ))
}