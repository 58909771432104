import React, { useEffect, useState } from "react";
import Hlogo from "../../assets/images/Hlogo.png";
import {
  MdFormatListBulletedAdd,
  MdPublishedWithChanges,
} from "react-icons/md";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { FaWpforms, FaQuestion } from "react-icons/fa6";
import { CiEdit, CiSettings } from "react-icons/ci";
import { IoEyeOutline } from "react-icons/io5";
import { selectUser } from "../../redux/reducers/authReducer";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { GiConfirmed } from "react-icons/gi";
import { cn } from "../../lib/basic";
import { fetchEvent, publishEvent, unpublishEvent } from "../../service/Event.service";
import MainModal from "../modal/MainModal";
import CustomButton from "../Button/Buttons";
import { Error, Success } from "../../utils/alert";
import { ColorRing } from "react-loader-spinner";
import { FcRemoveImage } from "react-icons/fc";
import { removeEvent, storeEvent } from "../../redux/reducers/eventReducer";

const Sidebar = ({ eventId }) => {
  const [isVisible, setIsVisible] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const { event } = useSelector((state) => state.event);

  const [loading, setLoading] = useState({
    publish: false,
    event: false,
  });
  const [eventDetail, setEventDetail] = useState({});
  const [publishModal, setPublishModal] = useState(false);

  function isCurrentTab(tab) {
    if (isVisible) return;

    if (location.pathname.includes(tab)) {
      return "bg-brandGreen text-white rounded-[10px] pl-3 -ml-2";
    }
  }

  // For publishing
  const handlePublish = async () => {
    setLoading((prev) => ({
      ...prev,
      publish: true,
    }));

    try {
      const response = await publishEvent(eventId);
      if (response.status === 200) {
        Success({
          message:
            "Your event has been published. Email is being sent to your guests.",
          title: "Congratulations!",
        });
      }
    } catch (error) {
      console.log(error);
      Error({
        message: "Please try again later.",
        title: "Oops something went wrong!",
      });
    }

    setLoading((prev) => ({
      ...prev,
      publish: false,
    }));

    setPublishModal(false);
  };

  const handleUnpublishEvent = async () => {
    setLoading((prev) => ({
      ...prev,
      publish: true,
    }));

    try {
      const response = await unpublishEvent(eventId);
      const { status } = response;
      if(status === 200 || status === 201 ){
        Success({
          title: "Success",
          message: "Your event has been unpublished. Not a single invitee is able to see it."
        })
      }
    } catch (error) {
        Error({
          message: "Please try again later.",
          title: "Oops something went wrong!",
        })
    }finally{
      setLoading(prev => ({
        ...prev,
        publish: false,
      }))
    }
  }

  useEffect(() => {
    const handleFetchEventDetail = async () => {
      setLoading((prev) => ({
        ...prev,
        event: true,
      }));
      try {
        const response = await fetchEvent(eventId);
        if (response.status === 200) {
          setEventDetail(response.data);
          // Store in redux state
          dispatch(storeEvent(response.data));
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading((prev) => ({
          ...prev,
          event: false,
        }));
      }
    };

    handleFetchEventDetail();
  }, [eventId, loading.publish, dispatch]);
  
  return (
    <div
      className="w-[20rem] bg-[#fff] shadow-lg p-4 fixed h-full top-0 overflow-y-auto"
      style={{ maxHeight: "100vh" }}
    >
      <div className="flex lg:flex-1 my-4">
        <Link to="/dashboard" className="-m-1.5 p-1.5">
          <span className="sr-only">NowInvite</span>
          <img className="h-6 w-auto" src={Hlogo} alt="company logo" />
        </Link>
      </div>
      <nav className="space-y-4 mt-10">
        <div className="font-bold text-lg">Dashboard</div>
        <div className="ml-3">
          {/* <Link
            to={`/dashboard/events/${eventId}/event-checklist`}
            className={cn(isCurrentTab(`/dashboard/events/${eventId}/event-checklist`), "flex gap-3 items-center mb-2  w-[230px] cursor-pointer h-[34px]")}
          >
            <MdChecklist />
            <p >
              Event Checklist
            </p>
          </Link> */}
          <Link
            to={`/dashboard/events/${eventId}/reporting`}
            className={cn(
              isCurrentTab(`/events/${eventId}/reporting`),
              "flex gap-3 items-center mb-3  w-[230px] cursor-pointer h-[34px]"
            )}
          >
            <HiOutlineDocumentReport />
            <p>Reporting</p>
          </Link>
        </div>
        <div className="font-bold text-lg mt-3">Customize</div>
        <div className="ml-3">
          <Link
            to={`/dashboard/events/${eventId}/wall-designer`}
            className={cn(
              isCurrentTab(`/dashboard/events/${eventId}/wall-designer`),
              "flex gap-3 items-center mb-2  w-[230px] cursor-pointer h-[34px]"
            )}
          >
            <FaWpforms />
            <p>Event website builder</p>
          </Link>
          {/* <Link
            to={`/dashboard/events/${eventId}/form-builder`}
            className={cn(
              isCurrentTab(`/dashboard/events/${eventId}/form-builder`),
              "flex gap-3 items-center mb-2  w-[230px] cursor-pointer h-[34px]"
            )}
          >
            <FaWpforms />
            <p>Form builder</p>
          </Link> */}
          <Link
            to={`/dashboard/events/${eventId}/settings`}
            className={cn(
              isCurrentTab(`/events/${eventId}/settings`),
              "flex gap-3 items-center mb-2  w-[230px] cursor-pointer h-[34px]"
            )}
          >
            <CiSettings />
            <p>Event Settings</p>
          </Link>
          <Link
            to={`/dashboard/events/${eventId}/invite-lists`}
            className={cn(
              isCurrentTab(`/dashboard/events/${eventId}/invite-lists`),
              "flex gap-3 items-center mb-3  w-[230px] cursor-pointer h-[34px]"
            )}
          >
            <MdFormatListBulletedAdd />
            <p>Invite Lists</p>
          </Link>
        </div>
        {/* <div className="font-bold text-lg mt-3">Organize</div>
        <div className="ml-3"> */}
          {/* <div
            className={cn("flex gap-3 items-center mb-2  w-[230px] cursor-pointer h-[34px]")}
            onClick={handleDown}
          >
            <MdOutlineMailOutline />
            <p className='whitespace-nowrap'>Email Communications</p>
            {!isVisible ? <FaAngleDown /> : <IoIosArrowUp />}
          </div> */}
          {/* {isVisible && (
            <div className="ml-10 flex flex-col gap-2 mt-2">
              <Link
                to={`/dashboard/events/${eventId}/new-email`}
                className="cursor-pointer flex items-center space-x-2"
              >
                <FontAwesomeIcon color="#616060" icon={faAdd} size="sm" />
                <span className='block'>New email</span>
              </Link>
              <Link
                to={`/dashboard/events/${eventId}/sent-email`}
                className="cursor-pointer flex items-center space-x-2"
              >
                <FontAwesomeIcon color="#616060" icon={faMessage} size="sm" />
                <span className='block'>Sent</span>
              </Link>
              <Link
                to={`/events/${eventId}/draft-email`}
                className="cursor-pointer flex items-center space-x-2"
              >
                <FontAwesomeIcon color="#616060" icon={faDraftingCompass} size="sm" />
                <span className='block'>Draft</span>
              </Link>
            </div>
          )} */}
          {/* <Link
            to={`/events/${eventId}/check-in`}
            className="flex gap-3 items-center mb-3 cursor-pointer"
          >
            <LuScanLine />
            <p>Check-in</p>
          </Link>
        </div> */}
        <div className="font-bold text-lg mt-3">Launch</div>
        <div className="ml-3">
          <a
            href={`${event?.hosting_url + ".nowinvite.com"}`}
            target="_blank"
            rel="noopener noreferrer"
            className="flex gap-3 items-center mb-2 h-[34px] cursor-pointer"
          >
            <IoEyeOutline />
            <p>Preview</p>
          </a>

          {!!event && event.published ? (
            <button
              onClick={handleUnpublishEvent}
              disabled={loading.publish}
              className="flex gap-3 items-center mb-2 h-[34px] cursor-pointer"
            >
              <FcRemoveImage />
              <p>Unpublish</p>
            </button>
          ) : (
            <button
              disabled={loading.publish}
              onClick={() => setPublishModal(true)}
              className="flex gap-3 items-center mb-2 h-[34px] cursor-pointer"
            >
              <MdPublishedWithChanges />
              <p>Publish</p>
            </button>
          )}

          {/* <Link
            to="/share-invite"
            className="flex gap-3 items-center mb-2 h-[34px] cursor-pointer"
          >
            <CiShare2 />
            <p>Share & Invite</p>
          </Link> */}
        </div>
      </nav>

      {publishModal && (
        <PublishModal
          loading_state={loading.publish}
          isOpen={publishModal}
          close={() => setPublishModal(false)}
          event_id={eventId}
          onClick={handlePublish}
          navigate={navigate}
        />
      )}
    </div>
  );
};

const PublishModal = ({
  isOpen,
  close,
  onClick,
  navigate,
  event_id,
  loading_state,
}) => {
  return (
    <MainModal
      title={"Publish Event"}
      closeModal={close}
      isOpen={isOpen}
      className={"space-y-4"}
    >
      <div className="flex items-center justify-center flex-col gap-6">
        <p>
          <FaQuestion size={64} color="#484848" />
        </p>
        <p className="text-xl text-neutral-600 font-semibold">
          Are you sure you want to publish this event?
        </p>
        <p className="text-sm text-neutral-500 text-center">
          Once published:
          <ul className="list-disc mt-2 pl-5 text-left">
            <li>The event will be visible to all invitees and attendees.</li>
            <li>You will no longer be able to edit the event date and time.</li>
            <li>
              Notifications and invites will be automatically sent out to all
              invitees.
            </li>
          </ul>
        </p>
      </div>

      <div className="bg-gray-100 p-4 rounded-md shadow-inner text-sm text-gray-600">
        <strong>Tip:</strong> Before publishing, double-check the event details,
        settings, and attendee list to ensure everything is ready. You can
        customize event settings or add more details to enhance attendee
        engagement.
      </div>

      <div className="flex items-center justify-center gap-4 mt-4">
        <CustomButton
          label={loading_state ? "Please wait..." : "Publish Now"}
          onClick={onClick}
          variant={"primary"}
          Icon={loading_state ? ColorRing : GiConfirmed}
          iconProps={{
            visible: true,
            height: "25",
            width: "25",
            ariaLabel: "color-ring-loading",
            colors: ["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"],
          }}
        />
        <CustomButton
          label="Review Settings"
          onClick={() => {
            navigate(`/dashboard/events/${event_id}/settings`);
            close();
          }}
          Icon={CiEdit}
          variant={"outlined"}
        />
        <CustomButton label="Cancel" onClick={close} variant={"destructive"} />
      </div>
    </MainModal>
  );
};

export default Sidebar;
