import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { ChevronLeftIcon, ChevronRightIcon } from "lucide-react";
import Button from "./Button/Buttons";
import Logo1 from "../assets/logos/1.png";
import Logo2 from "../assets/logos/2.png";
import Logo3 from "../assets/logos/3.png";
import Logo4 from "../assets/logos/nowinvite.png";

const logos = [
  { id: 1, src: Logo1, alt: "logo1" },
  { id: 2, src: Logo2, alt: "logo2" },
  { id: 3, src: Logo3, alt: "logo3" },
  { id: 4, src: Logo4, alt: "logo4" },
];

export default function AboutUs() {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % logos.length);
    }, 5000);
    return () => clearInterval(timer);
  }, []);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % logos.length);
  };

  const prevSlide = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + logos.length) % logos.length
    );
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-100 to-white py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <h1 className="text-4xl font-bold text-center mb-8 text-gray-800">
          About Us
        </h1>

        <div className="relative h-64 sm:h-96 mb-12">
          <div className="absolute inset-0 bg-gray-300 rounded-lg shadow-lg overflow-hidden">
            <img
              src={logos[currentIndex].src}
              alt={logos[currentIndex].alt}
              className="w-full h-full object-cover"
            />
          </div>

          <div className="absolute inset-0 flex items-center justify-between">
            <Button
              onClick={prevSlide}
              className="bg-white p-2 rounded-full shadow-lg"
            >
              <ChevronLeftIcon size={32} />
            </Button>

            <Button
              onClick={nextSlide}
              className="bg-white p-2 rounded-full shadow-lg"
            >
              <ChevronRightIcon size={32} />
            </Button>
          </div>
        </div>

        <motion.div
          className="bg-white shadow-lg rounded-lg p-6 mb-8"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.8 }}
        >
          <p className="text-gray-700 leading-relaxed">
            NowInvite's logo cleverly merges the letters "N" and "I",
            symbolizing a postal letter. An upward arrow integrated into the
            negative space of the "I" represents fast and efficient digital
            invitation delivery. The design captures the platform's commitment
            to seamless communication and swift event coordination.
          </p>
        </motion.div>

        <motion.div
          className="grid grid-cols-2 sm:grid-cols-4 gap-4"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.4, duration: 0.8 }}
        >
          {logos.map((logo) => (
            <div key={logo.id} className="bg-white p-4 rounded-lg shadow-md">
              <img src={logo.src} alt={logo.alt} className="w-full h-auto" />
            </div>
          ))}
        </motion.div>
      </div>
    </div>
  );
}
