import axios from 'axios';
import React, { useState } from 'react';
import { apiUrl } from '../../utils/const';

function RSVPForm({
  eventId,
  userId,
  startDate,
  eventName,
  startTime,
  wallDesign,
}) {
  const [formData, setFormData] = useState({
    guestsInfo: [
      { firstName: '', lastName: '', email: '', food_preference: '' },
    ],
    notAttending: false,
    attending: 0,
    cantMakeItInfo: { firstName: '', lastName: '', email: '', whyCant: '' },
  });

  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const Food_preference = wallDesign.food_preference;

  // Handle RSVP form submission
  const handleSubmit = async () => {
    const { guestsInfo, notAttending, attending, cantMakeItInfo } = formData;

    // Prepare data for API submission
    let responseData = {
      invitation_id: '',
      eventId,
      userId,
      startDate,
      eventName,
      startTime,
      response: notAttending ? 'notAttending' : 'attending',
      guestsInfo: notAttending ? [cantMakeItInfo] : guestsInfo,
      message: notAttending ? cantMakeItInfo.whyCant : null,
    };

    try {
      setLoading(true); // Start loading state
      setError(null); // Reset any previous errors
      console.log(responseData);
      // POST request to backend API
      const res = await axios.post(
        `${apiUrl}/api/rsvp-response/`,
        responseData
      );
      // Success handling
      setSuccess(true);
      setLoading(false);
    } catch (err) {
      setError('There was an error submitting the RSVP. Please try again.');
      setLoading(false);
    }
  };

  // Handle RSVP count changes
  const handleRSVPChange = (change) => {
    let newAttending = formData.attending + change;
    if (newAttending < 0) newAttending = 0;
    if (newAttending < 1) setStep(0);

    let newguestsInfo = [...formData.guestsInfo];
    if (change > 0) {
      setStep(1);
      if (newAttending > 1) {
        for (let i = 0; i < change; i++) {
          console.log(newAttending);
          newguestsInfo.push({ firstName: '', lastName: '', email: '' });
        }
      }
    } else if (change < 0 && newAttending > 0) {
      newguestsInfo = newguestsInfo.slice(0, newAttending);
    }

    setFormData({
      ...formData,
      attending: newAttending,
      guestsInfo: newguestsInfo,
    });
  };

  // Handle attendee info input changes
  const handleAttendeeInfoChange = (index, field, value) => {
    const newguestsInfo = [...formData.guestsInfo];
    newguestsInfo[index] = {
      ...newguestsInfo[index],
      [field]: value,
    };
    setFormData({
      ...formData,
      guestsInfo: newguestsInfo,
    });
  };

  // Handle "Can't Make It" info input changes
  const handleCantMakeItInfoChange = (field, value) => {
    setFormData({
      ...formData,
      cantMakeItInfo: {
        ...formData.cantMakeItInfo,
        [field]: value,
      },
    });
  };

  // Handle toggle for attending count
  const handleToggleNotAttending = (isCantMake) => {
    setFormData({
      ...formData,
      notAttending: !formData.notAttending,
    });
    // setStep(1);
    if (!isCantMake) {
      setStep(1);
    } else {
      setStep(0);
    }
  };

  // Step navigation handlers
  const handleContinue = () => {
    const guestsInfo = formData.guestsInfo;
    const firstGuestValid =
      guestsInfo[0].firstName && guestsInfo[0].lastName && guestsInfo[0].email;
    const restGuestsValid = guestsInfo.every(
      (info) => info.firstName && info.lastName
    );

    // If the first guest is valid and all others are valid or not attending is true, proceed
    if ((firstGuestValid && restGuestsValid) || formData.notAttending) {
      setStep(2);
    } else {
      console.log('Validation failed');
    }
  };

  const handleBack = () => {
    setStep(1); // Go back to user info step
  };

  // Render attendee info inputs
  const renderAttendeeInfoInputs = () => {
    return (
      <>
        {formData.guestsInfo.map((info, index) => (
          <div key={index} className="flex gap-1 mb-2 ">
            <input
              className="p-2 w-1/2  border rounded mr-4"
              type="text"
              placeholder="First Name"
              required
              value={info.firstName || ''}
              onChange={(e) =>
                handleAttendeeInfoChange(index, 'firstName', e.target.value)
              }
            />
            <input
              className="p-2 w-1/2 border rounded"
              type="text"
              placeholder="Last Name"
              required
              value={info.lastName || ''}
              onChange={(e) =>
                handleAttendeeInfoChange(index, 'lastName', e.target.value)
              }
            />
          </div>
        ))}

        {/* Single email input for all attendees */}
        <div className="mt-8 space-y-2 mb-4">
          <input
            className="p-2 border w-full rounded"
            type="email"
            placeholder="Email"
            required
            value={formData.guestsInfo[0]?.email || ''}
            onChange={(e) => {
              const newEmail = e.target.value;
              const updatedguestsInfo = formData.guestsInfo.map((attendee) => ({
                ...attendee,
                email: newEmail,
              }));
              setFormData({
                ...formData,
                guestsInfo: updatedguestsInfo,
              });
            }}
          />
        </div>
      </>
    );
  };

  // Render "Can't Make It" info inputs
  const renderCantMakeItInfoInputs = () => {
    return (
      <div className="flex flex-col space-y-2 mb-4">
        <input
          className="p-2 border rounded"
          type="text"
          placeholder="First Name"
          value={formData.cantMakeItInfo.firstName}
          onChange={(e) =>
            handleCantMakeItInfoChange('firstName', e.target.value)
          }
        />
        <input
          className="p-2 border rounded"
          type="text"
          placeholder="Last Name"
          value={formData.cantMakeItInfo.lastName}
          onChange={(e) =>
            handleCantMakeItInfoChange('lastName', e.target.value)
          }
        />
        <input
          className="p-2 border mt-8 rounded"
          type="email"
          placeholder="Email"
          value={formData.cantMakeItInfo.email}
          onChange={(e) => handleCantMakeItInfoChange('email', e.target.value)}
        />
      </div>
    );
  };
  return (
    <div className="fixed top-0 right-0 bottm-0 bg-opacity-50 w-[40%] h-screen overflow-y-scroll bg-sky-100 p-4 pt-10">
      {success ? (
        formData.notAttending ? (
          <div className="mt-20 flex flex-col justify-center items-center text-center bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative">
            <h2 className="text-xl font-semibold mb-2">
              RSVP Submitted: Unable to Attend
            </h2>
            <p className="mb-4">
              We're sorry to hear that you won't be able to join us. Your RSVP
              has been recorded, and we truly appreciate you letting us know in
              advance.
            </p>
            <p className="mb-4 text-center font-bold">Thank you!</p>
          </div>
        ) : (
          <div className="mt-20 flex flex-col justify-center items-center text-center bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative">
            <h2 className="text-xl font-semibold mb-2">
              RSVP Submitted Successfully!
            </h2>
            <p className="mb-4">
              We are thrilled to know that you'll be joining us. Your RSVP has
              been successfully recorded.
            </p>
            <p className="mb-4">
              A confirmation email has been sent to{' '}
              <strong>{formData.guestsInfo[0]?.email}</strong>,Please check your
              email!
            </p>
            <p className="mb-4 text-center font-bold">Thank you!</p>
          </div>
        )
      ) : (
        <div>
          <h3 className="text-lg mb-10 font-bold text-gray-800 text-center">
            RSVP Registration
          </h3>
          {/* step0 */}
          <div className="flex flex-col space-y-4 mb-10">
            <div className="flex items-center justify-between">
              <span className="text-gray-800">Attending</span>
              <div className="flex items-center space-x-2 text-gray-800">
                <button
                  onClick={() => handleRSVPChange(-1)}
                  disabled={formData.notAttending}
                  className="px-2 rounded-full border border-gray-800 "
                >
                  -
                </button>
                <div className="w-8 h-8 border border-gray-800 rounded flex items-center justify-center">
                  {formData.attending}
                </div>
                <button
                  onClick={() => handleRSVPChange(1)}
                  disabled={formData.notAttending}
                  className="px-2 border border-gray-800 rounded-full"
                >
                  +
                </button>
              </div>
            </div>

            {formData.attending < 1 && (
              <div className="flex items-center justify-between">
                <span className="text-gray-800">Can't Make It</span>
                <button
                  onClick={() =>
                    handleToggleNotAttending(formData.notAttending)
                  }
                  disabled={formData.attending > 0}
                  className={`p-4 border border-gray-800 rounded mr-9 ${
                    formData.notAttending
                      ? 'p-0 px-2 py-2 text-sm text-gray-800'
                      : ''
                  }`}
                >
                  {formData.notAttending ? '✔' : ''}
                </button>
              </div>
            )}
          </div>
          {/* step1 */}
          {step === 1 && (
            <div>
              {!formData.notAttending ? (
                <>
                  {renderAttendeeInfoInputs()}

                  {formData.attending > 0 && (
                    <div className="flex items-center justify-between">
                      <span className="text-gray-800">Can't Make It</span>
                      <button
                        onClick={() =>
                          handleToggleNotAttending(formData.notAttending)
                        }
                        disabled={formData.attending > 0}
                        className={`p-4 border border-gray-800 rounded mr-9 ${
                          formData.notAttending
                            ? 'p-0 px-2 py-2 text-sm text-gray-800'
                            : ''
                        }`}
                      >
                        {formData.notAttending ? '✔' : ''}
                      </button>
                    </div>
                  )}
                </>
              ) : (
                renderCantMakeItInfoInputs()
              )}
              <div className="flex w-full justify-center">
                <button
                  onClick={handleContinue}
                  className="mt-4 bg-white text-black p-2 rounded"
                >
                  Continue
                </button>
              </div>
            </div>
          )}
          {/* step2 */}

          {step === 2 && (
            <div>
              {!formData.notAttending ? (
                <>
                  <h2 className="text-center mb-2">Meal Preference</h2>
                  {formData.guestsInfo.map((guest, index) => (
                    <div key={index} className="mb-4">
                      <h4 className="text-gray-800 mb-2">
                        {guest.firstName} {guest.lastName}
                      </h4>
                      <div className="flex flex-col mb-4">
                        <select
                          value={guest.food_preference}
                          onChange={(e) =>
                            handleAttendeeInfoChange(
                              index,
                              'food_preference',
                              e.target.value
                            )
                          }
                          className="p-2 border rounded"
                        >
                          <option value="">Select your preference</option>
                          {Food_preference.map((preference) => (
                            <option key={preference.id} value={preference.name}>
                              {preference.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  ))}

                  <div className="flex justify-between">
                    <button
                      onClick={handleBack}
                      className="mr-2 bg-gray-500 text-black p-2 rounded"
                      disabled={loading} // Disable button during loading
                    >
                      Back
                    </button>
                    <button
                      onClick={handleSubmit} // Submit the form
                      className="bg-gray-800 text-white p-2 rounded"
                    >
                      {loading ? 'Submitting...' : 'Submit'}
                    </button>
                    {error && <div className="text-red-500 mt-4">{error}</div>}
                    {success && (
                      <div className="text-green-500 mt-4">{success}</div>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div className="flex flex-col space-y-2">
                    <label className="text-gray-800">
                      Would you like to include a note?
                    </label>
                    <input
                      className="p-2 border rounded"
                      type="text"
                      placeholder="Reason for not attending"
                      value={formData.cantMakeItInfo.whyCant || ''}
                      onChange={(e) =>
                        handleCantMakeItInfoChange('whyCant', e.target.value)
                      }
                    />
                  </div>
                  {error && <div className="text-red-500 mt-4">{error}</div>}

                  <div className="flex justify-between mt-4">
                    <button
                      onClick={handleBack}
                      className="mr-2 bg-gray-500 text-white p-2 rounded"
                    >
                      Back
                    </button>
                    <button
                      onClick={handleSubmit}
                      className="bg-gray-800 text-white p-2 rounded"
                    >
                      {loading ? 'Submitting...' : 'Submit'}
                    </button>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default RSVPForm;
