import { not_found_img } from "../../assets";
import { cn } from "../../lib/basic";

export default function NotFound({message, img = {
    src: not_found_img,
    alt: "Not Found",
    imgClassName: "",
}, className}){
    return (
    <div className={cn("flex flex-col justify-center gap-4 items-center w-full mx-auto")}>
        <img src={img.src} alt={img.alt} className={cn("object-cover max-w-xs", img.imgClassName)}/>
        <p className={cn("text-neutral-600 font-semibold text-center text-lg", className)}>{message}</p>
    </div>
    )
}