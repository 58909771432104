import React, { useEffect, useState } from 'react';
import MiniHeader from '../components/common/MiniHeader';
import Footer from '../components/common/Footer';
import { IoMdPerson, IoIosNotificationsOutline } from 'react-icons/io';
import { selectUser } from '../redux/reducers/authReducer';
import { useSelector } from 'react-redux';
import { apiUrl } from '../utils/const';
import axios from 'axios';
import {
  getAuth,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from 'firebase/auth';
import AccountSection from '../components/user/AccountEdit';
// import Billing from '../components/Billing';

const sampleNotifications = [
  {
    id: 1,
    eventName: 'Event 1',
    eventDate: '2024-07-08',
    new: true,
    edit: true,
    cancellation: true,
    display: true,
  },
  {
    id: 2,
    eventName: 'Event 2',
    eventDate: '2024-08-12',
    new: true,
    edit: true,
    cancellation: true,
    display: true,
  },
  // Add more sample data as needed
];

const MyAccountPage = () => {
  const user = useSelector(selectUser);
  const [activeTab, setActiveTab] = useState('account');
  const [expandedRows, setExpandedRows] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userProfile, setUserProfile] = useState({});
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  useEffect(() => {
    fetchUser(user.uid);
  }, [user]);

  const fetchUser = async (userId) => {
    setIsLoading(true);
    setError(null);
    try {
      const userResponse = await axios.get(`${apiUrl}/api/users/${userId}`);
      setUserProfile(userResponse.data);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Failed to load data. Please try again later.');
      setIsLoading(false);
    }
  };
  const toggleRow = (id, type) => {
    setExpandedRows((prev) => ({
      ...prev,
      [id]: {
        ...prev[id],
        [type]: !prev[id]?.[type],
      },
    }));
  };

  const handlePasswordChange = async () => {
    if (newPassword !== confirmNewPassword) {
      setError('New passwords do not match.');
      return;
    }
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      setError('No authenticated user found.');
      return;
    }

    try {
      const credential = EmailAuthProvider.credential(
        user.email,
        currentPassword
      );
      await reauthenticateWithCredential(user, credential);
      await updatePassword(user, newPassword);
      setError(null);
      alert('Password updated successfully.');
    } catch (error) {
      console.error('Error updating password:', error);
      setError('Failed to update password. Please try again later.');
    }
  };

  const renderNotifications = () => {
    return (
      <div>
        <h2 className="text-xl font-bold mb-4">Notifications</h2>
        <table className="min-w-full bg-white">
          <thead>
            <tr>
              <th className="py-2 px-4 text-3xl text-start">
                <IoIosNotificationsOutline />
              </th>
              <th className="py-2 px-4 text-start">Event Name</th>
              <th className="py-2 px-4 text-start">Event Date</th>
              <th className="py-2 px-4 text-start">New</th>
              <th className="py-2 px-4 text-start">Edit</th>
              <th className="py-2 px-4 text-start">Cancellation</th>
            </tr>
          </thead>
          <tbody>
            {sampleNotifications.map((notification) => (
              <tr key={notification.id}>
                <td className="border px-4 py-2">
                  <button onClick={() => toggleRow(notification.id, 'display')}>
                    <input type="checkbox"/>
                  </button>
                </td>
                <td className="border px-4 py-2">{notification.eventName}</td>
                <td className="border px-4 py-2">{notification.eventDate}</td>
                <td className="border px-4 py-2">
                  <button onClick={() => toggleRow(notification.id, 'new')}>
                    <input type='checkbox'/>
                  </button>
                </td>
                <td className="border px-4 py-2">
                  <button onClick={() => toggleRow(notification.id, 'edit')}>
                    <input type='checkbox'/>
                  </button>
                </td>
                <td className="border px-4 py-2">
                  <button
                    onClick={() => toggleRow(notification.id, 'cancellation')}
                  >
                    <input type='checkbox'/>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const handleSave = (fullName, email) => {
    // Update user information logic here
    setUserProfile({ displayName: fullName, email: email });
    console.log('User information saved:', fullName, email);
  };

  const handleCancel = () => {
    // Reset logic or any other cancel logic if needed
    console.log('Edit cancelled');
  };

  const renderContent = () => {
    switch (activeTab) {
      case 'account':
        return (
          <AccountSection
            user={user}
            handleSave={handleSave}
            handleCancel={handleCancel}
          />
        );
      case 'security':
        return (
          <div>
            <h2 className="text-xl font-bold mb-4">Account Security</h2>
            <form onSubmit={handlePasswordChange}>
              <div className="mb-4">
                <label className="block text-gray-600 text-sm font-bold mb-1">
                  Current Password
                </label>
                <input
                  type="password"
                  value={currentPassword}
                  placeholder='Current Password'
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  className="shadow appearance-none placeholder:text-sm border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-brandGreen/40 focus:ring focus:shadow-outline"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-600 text-sm font-bold mb-1">
                  New Password
                </label>
                <input
                  type="password"
                  value={newPassword}
                  placeholder="New Password"
                  onChange={(e) => setNewPassword(e.target.value)}
                  className="shadow appearance-none placeholder:text-sm border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-brandGreen/40 focus:ring focus:shadow-outline"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-600 text-sm font-bold mb-1">
                  Confirm Password
                </label>
                <input
                  type="password"
                  value={confirmNewPassword}
                  onChange={(e) => setConfirmNewPassword(e.target.value)}
                  placeholder="Confirm New Password"
                  className="shadow appearance-none placeholder:text-sm border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:ring-brandGreen/40 focus:ring "
                />
              </div>
              {error && <p className="text-red-500 text-xs italic">{error}</p>}
              <div className="flex gap-6 mt-6">
                <button
                  type="button"
                  onClick={handlePasswordChange}
                  className="bg-brandGreen text-white px-6 py-2 rounded-md hover:bg-green-700"
                >
                  Change Password
                </button>
              </div>
            </form>
          </div>
        );
      case 'notifications':
        return renderNotifications();

      // case 'billing':
      //   return <Billing />;
      default:
        return null;
    }
  };

  return (
    <div className="px-6 lg:px-10">
      <MiniHeader dashboard={true} />
      <div className="max-w-4xl mx-auto my-10">
        <div className="flex justify-between border-b border-gray-300 mb-6">
          <button
            className={`px-4 py-2 ${
              activeTab === 'account'
                ? 'text-green-600 border-b-2 border-green-600'
                : 'text-gray-600'
            }`}
            onClick={() => setActiveTab('account')}
          >
            My Account
          </button>
          <button
            className={`px-4 py-2 ${
              activeTab === 'security'
                ? 'text-green-600 border-b-2 border-green-600'
                : 'text-gray-600'
            }`}
            onClick={() => setActiveTab('security')}
          >
            Security
          </button>
          <button
            className={`px-4 py-2 ${
              activeTab === 'notifications'
                ? 'text-green-600 border-b-2 border-green-600'
                : 'text-gray-600'
            }`}
            onClick={() => setActiveTab('notifications')}
          >
            Notifications
          </button>
          {
            /*
                        <button
                  className={`px-4 py-2 ${
                    activeTab === 'billing'
                      ? 'text-green-600 border-b-2 border-green-600'
                      : 'text-gray-600'
                  }`}
                  onClick={() => setActiveTab('billing')}
                >
                  Billing
                </button>
             */
          }
        </div>
        {renderContent()}
      </div>
      <Footer />
    </div>
  );
};

export default MyAccountPage;
