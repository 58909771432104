// const apiUrl = 'https://nowinvite-server.onrender.com';

const NODE_ENV = process.env.REACT_APP_NODE_ENV;

const apiUrl =
  NODE_ENV === "production"
    ? "https://www.nowinvite.com"
    : "http://localhost:5000";

const protected_routes = [
  "/dashboard",
  "/templates/:theme-Id",
  "/my-account",
  "/rsvp-detail",
];

export const SUPER_ADMIN = "SUPER_ADMIN";
export const ADMIN = "ADMIN";
export const USER = "USER";

export { apiUrl, protected_routes, NODE_ENV };
