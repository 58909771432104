import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, Link } from "react-router-dom";
import axios from "axios";
import Header from "../components/common/Header";
import Footer from "../components/common/Footer";
import loginImg from "../assets/images/login.png";
import { auth } from "../config/firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { FaUser, FaEye, FaEyeSlash } from "react-icons/fa";
import { loginSuccess, selectUser } from "../redux/reducers/authReducer";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { apiUrl } from "../utils/const";
import PageContainer from "../components/containers/PageContainer";
function SignInPage() {
  const user = useSelector(selectUser);
  const navigate = useNavigate();

  if (user?.displayName || user?.uid) {
    navigate("/dashboard");
  }

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    const { email, password } = formData;

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );

      // Get the ID token from the authenticated user
      const token = await userCredential.user.getIdToken();
      const user = {
        uid: userCredential.user.uid,
        email: userCredential.user.email,
        displayName: userCredential.user.displayName,
      };

      // Fetch user info to check for events
      const response = await axios.get(`${apiUrl}/api/users/${user.uid}`);

      // Add role to user object
      user.role = response?.data?.role;
      
      dispatch(loginSuccess({ user, token }));

      const events = response?.data?.events;

      if (events && events.length > 0) {
        navigate("/dashboard");
      } else {
        navigate("/");
      }
    } catch (error) {
      console.error("Error:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
    setTimeout(() => {
      setShowPassword(false);
    }, 4000);
  };

  return (
    <PageContainer>
      <Header link={"Login/Sign-up"} />
      <div className="w-full bg-gray-200 px-2 lg:p-0 py-4">
        <div className="flex items-center my-8 md:my-4 justify-center md:p-6 rounded-lg gap-16 ">
          <img
            src={loginImg}
            alt="login"
            className="h-[30rem] hidden md:block"
          />
          <div className="w-full max-w-sm flex flex-col items-center gap-4 bg-gray-50 py-6 md:rounded-xl px-3">
            <h2 className="text-2xl font-bold">Login</h2>
            {error && <p className="text-red-500">{error}</p>}
            <form onSubmit={handleSubmit}>
              <div className="mb-4 relative">
                <label htmlFor="email" className="block text-sm text-gray-600">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Enter your email"
                  className="w-full px-3 py-2 rounded-lg placeholder:text-sm border focus:outline-none focus:ring-brandGreen/40 focus:ring pr-10"
                  required
                />
                <FaUser className="absolute right-3 top-9 text-gray-500" />
              </div>
              <div className="mb-4 relative">
                <label
                  htmlFor="password"
                  className="block text-sm text-gray-600"
                >
                  Password
                </label>
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  id="password"
                  value={formData.password}
                  onChange={handleChange}
                  placeholder="Enter your password"
                  className="w-full px-3 py-2 border rounded-lg placeholder:text-sm focus:outline-none focus:ring-brandGreen/40 focus:ring pr-10"
                  required
                />
                {showPassword ? (
                  <FaEyeSlash
                    className="absolute right-3 top-9 text-gray-500 cursor-pointer"
                    onClick={togglePasswordVisibility}
                  />
                ) : (
                  <FaEye
                    className="absolute right-3 top-9 text-gray-500 cursor-pointer"
                    onClick={togglePasswordVisibility}
                  />
                )}
              </div>
              <div className="w-full text-right mb-4">
                <Link
                  to="/forgot-password"
                  className="text-sm text-blue-500 hover:underline"
                >
                  Forgot Password?
                </Link>
              </div>
              <button
                type="submit"
                className="w-full bg-brandGreen text-white py-2 rounded-lg hover:bg-brandGreen/80 focus:outline-none"
                disabled={loading}
              >
                {loading ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : (
                  "Sign-in"
                )}
              </button>
            </form>
            <div className="flex items-center gap-1">
              <p className="text-sm">Don't have an account?</p>
              <button
                onClick={() => navigate("/sign-up")}
                className="cursor-pointer text-sm text-blue-400 underline focus:outline-none"
              >
                Sign Up
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </PageContainer>
  );
}

export default SignInPage;
