import React from 'react'
import Header from '../components/common/Header';
import Footer from '../components/common/Footer';
import Categories from '../components/Categories/Categories';
import introPhoto from '../assets/images/RSVP/introImage.png'
import { Image, Shimmer } from 'react-shimmer'
import PageContainer from '../components/containers/PageContainer';

const Home = () => {
  return (
    <PageContainer>
      <div className="">
      <Header link={'RSVP'}/>
        {/* <Image
        src={introPhoto} 
        className="lg:my-10 !w-full h-auto max-h-[calc(100vh-4rem)] sm:max-h-[calc(100vh-6rem)] md:max-h-[calc(100vh-8rem)] lg:max-h-[calc(100vh-10rem)] xl:max-h-[calc(100vh-12rem)] object-cover rounded-xl opacity-100" 
        fallback={<Shimmer width={800} height={400} />} 
        /> */}
        <img
        src={introPhoto} 
        className="lg:my-10 w-full h-auto max-h-[calc(100vh-4rem)] sm:max-h-[calc(100vh-6rem)] md:max-h-[calc(100vh-8rem)] lg:max-h-[calc(100vh-10rem)] xl:max-h-[calc(100vh-12rem)] object-cover rounded-xl opacity-100" 
        />
      </div>
      <Categories/>
      <Footer/>
    </PageContainer>
  )
}

export default Home
