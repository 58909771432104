import React from "react";
import {
  // selectIsLoggedIn,
  selectUser,
  logoutSuccess,
} from "../../redux/reducers/authReducer";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Hlogo from "../../assets/images/Hlogo.png";
// import ProfileImg from '../../assets/images/proflie.jpg';
// import { IoIosArrowDown } from 'react-icons/io';
import CustomPopup from "../UI/ProfilePopUP"; // Import the new CustomPopup component
// import { MdContactSupport } from 'react-icons/md';
import { FaCircleUser } from "react-icons/fa6";
import { IoIosLogOut } from "react-icons/io";
import { UserCircleIcon } from "@heroicons/react/24/outline";
import { CalendarHeart, HomeIcon } from "lucide-react";
import { cn } from "../../lib/basic";

function MiniHeader({ dashboard }) {
  const user = useSelector(selectUser);
  const { event } = useSelector((state) => state.event);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = () => {
    dispatch(logoutSuccess());
    navigate("/");
  };

  return (
    <div>
      <nav
        className={cn(
          !dashboard
            ? "ml-[20rem] justify-end "
            : "mx-auto justify-start shadow"
        ,
       "bg-white  flex items-center py-6 px-4 sm:px-6 lg:px-8", !location.pathname.includes("events/create") && " justify-between")}
       aria-label="Global"
      >
        {dashboard && (
          <div className="flex lg:flex-1">
            <Link href="/dashboard" className="-m-1.5 p-1.5 flex items-center">
              <span className="sr-only">NowInvite</span>
              <img
                className="h-8 max-w-[230px] md:max-w-auto"
                src={Hlogo}
                alt="company logo"
              />
            </Link>
          </div>
        )}

        {!dashboard && !location.pathname.includes("events/create") && (
          <div className="text-neutral-600 flex items-center gap-2">
            <HomeIcon />{" "}
            <Link
              to="/dashboard"
              className="font-semibold hover:text-brandGreen/70 cursor-pointer"
            >
              More Events
            </Link>
          </div>
        )}

        <div className="flex items-center gap-6">
          {/* <Link to={'/billing'}>
            <p className="text-brandGreen font-medium hover:text-gray-900 cursor-pointer">
              Upgrade
            </p>
          </Link> */}
          {!!event && !dashboard && !location.pathname.includes("events/create") && (
            <div>
              <p className="flex items-center gap-2">
                <CalendarHeart color="#4B5563" />
                <span className="font-semibold text-neutral-600">
                  {event.eventName}
                </span>
              </p>
            </div>
          )}
          {!user?.isLoggedIn ? (
            <div className="flex items-center gap-2">
              <CustomPopup
                triggerContent={
                  <>
                    <UserCircleIcon
                      className="h-6 w-6 mr-1 text-yellow-500"
                      aria-hidden="true"
                    />
                    {user.displayName}
                  </>
                }
              >
                <button
                  onClick={() => navigate("/my-account")}
                  className=" w-full text-left mb-2  text-brandGreen flex gap-1 items-center"
                >
                  <FaCircleUser
                    className="h-5 w-5 mr-2 text-yellow-500"
                    aria-hidden="true"
                  />
                  My Account
                </button>
                {/* <button
                  onClick={() => navigate('/support')}
                  className=" w-full text-left text-brandGreen mb-2 gap-1 flex items-center"
                >
                  <MdContactSupport
                    className="h-5 w-5 mr-2 text-yellow-500"
                    aria-hidden="true"
                  />
                  Support
                </button> */}
                <button
                  onClick={handleLogout}
                  className=" w-full text-left text-brandGreen gap-1 flex items-center"
                >
                  <IoIosLogOut
                    className="h-5 w-5 mr-2 text-yellow-500"
                    aria-hidden="true"
                  />
                  Sign Out
                </button>
              </CustomPopup>
            </div>
          ) : null}
        </div>
      </nav>
    </div>
  );
}

export default MiniHeader;
