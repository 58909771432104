import { useState } from "react";
import {
  BarChart,
  Bar,
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
} from "recharts";
import {
  Search,
  Calendar,
  Users,
  Crown,
  CircleUser,
  BadgeDollarSign,
  Activity,
} from "lucide-react";
import AdminSidebar from "../components/DashboardSections/AdminSidebar";

const monthlyData = [
  { name: "Jan", value: 30 },
  { name: "Feb", value: 45 },
  { name: "Mar", value: 28 },
  { name: "Apr", value: 35 },
  { name: "May", value: 48 },
  { name: "Jun", value: 25 },
  { name: "Jul", value: 38 },
  { name: "Aug", value: 42 },
  { name: "Sep", value: 45 },
  { name: "Oct", value: 50 },
  { name: "Nov", value: 35 },
  { name: "Dec", value: 40 },
];

const pieData = [
  { name: "Complete", value: 60, color: "#4ade80" },
  { name: "Pending", value: 25, color: "#60a5fa" },
  { name: "Cancelled", value: 15, color: "#f87171" },
];

const recentActivity = [
  {
    id: 1,
    type: "rsvp",
    message: "You have received a new RSVP from John Doe",
    time: "Today 3:35 AM",
  },
  {
    id: 2,
    type: "event",
    message: 'You have added a new Event titled "Team Meeting"',
    time: "Today 3:35 AM",
  },
  {
    id: 3,
    type: "rsvp",
    message: "You have received a new RSVP from Jane Smith",
    time: "Today 3:35 AM",
  },
  {
    id: 4,
    type: "event",
    message: 'You have added a new Event titled "Product Launch"',
    time: "Today 3:35 AM",
  },
];

export default function AdminDashboard() {
  const [selectedPeriod, setSelectedPeriod] = useState("Weekly");

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Sidebar */}
      <AdminSidebar />

      {/* Main Content */}
      <main className="ml-64 p-8">
        {/* Header */}
        <header className="mb-8 flex items-center justify-between">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 h-5 w-5 -translate-y-1/2 text-gray-400" />
            <input
              type="text"
              placeholder="Search"
              className="w-80 font-light rounded-lg border border-gray-200 py-2 pl-10 pr-4 focus:border-green-500 focus:outline-none"
            />
          </div>

          <div className="flex items-center gap-4">
            <button className="flex items-center  gap-x-2 rounded-lg border border-gray-200 px-4 py-2 text-sm font-medium text-gray-600">
              <Crown className="h-7 w-5 text-yellow-500" />
              Upgrade
            </button>
            <div className="flex items-center gap-2">
              <CircleUser size={48} />
              <div>
                <p className="font-normal">Biruk Habtamu</p>
                <p className="text-sm text-gray-500">b.uk@gmail.com</p>
              </div>
            </div>
          </div>
        </header>

        {/* Dashboard Content */}
        <div className="mb-6">
          <h1 className="text-2xl font-bold">Dashboard</h1>
          <p className="text-gray-500">
            View overall details about events and lorem ipsum
          </p>
        </div>

        <div className="mb-8 grid grid-cols-4 gap-6">
          {/* Welcome Section */}
          <div className="mb-8 flex items-center gap-4">
            {/* <img
              src="/placeholder.svg?height=64&width=64"
              alt="Welcome"
              className="h-16 w-16 rounded-full"
            /> */}
            <div className="flex flex-col m-auto items-center justify-center">
              <p className="text-gray-500">Welcome Back,</p>
              <p className="text-xl text-yellow-500 font-semibold">Dave 👋</p>
            </div>
          </div>
          {/* Stats */}
          {[
            {
              label: "Total Events",
              value: "24",
              trend: "+1%",
              icon: Calendar,
              className: "text-blue-500",
            },
            {
              label: "Total Revenue",
              value: "2000$",
              trend: "+1%",
              icon: BadgeDollarSign,
              className: "text-yellow-500",
            },
            {
              label: "Users",
              value: "24",
              trend: "+1%",
              icon: CircleUser,
              className: "text-green-500",
            },
          ].map((stat) => (
            <div
              key={stat.label}
              className="rounded-xl border border-gray-200 bg-white p-4"
            >
              <div className="flex justify-between">
                <div className="flex gap-x-3">
                  <stat.icon className={stat.className} />
                  <p className="mb-2 text-gray-500">{stat.label}</p>
                </div>
                <div className="flex">
                  <Activity
                    size={16}
                    className="flex items-center text-green-400"
                  />
                  <span className="text-sm text-green-500">{stat.trend}</span>
                </div>
              </div>
              <div className="flex py-2 items-center justify-center">
                <p className="text-2xl font-bold">{stat.value}</p>
              </div>
            </div>
          ))}
        </div>

        {/* Charts */}
        <div className="mb-8 grid grid-cols-3 gap-6">
          <div className="col-span-2 rounded-xl border border-gray-200 bg-white p-6">
            <div className="mb-4 flex items-center justify-between">
              <h3 className="font-semibold">User Engagement</h3>
              <select
                value={selectedPeriod}
                onChange={(e) => setSelectedPeriod(e.target.value)}
                className="rounded-lg border border-gray-200 px-3 py-2 text-sm"
              >
                <option>Weekly</option>
                <option>Monthly</option>
                <option>Yearly</option>
              </select>
            </div>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={monthlyData}>
                <Bar dataKey="value" fill="#4ade80" radius={[1, 4, 0, 0]} />
              </BarChart>
            </ResponsiveContainer>
          </div>

          <div className="rounded-xl border border-gray-200 bg-white p-6">
            <h3 className="mb-4 font-semibold">Distribution</h3>
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={pieData}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  innerRadius={60}
                  outerRadius={80}
                >
                  {pieData.map((entry, index) => (
                    <Cell key={index} fill={entry.color} />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Recent Activity */}
        <div className="rounded-xl border border-gray-200 bg-white p-6">
          <div className="mb-4 flex items-center justify-between">
            <h3 className="font-semibold">Recent Activity</h3>
            <button className="text-sm text-green-600">View All</button>
          </div>
          <div className="space-y-4">
            {recentActivity.map((activity) => (
              <div
                key={activity.id}
                className="flex items-center justify-between"
              >
                <div className="flex items-center gap-3">
                  <div
                    className={`rounded-full p-2 ${
                      activity.type === "rsvp" ? "bg-blue-50" : "bg-orange-50"
                    }`}
                  >
                    {activity.type === "rsvp" ? (
                      <Users className="h-5 w-5 text-blue-500" />
                    ) : (
                      <Calendar className="h-5 w-5 text-orange-500" />
                    )}
                  </div>
                  <p className="text-gray-600">{activity.message}</p>
                </div>
                <span className="text-sm text-gray-400">{activity.time}</span>
              </div>
            ))}
          </div>
        </div>
      </main>
    </div>
  );
}
