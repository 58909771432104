import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Toggle from 'react-toggle';
import 'react-toggle/style.css'; // For default styling
import { apiUrl } from '../../../utils/const';

const ContentToggleSidebar = ({ contents, handleToggle }) => {
  return (
    <div className="sidebar">
      <h3>Section Visibility</h3>
      <div className="content-list">
        {contents.map((content) => (
          <div key={content.id} className="content-item">
            <span>{content.type}</span>
            <Toggle
              checked={content.show}
              onChange={() => handleToggle(content.id)}
            />
          </div>
        ))}
      </div>

      {/* Add some basic styling */}
      <style jsx>{`
        .sidebar {
          padding: 20px;
          width: 250px;
          background-color: #f0f0f0;
          border-right: 1px solid #ddd;
        }
        .content-list {
          margin-top: 20px;
        }
        .content-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 0;
          border-bottom: 1px solid #ddd;
        }
      `}</style>
    </div>
  );
};

export default ContentToggleSidebar;
