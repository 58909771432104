import React, { useState } from "react";
import Modal from "react-modal";
import AdminLayout from "../components/common/AdminLayout";
import Hlogo from "../assets/images/Hlogo.png";
import axios from "axios";
import { apiUrl } from "../utils/const";
import { useSelector } from "react-redux";
import { selectToken } from "../redux/reducers/authReducer";
import Toast from "../components/UI/toast";
import CustomButton from "../components/Button/Buttons";

const Partners = () => {
  const [partners, setPartners] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const partnersPerPage = 5;

  const [partnerName, setPartnerName] = useState();
  const [partnerLogo, setPartnerLogo] = useState();
  const token = useSelector(selectToken);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });

  const handleImageChange = (e) => {
    const selectedFiles = Array.from(e.target.files);

    // Disable any existing alert
    setAlert({ show: false, message: "", type: "" });

    // Validate file types and size
    const validImageFiles = selectedFiles.filter((file) => {
      // Check file type
      if (!file.type.startsWith("image/")) {
        setAlert({
          show: true,
          message: "Please upload an image file",
          type: "error",
        });
        return false;
      }

      // Check file size (adjust as needed, backend middleware likely has its own size check)
      if (file.size > 5 * 1024 * 1024) {
        // 5MB limit
        setAlert({
          show: true,
          message: "Please upload an image file smaller than 5MB",
          type: "error",
        });
        return false;
      }

      return true;
    });

    // Set images for upload
    setPartnerLogo(validImageFiles);
  };

  const handleInputChange = (e) => {
    setPartnerName(e.target.value);
  };

  const indexOfLastPartner = currentPage * partnersPerPage;
  const indexOfFirstPartner = indexOfLastPartner - partnersPerPage;
  const currentPartners = partners.slice(
    indexOfFirstPartner,
    indexOfLastPartner
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Create FormData
    const formData = new FormData();
    formData.append("partnerName", partnerName);

    // Append the first file from partnerLogo array
    if (partnerLogo && partnerLogo.length > 0) {
      formData.append("partnerLogo", partnerLogo[0]);
    }

    try {
      const response = await axios.post(
        `${apiUrl}/api/partners/add`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data", // Important for file uploads
          },
        }
      );

      // Update partners list with the new partner
      setPartners([...partners, response.data]);

      // Reset form and show success message
      setAlert({
        show: true,
        message: "Partner added successfully",
        type: "success",
      });
      setPartnerName("");
      setPartnerLogo(null);
      setModalIsOpen(false); // Close the modal on successful submission
    } catch (error) {
      // Handle error response
      setAlert({
        show: true,
        message: error.response?.data?.error || "Failed to add partner",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <AdminLayout>
      <div className="container mx-auto">
        <h1 className="text-2xl font-bold mb-4">Partners</h1>
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded mb-4"
          onClick={() => setModalIsOpen(true)}
        >
          Add Partner
        </button>
        <h2 className="text-xl font-bold mb-4 text-center">All Partners</h2>
        <table className="min-w-full bg-white">
          <tbody>
            {currentPartners.map((partner, index) => (
              <tr key={index}>
                <td className="py-2 px-4 border-b">{partner.name}</td>
                <td className="py-2 px-4 border-b">
                  <img src={partner.logo} alt={partner.name} className="h-10" />
                </td>
              </tr>
            ))}
            <tr>
              <td className="py-2 px-4 border-b">FlawLess Events</td>
              <td className="py-2 px-4 border-b">
                <img src={Hlogo} className="" />
              </td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b">Arif Studios</td>
              <td className="py-2 px-4 border-b">
                <img src={Hlogo} className="" />
              </td>
            </tr>
          </tbody>
        </table>
        <div className="flex justify-center mt-4">
          {Array.from(
            { length: Math.ceil(partners.length / partnersPerPage) },
            (_, i) => (
              <button
                key={i}
                onClick={() => paginate(i + 1)}
                className={`px-3 py-1 mx-1 ${
                  currentPage === i + 1
                    ? "bg-blue-500 text-white"
                    : "bg-gray-200"
                }`}
              >
                {i + 1}
              </button>
            )
          )}
        </div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={() => setModalIsOpen(false)}
          className="flex items-center justify-center"
          overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
        >
          <div className="bg-white p-6 rounded shadow-lg w-96 relative">
            <button
              onClick={() => setModalIsOpen(false)}
              className="absolute  top-2 right-2 text-gray-500 hover:text-gray-700"
            >
              &times;
            </button>
            <h2 className="text-xl font-bold mb-4">Add New Partner</h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-gray-700">Name</label>
                <input
                  type="text"
                  name="name"
                  value={partnerName}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border rounded"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">Logo</label>
                <input
                  type="file"
                  onChange={handleImageChange}
                  className="w-full px-3 py-2 border rounded"
                  required
                />
              </div>
              <button
                type="submit"
                className="bg-blue-500 text-white px-4 py-2 rounded"
                onClick={handleSubmit}
              >
                Add Partner
              </button>
            </form>
          </div>
        </Modal>
      </div>
    </AdminLayout>
  );
};

export default Partners;
