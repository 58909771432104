import dayjs from "dayjs";
import { twMerge } from "tailwind-merge";
const localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

export const cn = (...classNames) => {
  return twMerge(...classNames);
};

export const redirect = (to = "", navigate) => {
  navigate(to);
};

export const formatStandardDate = (dateObj) => {
  return dayjs(dateObj).format("YYYY-MM-DD");
};

export const formatTime = (dateStr) => {
  return dayjs(dateStr).format("LTS");
};
