import axios from "axios";
import { apiUrl } from "../utils/const";
import { Error } from "../utils/alert";

const uploadInviteeFile = async (event_id, data) => {
  try {
    const formData = new FormData();
    formData.append("file", data);

    const response = await axios.post(
      apiUrl + `/api/invitations/${event_id}/upload-invitees`,
      formData
    );

    return response;
  } catch (error) {
    Error({
      message: error?.response?.data?.error,
      title: "Failed Uploading Invitee File",
    });
    console.error(error);
  }
};

const fetchInviteeList = async (event_id) => {
  return await axios.get(apiUrl + `/api/invitations/${event_id}/invitees`);
};

const addInvitee = async (event_id, data) => {
  return await axios.post(
    apiUrl + `/api/invitations/${event_id}/invitees`,
    data
  );
};

const removeInvitee = async (event_id, data) => {
  return await axios.delete(apiUrl + `/api/invitations/${event_id}/invitees`, {
    data,
  });
};

const updateInvitee = async (event_id, data) => {
  return await axios.put(
    apiUrl + `/api/invitations/${event_id}/invitees`,
    data
  );
};

const exportInviteeList = async (event_id, format = "csv") => {
  return await axios.get(
    apiUrl + `/api/invitations/${event_id}/export?format=${format}`,
    {
      responseType: "blob",
    }
  );
};

const downloadInviteeListTemplate = async () => {
  return await axios.get(apiUrl + `/api/invitations/template`, {
    responseType: "blob",
  });
};

export {
  uploadInviteeFile,
  fetchInviteeList,
  addInvitee,
  removeInvitee,
  exportInviteeList,
  downloadInviteeListTemplate,
  updateInvitee,
};
