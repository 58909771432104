import React, { useState } from 'react';
import Header from '../components/common/Header';
import Footer from '../components/common/Footer';
import heroBanner from "../assets/images/AboutHero.png";
import AboutUs from '../components/AboutUs';
import { motion } from "framer-motion";
import PageContainer from '../components/containers/PageContainer';
import MainModal from '../components/modal/MainModal';
import { Mail, Phone, Share, Share2Icon } from 'lucide-react';
import { FaAddressCard } from 'react-icons/fa';
import { FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa6';
import CustomButton from '../components/Button/Buttons';

// FRAMER MOTION VARIANT
const variants = {
  hidden: {
    opacity: 0,
    x: -50,
    y: 0,
  },

  visible: {
    opacity: 1,
    x: 0,
    y: 0,

    transition: {
      delay: 0.2,
      duration: 0.8,
      type: "spring",
      stiffness: 50,
    }
  },
}
const AboutUsPage = () => {
  const [contactUsModal, setContactUsModalOpen] = useState(false); 
  return (
    <PageContainer>
      <div>
        <Header link={'About us'} />
        <div className="relative">
          <img
            src={heroBanner}
            alt="party image"
            className="lg:my-10 w-full h-auto max-h-[calc(100vh-4rem)] sm:max-h-[calc(100vh-6rem)] md:max-h-[calc(100vh-8rem)] lg:max-h-[calc(100vh-10rem)] xl:max-h-[calc(100vh-12rem)] object-cover rounded-xl opacity-100"
          />
          <div className="absolute bottom-[10%] left-[15%]">
            <motion.p 
            variants={variants} 
            whileInView={"visible"} 
            initial={"hidden"} 
            viewport={{
              once: true,
            }} 
            className="px-8 py-4 mt-5 cursor-pointer rounded-lg bg-brandGreen text-white" 
            onClick={() => {
              setContactUsModalOpen(true);
            }}>
              Contact Us
            </motion.p>
            <motion.p 
            variants={{...variants, visible: {
              ...variants.visible,
              transition: {
                delay: 0.4,
                duration: 1.4,
                type: "spring",
                stiffness: 50,
              }
            }}} 
            whileInView={"visible"} 
            initial={"hidden"} 
            viewport={{
              once: true,
            }} 
            className="px-8 py-4 mt-5 rounded-lg bg-yellow-500 text-white">
              +1 2027510172
            </motion.p>
          </div>
        </div>
      </div>
      <AboutUs />
      <Footer />

      {
        contactUsModal && <ContactUsModalUI open={contactUsModal} setOpen={setContactUsModalOpen}/>
      }
    </PageContainer>
  );
};

const Icons = [
  {
    Icon: FaFacebook,
  },
  {
    Icon: FaTwitter,
  },
  {
    Icon: FaInstagram
  }
]
const ContactUsModalUI = ({open, setOpen}) => {
  return (
    <MainModal className={"p-5"} isOpen={open} closeModal={() => setOpen(false)}>
      <h3 className='text-4xl text-brandGreen font-semibold'>Contact Us</h3>
      <div className='my-4 bg-neutral-50 px-3 py-4 rounded-md'>
        <div className='flex items-center justify-start gap-3'>
          <Phone className='text-neutral-600' size={18}/>
          <p className='text-sm text-neutral-600'>
            +1 2027510172
          </p>
        </div>

        <hr className='my-4'/> 

        <div className='flex items-center justify-start gap-3'>
          <Mail className='text-neutral-600' size={18}/>
          <p className='text-sm text-neutral-600'>
            support@nowinvite.com
          </p>
        </div>

        <hr className='my-4'/> 

        <div>
          <ul className='flex items-center gap-3 justify-center'>
            {
              Icons.map((Item) => (
                <li key={Item.icon}><Item.Icon size={24} className='text-neutral-600 cursor-pointer'/></li>
              ))
            }
          </ul>
        </div>
      </div>
      
      <div className='flex items-center justify-end gap-4'>
        <CustomButton variant={"ghost"} label="Close" onClick={() => setOpen(false)}/>
        <CustomButton variant={"primary"} label="Share" Icon={Share2Icon}/>
      </div>

    </MainModal>
  )
}

export default AboutUsPage;
