import PropTypes from 'prop-types'
import { cn } from '../../lib/basic'

const PageContainer = ({ children, className }) => {
  return (
    <div className={cn("p-2 md:p-6 lg:px-10 overflow-x-hidden", className)}>
        {children}
    </div>
  )
}

PageContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PageContainer