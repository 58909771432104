import React from 'react';

const EventSettings = () => {
  return (
    <div>
      <h2 className="mb-4">Event Settings</h2>
      {/* Event Settings content goes here */}
    </div>
  );
};

export default EventSettings;
