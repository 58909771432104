import React from 'react';
import { FaCog } from 'react-icons/fa';

function FAQSection({ eventData, handleContentChange, openEditor, isEditing }) {
  const FAQContent = eventData.contents.find((item) => item.type === 'faq');

  // Separate handle functions for question and answer
  const handleQuestionChange = (e, index) => {
    if (isEditing) {
      const updatedFAQ = [...FAQContent.text];
      updatedFAQ[index].question = e.currentTarget.innerText;
      handleContentChange('faq', updatedFAQ); // update the FAQ content with new question
    }
  };

  const handleAnswerChange = (e, index) => {
    if (isEditing) {
      const updatedFAQ = [...FAQContent.text];
      updatedFAQ[index].answer = e.currentTarget.innerText;
      handleContentChange('faq', updatedFAQ); // update the FAQ content with new answer
    }
  };

  return (
    <section
      className=" w-full relative"
      style={{
        backgroundColor: eventData.custom_design.faq_styles.background_color,
        color: eventData.custom_design.faq_styles.color,
        padding: eventData.custom_design.faq_styles.padding,
      }}
    >
      <h2 className="text-2xl font-semibold mb-4">FAQ</h2>
      <ul>
        {FAQContent?.text.map((faq, index) => (
          <li key={index} className="mb-6">
            {/* Question Section */}
            {faq.question && (
              <div
                className={`mb-2 font-bold ${
                  isEditing ? 'px-4 hover:shadow-dashed hover:shadow-white' : ''
                }`}
                contentEditable={isEditing}
                suppressContentEditableWarning={true}
                onBlur={(e) => handleQuestionChange(e, index)} // separate handler for question
              >
                {faq.question}
              </div>
            )}
            {/* Answer Section */}
            {faq.answer && (
              <div
                className={`text-lg ${
                  isEditing ? 'px-4 hover:shadow-dashed hover:shadow-white' : ''
                }`}
                contentEditable={isEditing}
                suppressContentEditableWarning={true}
                onBlur={(e) => handleAnswerChange(e, index)} // separate handler for answer
              >
                {faq.answer}
              </div>
            )}
          </li>
        ))}
      </ul>

      {/* Show setting icon only when in edit mode */}
      {isEditing && (
        <FaCog
          className="absolute text-xl top-4 right-4 cursor-pointer text-black"
          onClick={() => openEditor(FAQContent)}
        />
      )}
    </section>
  );
}

export default FAQSection;
