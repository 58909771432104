import React from 'react';
import Layout from '../components/common/Layout';
import RSVPForm from '../components/DashboardSections/RSVP_form';
import FormBuilder from '../components/DashboardSections/FormBuilder';

function FormBuilderPage() {
  return (
    <Layout>
      {/* <RSVPForm /> */}
      <FormBuilder />
    </Layout>
  );
}

export default FormBuilderPage;
