import React from "react";
import Layout from "../components/common/Layout";
import InviteList from "../components/DashboardSections/InviteList";

const EventEmailPage = () => {
  return (
    <Layout>
      <InviteList />
    </Layout>
  );
};

export default EventEmailPage;
