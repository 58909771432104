import {
  LayoutDashboard,
  Calendar,
  Users,
  RockingChairIcon as ChairOffice,
  ClipboardCheck,
} from "lucide-react";
import Hlogo from "../../assets/images/Hlogo.png";
import { Link, useLocation } from "react-router-dom";
import { cn } from "../../lib/basic";
import { useState } from "react";

export default function AdminSidebar() {
  const [isVisible, setIsVisible] = useState(false);
  const location = useLocation();
  function isCurrentTab(tab) {
    if (isVisible) return;

    if (location.pathname.includes(tab)) {
      return "bg-brandGreen text-white rounded";
    }
  }

  return (
    <aside className="fixed left-0 top-0 h-full w-64 bg-white p-4 shadow">
      <div className="mb-8">
        <img src={Hlogo} alt="Logo" width={200} className="flex items-center" />
      </div>

      <nav className="space-y-2">
        <Link to="/admin-dashboard" className="text-gray-600">
          <button
            className={cn(
              isCurrentTab("admin-dashboard"),
              "flex w-full items-center gap-3 rounded-lg p-3"
            )}
          >
            <LayoutDashboard className="h-5 w-5" />
            <span className="font-medium">Dashboard</span>
          </button>
        </Link>

        {[
          { icon: Calendar, label: "Events" },
          { icon: Users, label: "Partners" },
          { icon: ChairOffice, label: "Seating" },
          { icon: ClipboardCheck, label: "Check-ins" },
        ].map((item) => (
          <Link
            to={`/admin/${item.label.toLowerCase()}`}
            key={item.label}
            className="flex justify-center w-full mx-auto text-gray-600"
          >
            <button
              key={item.label}
              // className="flex w-full items-center gap-3 text-gray-600"
              className={cn(
                isCurrentTab(item.label.toLowerCase()),
                "flex w-full items-center gap-3 rounded-lg p-3"
              )}
            >
              <item.icon className="h-5 w-5" />
              <span>{item.label}</span>
            </button>
          </Link>
        ))}
      </nav>
    </aside>
  );
}
