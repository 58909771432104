import React from 'react';
import { motion } from 'framer-motion';

const variants = {
    hidden: {
        opacity: 0.1,
        y: 100,
    },
    visible: {
        opacity: 1,
        y:0,
        transition: {
            duration: 1.2,
            type: 'spring',
            stiffness: 20,
        }
    }
};

const EventCard = ({ image, date, title, description }) => {
  return (
    <motion.div
    initial="hidden"
    whileInView="visible"
    variants={variants}
    viewport={{ once: true }}
    className="w-full px-2 mb-4">
      <div className="bg-white rounded-lg shadow-md overflow-hidden">
        <img src={image} alt={title} className="object-cover object-center" />
        <div className="p-4">
          <p className="text-gray-600 text-xs md:text-sm flex justify-between">{date}<span>10PM to 12PM</span></p>
          <h3 className="text-xl font-semibold text-gray-800 my-2">{title}</h3>
          <p className="text-gray-700 text-sm">{description}</p>
        </div>
      </div>
    </motion.div>
  );
};

const EventGrid = ({ events }) => {
  return (
      <div className="flex flex-col items-center gap-8 md:mt-0 mt-10">
        <h1 className="text-3xl font-bold mb-4">EVENT SCHEDULE</h1>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {events.map(event => (
            <EventCard
              key={event.id}
              image={event.image}
              date={event.date}
              title={event.title}
              description={event.description}
            />
          ))}
        </div>
        <button className='px-8 py-3 bg-yellow-500 cursor-pointer text-lg rounded-lg text-white font-bold'>View More</button>
    </div>
  );
};

export default EventGrid;
