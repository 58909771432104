import React from 'react';
import { useNavigate } from 'react-router-dom';
import data from './data';
import { motion } from 'framer-motion';

// FRAMER MOTION VARIANTS

const variants = {
  hidden: {
    opacity: 0.1,
    y: 50,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
    },
  },
  hover: {
    y: -10,
    transition: {
      duration: 0.3,
    },
  },
};

function Categories() {
  const navigate = useNavigate();
  const handleDetail = (category) => {
    navigate(`/rsvp-detail?category=${category}`);
  };
  return (
    <div className="mt-14 mb-10">
      <h2 className="text-center font-Fredoka font-semibold text-4xl leading-10">
        Categories
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 my-8">
        {data.map((item, index) => (
          <motion.div
            initial="hidden"
            whileInView="visible"
            variants={variants}
            viewport={{ once: true }}
            whileHover={'hover'}
            onClick={() => handleDetail(`${item.category}`)}
            key={item.id}
            className="p-4 rounded-lg hover:bg-brandGreen/5  cursor-pointer hover:shadow-sm"
          >
            <img
              src={item.image}
              alt={item.title}
              className="h-48 w-full object-cover mb-4 rounded-xl"
            />
            <h3 className="text-xl text-center text-gray-700 font-semibold">
              {item.title}
            </h3>
            <p className="text-base text-gray-500">{item.description}</p>
          </motion.div>
        ))}
      </div>
    </div>
  );
}

export default Categories;
