import Header from "../components/common/Header";
import Footer from "../components/common/Footer";
import PageContainer from "../components/containers/PageContainer";
import Career from "../components/Career";

export default function Careers() {
  return (
    <PageContainer>
      <Header link={"Careers"} />
      <h1 className="text-2xl font-bold text-center mx-auto"> Careers </h1>
      <Career />
      <Footer />
    </PageContainer>
  );
}
