import { Outlet, useLocation } from "react-router-dom"
import { checkAccess } from "../lib/accessControl";
import { useSelector } from "react-redux";
import { selectUser } from "../redux/reducers/authReducer";
import Unauthorized from "../pages/Unauthorized";

const DashboardGuard = () => {
  const user = useSelector(selectUser);
  const location = useLocation();

  if(!checkAccess(user?.role, location.pathname)){
    return <Unauthorized />
  }

  return <Outlet/>
}

export default DashboardGuard