import React from "react";
import Footer from "./Footer";
import MiniHeader from "./MiniHeader";
import { useParams } from "react-router-dom";
import AdminSidebar from "../DashboardSections/AdminSidebar";

const AdminLayout = ({ children }) => {
  return (
    <div className="flex flex-col bg-[#e5e7eb71] min-h-screen">
      <MiniHeader dashboard={true} />
      <div className="flex flex-1">
        <AdminSidebar />
        <div className="flex-1 p-8 ml-[20rem]">{children}</div>
      </div>
      <div className="ml-[20rem]">
        <Footer />
      </div>
    </div>
  );
};

export default AdminLayout;
