import React, { useState, useCallback, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';
import { apiUrl } from '../../../utils/const';
import axios from 'axios';
import { message } from 'antd';

function Settting({
  editingSection,
  closeEditor,
  wallId,
  coverImage,
  setCoverImage,
}) {
  const [formData, setFormData] = useState(null);
  const [loading, setLoading] = useState(true);

  // Provide default values for formData to avoid destructuring errors
  const { contents = [], coverPhoto = coverImage } = formData || {};
  const headerContent = contents.find((content) => content.type === 'header');
  const subheaderContent = contents.find(
    (content) => content.type === 'subheader'
  );

  const Gallery = contents.find(
    (item) => item.gallery && item.gallery.length > 0
  );
  const galleryImages = Gallery ? Gallery.gallery : [];

  const locationContent = contents.find(
    (content) => content.type === 'location'
  );
  // States for handling image and FAQ
  const [heroImage, setHeroImage] = useState(`${coverPhoto}`);
  console.log(heroImage);
  const [imageFile, setImageFile] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [newFAQQuestion, setNewFAQQuestion] = useState('');
  const [newFAQAnswer, setNewFAQAnswer] = useState('');

  const recommendedWidth = 1200;
  const recommendedHeight = 400;

  // Fetch data on component mount
  useEffect(() => {
    const fetchWall = async () => {
      try {
        if (wallId) {
          const response = await axios.get(`${apiUrl}/api/wall/${wallId}`);
          setFormData(response.data);
        }
      } catch (error) {
        console.error('Error fetching event:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchWall();
  }, [wallId]);

  const triggerFileInput = () => {
    document.getElementById('heroImageInput').click();
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const img = new Image();
      img.src = URL.createObjectURL(file);

      img.onload = () => {
        const width = img.width;
        const height = img.height;

        // Define acceptable ranges
        const minWidth = 1200;
        const maxWidth = 1920;
        const minHeight = 400;
        const maxHeight = 1080;

        if (
          width >= minWidth &&
          width <= maxWidth &&
          height >= minHeight &&
          height <= maxHeight
        ) {
          setImageFile(file);
          const reader = new FileReader();
          reader.onloadend = () => {
            setHeroImage(reader.result);
            setCoverImage(reader.result);
          };
          reader.readAsDataURL(file);
        } else {
          message.error(
            `Image dimensions must be between ${minWidth}x${minHeight} and ${maxWidth}x${maxHeight}.`
          );
        }
      };
    }
  };

  const handleImageDelete = () => {
    setHeroImage(`${apiUrl}/${coverPhoto}`);
    setCoverImage(`${apiUrl}/${coverPhoto}`);
    setImageFile(null);
  };

  const handleContentChange = (type, newValue, key = 'text') => {
    const updatedContent = formData.contents?.map((content) => {
      if (content.type === type) {
        return {
          ...content,
          [key]: newValue, // Dynamically update 'text', 'map_link', etc.
        };
      }
      return content;
    });
    setFormData((prevFormData) => ({
      ...prevFormData,
      contents: updatedContent,
    }));
  };

  const uploadImageFile = async () => {
    if (!imageFile) return;

    const formData = new FormData();
    formData.append('coverPhoto', imageFile);

    try {
      const response = await axios.post(
        `${apiUrl}/api/wall/${wallId}/upload-image`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.status === 200) {
        message.success('Image uploaded successfully!', 3);
        setHeroImage(response.data.imageUrl);
      } else {
        message.error('Failed to upload image!', 3);
      }
    } catch (error) {
      console.error('Error uploading image:', error);
      message.error('Error uploading image!', 3);
    }
  };

  const sendChange = async () => {
    try {
      if (imageFile) {
        await uploadImageFile();
      }
      const updatedForm = { ...formData };
      delete updatedForm.coverPhoto;
      await axios.put(`${apiUrl}/api/wall/${wallId}`, updatedForm);
      message.success('Data updated successfully!', 3);
    } catch (error) {
      console.error('Error updating data:', error);
      message.error('Error updating data!', 3);
    }
  };
  const handleAddFAQ = async () => {
    // Update the content locally first
    const updatedContent = formData.contents?.map((content) => {
      if (content.type === 'faq') {
        return {
          ...content,
          text: [
            ...content.text,
            { question: newFAQQuestion, answer: newFAQAnswer },
          ],
        };
      }
      return content;
    });
    setFormData((prevFormData) => ({
      ...prevFormData,
      contents: updatedContent,
    }));

    // Prepare the data to send in API request
    const updatedFAQ = {
      ...formData,
      contents: updatedContent,
    };

    // Reset input fields
    setNewFAQQuestion('');
    setNewFAQAnswer('');

    try {
      await axios.put(`${apiUrl}/api/wall/${wallId}`, updatedFAQ); // Assuming this is the correct endpoint
      message.success('FAQ added successfully!', 3);
    } catch (error) {
      console.error('Error updating FAQ:', error);
      message.error('Error adding FAQ!', 3);
    }
  };

  // Show loading spinner while data is being fetched
  if (loading) {
    return (
      <div className="fixed left-0 top-0 h-full w-1/4 bg-white shadow-lg p-4 z-50">
        Loading...
      </div>
    );
  }
  return (
    <div className="fixed left-0 top-0 h-full w-1/4 bg-white shadow-lg p-4 z-50">
      <FaTimes
        className="absolute top-4 right-4 cursor-pointer"
        onClick={closeEditor}
      />
      <h2 className="text-xl text-center font-bold mb-4">
        {editingSection} Section
      </h2>

      {editingSection === 'header' && (
        <>
          <div className="mb-4">
            <label className="block mb-2">Header</label>
            <input
              type="text"
              className="border p-2 w-full"
              value={headerContent.text}
              onChange={(e) =>
                handleContentChange('header', e.target.value, 'text')
              }
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2">Subheader</label>
            <input
              type="text"
              className="border p-2 w-full"
              value={subheaderContent.text}
              onChange={(e) =>
                handleContentChange('subheader', e.target.value, 'text')
              }
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2">
              Cover Photo
              <br />
              <span className="text-sm font-bold">
                (Recommended: {recommendedWidth}x{recommendedHeight}px)
              </span>
            </label>
            <div
              className="relative cursor-pointer"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <img
                src={
                  heroImage
                    ? heroImage
                    : 'https://via.placeholder.com/400x300.png?text=Click+to+Upload'
                }
                alt="Hero"
                className="w-full h-auto max-w-xs"
                onClick={triggerFileInput}
              />

              {isHovered && (
                <FaTimes
                  className="absolute top-2 right-2 text-red-500 cursor-pointer"
                  size={20}
                  onClick={handleImageDelete}
                />
              )}
            </div>
            <input
              type="file"
              id="heroImageInput"
              style={{ display: 'none' }}
              onChange={handleImageUpload}
              accept="image/*"
            />
          </div>
        </>
      )}

      {editingSection === 'gallery' && (
        <div className="flex flex-col gap-4">
          {galleryImages.map((imageFile, index) => (
            <img
              key={index}
              src={`${apiUrl}/${imageFile}`}
              alt={`Gallery ${index + 1}`}
              className="w-[300px] h-[150px]"
            />
          ))}
        </div>
      )}
      {editingSection === 'location' && (
        <>
          <div className="mb-4">
            <label className="block mb-2">Location</label>
            <input
              type="text"
              className="border p-2 w-full"
              value={
                formData.contents?.find(
                  (content) => content.type === 'location'
                )?.text || ''
              }
              onChange={(e) =>
                handleContentChange('location', e.target.value, 'text')
              }
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2">Google Maps Link</label>
            <input
              type="text"
              className="border p-2 w-full"
              value={
                formData.contents?.find(
                  (content) => content.type === 'location'
                )?.map_link || ''
              }
              onChange={(e) =>
                handleContentChange('location', e.target.value, 'map_link')
              }
            />
          </div>
        </>
      )}
      {editingSection === 'faq' && (
        <div className="faq-settings p-4">
          <h2 className="text-lg font-semibold mb-4">Add New FAQ</h2>

          {/* Input for New Question */}
          <div className="mb-4">
            <label className="block mb-2 text-sm">New Question</label>
            <input
              type="text"
              value={newFAQQuestion} // Separate state for new question
              onChange={(e) => setNewFAQQuestion(e.target.value)} // Update the new question state
              className="w-full p-2 border border-gray-300 rounded"
              placeholder="Enter your question"
            />
          </div>

          {/* Input for New Answer */}
          <div className="mb-4">
            <label className="block mb-2 text-sm">New Answer</label>
            <textarea
              value={newFAQAnswer} // Separate state for new answer
              onChange={(e) => setNewFAQAnswer(e.target.value)} // Update the new answer state
              className="w-full p-2 border border-gray-300 rounded"
              placeholder="Enter your answer"
            />
          </div>

          <button
            onClick={handleAddFAQ}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Add FAQ
          </button>
        </div>
      )}
      {editingSection !== 'faq' && (
        <div className="mt-10 flex justify-between">
          <button className="bg-gray-300 p-2 rounded" onClick={closeEditor}>
            Cancel
          </button>
          <button
            className="bg-brandGreen text-white py-2 px-4 rounded mr-2"
            onClick={sendChange}
          >
            Save
          </button>
        </div>
      )}
    </div>
  );
}

export default Settting;
