import React, { useEffect, useState } from "react";
import Layout from "../components/common/Layout";
import Websitebuilder from "../components/DashboardSections/Websitebuilder";
import { useParams } from "react-router-dom";
import axios from "axios";
import { apiUrl } from "../utils/const";
import ClipLoader from "react-spinners/ClipLoader";
import EventCustomizer from "./EventCustomizer";

const TemplatesPage = () => {
  const { eventId } = useParams();
  const [wallDesign, setWallDesign] = useState(null);
  const [wallId, setWallId] = useState("");
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const fetchEvent = async () => {
      setLoading(true); // Start loading
      try {
        if (eventId) {
          const response = await axios.get(`${apiUrl}/api/events/${eventId}`);
          setWallId(response.data.wallDesignId);
          setWallDesign(response.data.template);
        }
      } catch (error) {
        console.error("Error fetching event:", error);
      } finally {
        setLoading(false); // Stop loading
      }
    };

    fetchEvent();
  }, [eventId]);

  return (
    <Layout>
      {loading ? (
        <div className="flex justify-center items-center min-h-screen">
          <ClipLoader color="#4A90E2" loading={loading} size={50} />
        </div>
      ) : (
        <EventCustomizer />
        // <Websitebuilder
        //   wallId={wallId}
        //   wallDesign={wallDesign}
        //   isEditing={true}
        // />
      )}
    </Layout>
  );
};

export default TemplatesPage;
