const downloader = ({ file_name, blob }) => {
    const href = URL.createObjectURL(blob);
  
    // Create the element
    const download_element = document.createElement("a");
    download_element.href = href;
    download_element.download = file_name; // This might depends
  
    // Add the element to body and trigger download
    document.body.appendChild(download_element);
    download_element.click();
    document.body.removeChild(download_element);
  
    URL.revokeObjectURL(href);
  };
  
  export { downloader };
  