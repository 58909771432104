import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ClipLoader from "react-spinners/ClipLoader";
import { useNavigate } from "react-router-dom";
import { Success } from "../../utils/alert";

function EventDetailsForm({
  nextStep,
  prevStep,
  handleChange,
  handleDateChange,
  values,
  currentPage,
  isUrlAvailable,
  isCheckingUrl,
  handleSubmit,
}) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState(null);
  const isStep1Valid = () => {
    return (
      values.start_date &&
      values.start_time &&
      values.end_date &&
      values.end_time
    );
  };

  const isStep2Valid = () => {
    return values.location;
  };

  const isStep3Valid = () => {
    return values.hosting_url && isUrlAvailable && values.hosting_url.split(" ").length === 1;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await handleSubmit();
      Success({
        title: "Success",
        message: "You have successfully created an event."
      })
      setTimeout(() => {
        // Redirect after showing success message for 2 seconds
        navigate(`/dashboard`);
      }, 2000);
    } catch (error) {
      setNotification({
        type: "error",
        message: "Failed to create the event. Please try again.",
      });
    } finally {
      setLoading(false);
    }
  };
  const handleUrlChange = (e) => {
    const { value } = e.target;
    // Regular expression: allow only letters, numbers, and hyphens, no spaces, and no leading/trailing hyphens
    const isValid = /^[a-zA-Z0-9-]+$/.test(value) && !/^[-]|[-]$/.test(value);
    // Update value only if it passes validation
    if (isValid || value === "") {
      handleChange(e); // Existing handler to update state
    }
  };

  const renderDetailPage = () => {
    switch (currentPage) {
      case 1:
        return (
          <div className="w-1/2 mx-auto">
            <div className="grid grid-cols-2 gap-y-4 gap-x-6">
              <h2 className="text-center font-bold text-3xl my-10 col-span-2">
                When is your event
              </h2>
              <div>
                <label htmlFor="start_date" className="block mb-2">
                  Start Date
                </label>
                <DatePicker
                  selected={values.start_date}
                  onChange={(date) => handleDateChange("start_date", date)}
                  dateFormat="MMMM d, yyyy"
                  className="border p-2 mb-4 w-full"
                  placeholderText='Start date'
                />
              </div>
              <div>
                <label htmlFor="start_time" className="block mb-2">
                  Start Time
                </label>
                <DatePicker
                  selected={values.start_time}
                  onChange={(time) => handleDateChange("start_time", time)}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                  className="border p-2 mb-4 w-full"
                  placeholderText='Start time'
                />
              </div>
              <div>
                <label htmlFor="end_date" className="block mb-2">
                  End Date
                </label>
                <DatePicker
                  selected={values.end_date}
                  onChange={(date) => handleDateChange("end_date", date)}
                  dateFormat="MMMM d, yyyy"
                  className="border p-2 mb-4 w-full"
                  placeholderText='End date'
                />
              </div>
              <div>
                <label htmlFor="end_time" className="block mb-2">
                  End Time
                </label>
                <DatePicker
                  selected={values.end_time}
                  onChange={(time) => handleDateChange("end_time", time)}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                  className="border p-2 mb-4 w-full"
                  placeholderText='End time'
                />
              </div>
            </div>
            <div className="flex justify-between">
              <button
                onClick={nextStep}
                className={`bg-yellow-500 my-20 text-white w-full p-2 ${
                  !isStep1Valid() ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={!isStep1Valid()}
              >
                Continue
              </button>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="w-1/2 mx-auto">
            <h2 className="text-center font-bold text-3xl my-10">
              Where is your party taking place?
            </h2>
            <input
              type="text"
              name="location"
              placeholder="venue name or address"
              value={values.location}
              onChange={handleChange}
              className="border p-2 mb-4 w-full"
            />
            <div className="flex justify-between">
              <button
                onClick={nextStep}
                className={`bg-yellow-500 my-20 text-white w-full p-2 ${
                  !isStep2Valid() ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={!isStep2Valid()}
              >
                Continue
              </button>
            </div>
          </div>
        );

      case 3:
        return (
          <div className="w-1/2 mx-auto">
            <h2 className="text-center font-bold text-3xl my-10">
              Customize your RSVP event URL
            </h2>
            <div className="relative">
              <input
                type="text"
                name="hosting_url"
                placeholder="Enter Event Name"
                value={values.hosting_url}
                onChange={handleUrlChange}
                className={`border p-2 mb-4 w-full ${
                  !isUrlAvailable || isCheckingUrl ? "border-red-500" : ""
                }`}
              />

              <span className="absolute right-0 top-2 pr-3 text-gray-500">
                .nowinvite.com
              </span>
              {!isUrlAvailable && (
                <p className="text-red-500 text-sm mt-1">
                  URL is not available
                </p>
              )}
              {
                values.hosting_url && values.hosting_url.split(" ").length > 1 ?  
                <p className="text-red-500 text-sm mt-1">
                  Spaces are not allowed
                </p>
                : null
              }
            </div>

            {notification && (
              <div
                className={`text-center mt-4 ${
                  notification.type === "success"
                    ? "text-green-500"
                    : "text-red-500"
                }`}
              >
                {notification.message}
              </div>
            )}

            <div className="flex my-16 justify-between">
              <button
                onClick={handleFormSubmit}
                className={`bg-yellow-500 text-white w-full p-2 ${
                  !isStep3Valid() || isCheckingUrl || loading
                    ? "opacity-50 cursor-not-allowed"
                    : ""
                }`}
                disabled={!isStep3Valid() || isCheckingUrl || loading}
              >
                {loading ? <ClipLoader color="#ffffff" size={20} /> : "Create"}
              </button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return <div>{renderDetailPage()}</div>;
}

export default EventDetailsForm;
