import Modal from "react-modal";
import { cn } from "../../lib/basic";
import { IoClose } from "react-icons/io5";

Modal.setAppElement("#root");

export default function MainModal({
  isOpen,
  closeModal,
  children,
  title,
  className,
}) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel={title}
      shouldFocusAfterRender={false}
      className={cn(
        "bg-white p-6 rounded-lg shadow-md w-1/2 max-h-[80vh] overflow-y-auto mx-auto mt-20",
        className
      )}
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
    >
      <div className="mb-2 flex items-center justify-between ">
        <h3 className="text-xl text-neutral-600 font-bold">{title}</h3>
        <IoClose
          color="#484848"
          size={26}
          className="cursor-pointer"
          onClick={closeModal}
        />
      </div>
      {children}
    </Modal>
  );
}
