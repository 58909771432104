// components/card/TestimonialCard.js
import React from 'react';
import { FaMap } from "react-icons/fa";
const TestimonialCard = ({ testimonial }) => {
  return (
      <div className="bg-white p-4 rounded-md w-auto">
        <h3 className="text-lg font-bold mb-2 text-[#6B7280]">{testimonial.title}</h3>
        <p className="text-[24px] font-medium text-[#1F2937] mt-5 ">{testimonial.location}</p>
        <p className="text-sm my-4 text-[#6B7280] mt-7 whitespace-nowrap">{testimonial.address}
        <span className='p-2 ml-3 md:p-1 lg:p-2 rounded-full bg-indigo-950 text-white text-sm'>
              <FaMap className='inline'/>
            </span>

        </p>
      </div>
  );
};

export default TestimonialCard;
