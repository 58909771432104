// App.js
import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import EventCreationPage from "./pages/EventCreationPage";
import EventsPage from "./pages/EventsPage";
import ForgotPassPage from "./pages/ForgotPassPage";
import HomePage from "./pages/HomePage";
import MyAccountPage from "./pages/MyAccountPage";
import NotFoundPage from "./pages/NotFoundPage";
import RSVPPage from "./pages/RSVPPage";
import RsvpDetailPage from "./pages/rsvpDetailPage";
import SignInPage from "./pages/SignInPage";
import SignUpPage from "./pages/SignUpPage";
import ProtectedRoute from "./components/ProtectedRoute";
import DashboardPage from "./pages/Dashboard";
import TemplateCustomize from "./pages/TemplateCustomize";
// import AddTemplate from './components/tamplates/weeding/AddTemplate';
import EventChecklistPage from "./pages/EventCheckListPage";
import EventSettings from "./components/DashboardSections/EventSettings";
import InviteListsPage from "./pages/InviteListsPage";
import NewEmailPage from "./pages/NewEmailPage";
import SentEmailPage from "./pages/EmailSentPage";
import FormBuilderPage from "./pages/FormBuilderPage";
import EventTemplatePage from "./pages/EventTemplatePage";
import PricingPage from "./pages/PricingPage";
import AboutUsPage from "./pages/AboutUsPage";
import PreviewPage from "./pages/PreviewPage";
import RSVP from "./pages/RSVP";
import DashboardGuard from "./components/DashboardGuard";
import Reporting from "./pages/Reporting";
import EventSettingsPage from "./pages/EventSettingsPage";
import PreviewInviteePage from "./pages/PreviewInviteePage";
import { NODE_ENV } from "./utils/const";
// import ReportingPage from './pages/ReportingPage';
import AdminDashboard from "./pages/AdminDashboard";
import Partners from "./pages/Partners";
import Careers from "./pages/Careers";

function App() {
  const location = useLocation();
  const [isViewMode, setViewMode] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [location.pathname]);

  useLayoutEffect(() => {
    if (window.location.hostname) {
      // during development the host name dont have top level domain name but
      // in production it likely has
      const domainLength = NODE_ENV === "production" ? 2 : 1;

      if (window.location.hostname.includes("www")) {
        return setViewMode(false);
      }

      const has_subdomain =
        window.location.hostname.split(".").length > domainLength
          ? true
          : false;
      setViewMode(has_subdomain);
    }
  }, []);

  return (
    <Routes>
      {isViewMode ? (
        <Route path="/" element={<RSVP />} />
      ) : (
        <>
          {/* <Route path="rsvp_view" element={<RSVP />} /> */}
          <Route path="/" element={<HomePage />} />
          <Route path="/rsvp" element={<RSVPPage />} />
          <Route path="/pricing" element={<PricingPage />} />
          <Route path="/about-us" element={<AboutUsPage />} />
          <Route path="/careers" element={<Careers />} />
          <Route
            path="/admin-dashboard"
            element={
              <ProtectedRoute>
                <AdminDashboard />
              </ProtectedRoute>
            }
          />
          <Route path="/admin/partners" element={<Partners />} />
          <Route path="rsvp-detail" element={<RsvpDetailPage />} />
          <Route
            path="events/create"
            element={
              <EventCreationPage />
              /* <GeneralEventCreation /> */
            }
          />
          <Route path="/dashboard" element={<DashboardGuard />}>
            <Route path="" element={<DashboardPage />} />
            <Route path="templates/:theme-Id" element={<TemplateCustomize />} />
            {/* <Route
              path="events/:eventId/event-checklist"
              element={<EventChecklistPage />}
            /> */}
            <Route
              path="events/:eventId/event-settings"
              element={<EventSettings />}
            />
            <Route
              path="events/:eventId/invite-lists"
              element={<InviteListsPage />}
            />
            <Route
              path="events/:eventId/invite-lists/view"
              element={<PreviewInviteePage />}
            />
            <Route
              path="events/:eventId/new-email"
              element={<NewEmailPage />}
            />
            <Route
              path="events/:eventId/sent-email"
              element={<SentEmailPage />}
            />
            <Route
              path="events/:eventId/form-builder"
              element={<FormBuilderPage />}
            />
            <Route
              path="events/:eventId/wall-designer"
              element={<EventTemplatePage />}
            />
            <Route path="events/:eventId/preview" element={<PreviewPage />} />
            <Route path="events/:eventId/reporting" element={<Reporting />} />
            <Route
              path="events/:eventId/settings"
              element={<EventSettingsPage />}
            />
          </Route>

          <Route path="/events" element={<EventsPage />} />
          <Route path="/forgot-password" element={<ForgotPassPage />} />
          <Route
            path="/my-account"
            element={
              <ProtectedRoute>
                <MyAccountPage />
              </ProtectedRoute>
            }
          />
          <Route path="/sign-in" element={<SignInPage />} />
          <Route path="/sign-up" element={<SignUpPage />} />
          {/* <Route path="/add-template" element={<AddTemplate />} /> */}
        </>
      )}

      <Route path="/*" element={<NotFoundPage />} />
    </Routes>
  );
}

function AppWrapper() {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}

export default AppWrapper;
